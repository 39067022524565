<template>
  <section ref="Section" class="section cover" id="isfahan" data-chapter="isfahan" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description') }}</p>
      </div>
      <div class="cover__number" aria-hidden="true">06</div>
      <div class="cover__title" aria-hidden="true">{{ $t('title') }}</div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 20
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Isfahan",
    "description": "W Persji Polacy zostali przyjęci niezwykle gościnnie i życzliwie. W Isfahanie, nazwanym \"miastem polskich dzieci\", bezpieczną przystań znalazło ponad dwa tysiące polskich sierot. W Iranie rozchodziły się też drogi polskich żołnierzy i cywili.",
    "screen_reader_title": "Rozdział 6 Isfahan"
  },
  "en": {
    "title": "Isfahan",
    "description": "In Iran, the Poles were received with exceptional warmth and kindness. More than 2,000 Polish orphans found safe haven in Isfahan, earning it the nickname ‘the city of Polish children’. Iran was where the paths of the soldiers and civilians diverged.",
    "screen_reader_title": "Chapter 6 Isfahan"
  },
  "ru": {
    "title": "Исфахан",
    "description": "В Персии поляков ждал необычайно радушный и теплый прием. В Исфахане, получившем название «города польских детей», безопасное убежище нашли более двух тысяч польских сирот. В Иране же пути польских солдат и гражданских разошлись.",
    "screen_reader_title": "Глава 6 Исфахан"
  }
}
</i18n>
