<template>
  <section ref="KrasnowodzkAnimation" class="section animation krasnowodzk-animation" data-chapter="krasnowodzk" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="section__inner">
      <template v-if="$screen.width < 1024">
        <Waypoint @change="onWaypointChange" :options="intersectionOptions" />
      </template>
      <template v-if="$screen.width >= 1024">
        <Waypoint @change="onWaypointChangeDesktop" :options="intersectionOptions" />
      </template>
      <div class="krasnowodzk-animation__timeline krasnowodzk-animation__timeline--frame-1">
        <div class="krasnowodzk-animation__text">
          <h3 class="krasnowodzk-animation__header frame-1 frame-2">
            <span>{{ $t('title') }}</span>
          </h3>
          <div class="krasnowodzk-animation__figure krasnowodzk-animation__figure--left krasnowodzk-animation__figure--light frame-3">
            114 500
          </div>
          <div class="krasnowodzk-animation__figure krasnowodzk-animation__figure--left krasnowodzk-animation__figure--yellow frame-4">
            78 000
          </div>
          <div class="krasnowodzk-animation__figure krasnowodzk-animation__figure--left krasnowodzk-animation__figure--yellow frame-5">
            36 500
          </div>
          <div class="krasnowodzk-animation__description frame-1 frame-2">
            <p>{{ $t('description_1') }}</p>
          </div>
          <div class="krasnowodzk-animation__description frame-3">
            <p>{{ $t('description_2') }}</p>
          </div>
          <div class="krasnowodzk-animation__description frame-4">
            <p>{{ $t('description_3') }}</p>
          </div>
          <div class="krasnowodzk-animation__description frame-5">
            <p>{{ $t('description_4') }}</p>
          </div>
        </div>
        <div class="krasnowodzk-animation__chart">
          <div class="krasnowodzk-animation__figure frame-1">
            <div class="krasnowodzk-animation__chart-text"></div>
          </div>
          <div class="krasnowodzk-animation__figure frame-2">
            <div class="krasnowodzk-animation__chart-text">389 041</div>
          </div>
          <div class="krasnowodzk-animation__chart-image frame-3">
            <img src="@/assets/images/04-krasnowodzk-chart-2-a.svg" :alt="$t('image_description')" />
            <img src="@/assets/images/04-krasnowodzk-chart-2-b.svg" class="animated-image-3" :alt="$t('image_description')" />
            <img src="@/assets/images/04-krasnowodzk-chart-2-a-hc.svg" class="hc-only" :alt="$t('image_description')" />
            <img src="@/assets/images/04-krasnowodzk-chart-2-b-hc.svg" class="animated-image-3 hc-only" :alt="$t('image_description')" />
          </div>
          <div class="krasnowodzk-animation__chart-image frame-4">
            <img src="@/assets/images/04-krasnowodzk-chart-2-a.svg" :alt="$t('image_description')" />
            <img src="@/assets/images/04-krasnowodzk-chart-3.svg" :alt="$t('image_description')" />
            <img src="@/assets/images/04-krasnowodzk-chart-2-a-hc.svg" class="hc-only" :alt="$t('image_description')" />
            <img src="@/assets/images/04-krasnowodzk-chart-3-hc.svg" class="hc-only" :alt="$t('image_description')" />
          </div>
          <div class="krasnowodzk-animation__chart-image frame-5">
            <img src="@/assets/images/04-krasnowodzk-chart-2-a.svg" :alt="$t('image_description')" />
            <img src="@/assets/images/04-krasnowodzk-chart-4.svg" :alt="$t('image_description')" />
            <img src="@/assets/images/04-krasnowodzk-chart-2-a-hc.svg" class="hc-only" :alt="$t('image_description')" />
            <img src="@/assets/images/04-krasnowodzk-chart-4-hc.svg" class="hc-only" :alt="$t('image_description')" />
          </div>
        </div>
      </div>
      <div class="krasnowodzk-animation__background"></div>
    </div>
    <resize-observer @notify="handleResize" :showTrigger="true" />
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 15,
      autoAnimationId: '',
      autoAnimationDelay: 5000,
      currentFrameNumber: 1,
      isAnimating: false,
      timeout: 700,
      totalFrameNumber: 5,
      upKeys: [37, 38],
      downKeys: [39, 40],
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0.5]
      }
    }
  },
  methods: {
    handleWheel(event) {
      event.stopPropagation()
      if (this.isAnimating) {
        return
      }
      let direction = -event.deltaY
      if (direction < 0 && this.hasNext()) {
        this.next()
      } else if (direction > 0 && this.hasPrev()) {
        this.prev()
      }
    },
    handleKeyUp(event) {
      if (this.isAnimating) {
        return
      }
      let key = event.which
      if (this.upKeys.includes(key) && this.hasPrev()) {
        this.prev()
      } else if (this.downKeys.includes(key) && this.hasNext()) {
        this.next()
      }
    },
    next() {
      if (this.currentFrameNumber >= this.totalFrameNumber) {
        this.stopAnimation()
        this.$parent.scrollDown()
        document.removeEventListener('keyup', this.handleKeyUp)
        document.addEventListener('keyup', this.$parent.handleKeyUp)
        return
      }
      this.Animation(++this.currentFrameNumber)
    },
    prev() {
      if (this.currentFrameNumber <= 1) {
        this.stopAnimation()
        this.$parent.scrollUp()
        document.removeEventListener('keyup', this.handleKeyUp)
        document.addEventListener('keyup', this.$parent.handleKeyUp)
        return
      }
      this.Animation(--this.currentFrameNumber)
    },
    Animation(slideNumber) {
      const timeline = document.getElementsByClassName('krasnowodzk-animation__timeline')[0]
      timeline.className = 'krasnowodzk-animation__timeline'
      timeline.classList.add(`krasnowodzk-animation__timeline--frame-${slideNumber}`)
      this.stopAnimation()
    },
    hasNext() {
      return this.currentFrameNumber <= this.totalFrameNumber
    },
    hasPrev() {
      return this.currentFrameNumber >= 1
    },
    stopAnimation() {
      this.isAnimating = true
      setTimeout(() => {
        this.isAnimating = false
      }, this.timeout)
    },
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN' && this.$screen.width < 1024) {
        const section = this.$refs.KrasnowodzkAnimation

        section.scrollIntoView({
          behavior: 'smooth'
        })
        this.startAutoAnimation()
      }
      if (waypointState.going === 'OUT' && this.$screen.width < 1024) {
        this.stopAutoAnimation()
      }
    },
    onWaypointChangeDesktop(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN' && this.$screen.width >= 1024) {
        document.removeEventListener('keyup', this.$parent.handleKeyUp)
        document.addEventListener('keyup', this.handleKeyUp)
      }
    },
    startAutoAnimation() {
      this.autoAnimationId = setInterval(this.autoAnimation, this.autoAnimationDelay)
    },
    stopAutoAnimation() {
      clearInterval(this.autoAnimationId)
    },
    autoAnimation() {
      ++this.currentFrameNumber
      if (this.currentFrameNumber >= this.totalFrameNumber) {
        this.currentFrameNumber = 1
      }
      const timeline = document.getElementsByClassName('krasnowodzk-animation__timeline')[0]
      timeline.className = 'krasnowodzk-animation__timeline'
      timeline.classList.add(`krasnowodzk-animation__timeline--frame-${this.currentFrameNumber}`)
    },
    handleResize({ width }) {
      if (width >= 1024) {
        this.stopAutoAnimation()
        this.Animation(1)
      }
    }
  },
  mounted() {
    const section = this.$refs.KrasnowodzkAnimation
    if (this.$screen.width >= 1024) {
      section.addEventListener('wheel', this.handleWheel)
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Ewakuacja z ZSRR",
    "description_1": "Obywatele II Rzeczypospolitej w ZSRR zwolnieni z łagrów i miejsc osiedlenia na podstawie układu Sikorski-Majski z 30 lipca 1941.",
    "description_2": "Blisko 30% z objętych amnestią obywateli II RP zostało ewakuowanych z ZSRR wraz z Armią Andersa.",
    "description_3": "Tylu żołnierzy i żołnierek ewakuowało się w szeregach Armii Andersa do Iranu. Wśród nich 3 tysiące stanowiła Pomocnicza Służba Kobiet, a 4,5 tysiąca - kadeci i kadetki.",
    "description_4": "Tylu cywilów zostało ewakuowanych. Wśród nich znalazło się 14,5 tysiąca dzieci poniżej 14 roku życia.",
    "alt": "Diagram kołowy - zaznaczone wycinki odpowiadają danym liczbowym.",
  },
  "en": {
    "title": "Evacuation from the USSR",
    "description_1": "The number of citizens of the Second Polish Republic who were released from labour camps and settlements in the USSR after the Sikorski-Mayski Agreement of 30th July 1941.",
    "description_2": "The number of people who left the USSR with Anders’ Army – representing nearly 30% of the Polish citizens who were ‘amnestied’ by the Soviets.",
    "description_3": "The number of soldiers who were evacuated to Persia in the ranks of Anders’ Army. Three thousand of them were members of the Women’s Auxiliary Service, while 4,500 were part of youth military formations.",
    "description_4": "The number of civilians who were evacuated, including 14,500 children under the age of 14.",
    "alt": "A pie chart where the marked segments correspond to the numerical data below.",
  },
  "ru": {
    "title": "Эвакуация из СССР",
    "description_1": "Граждане Польской Республики в СССР освобождены из лагерей и мест поселения на основании соглашения Сикорского — Майского, подписанного 30 июля 1941 года.",
    "description_2": "Около 30 % граждан Польской Республики, на которых распространялась амнистия, были эвакуированы из СССР вместе с Армией Андерса.",
    "description_3": "Столько солдат было эвакуировано в рядах Армии Андерса в Иран. Из них 3 тысячи — Вспомогательная женская служба и 4,5 тысячи — кадеты.",
    "description_4": "Столько гражданских было эвакуировано. В их числе 14,5 тысяч — дети младше 14 лет.",
    "alt": "Графика представляет диаграмму: круг с сегментами с цифрами",
  }
}
</i18n>
