<template>
  <section ref="Section" class="section cover" id="beirut" data-chapter="beirut" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description') }}</p>
      </div>
      <div class="cover__number" aria-hidden="true">08</div>
      <div class="cover__title" aria-hidden="true">{{ $t('title') }}</div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 27
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Beirut",
    "description": "Wojska generała Andersa zostały rozmieszczone na bardzo dużym obszarze Iraku, Kurdystanu i Syrii, gdzie do ich zadań należała m.in. ochrona pól naftowych przed ewentualnym niemieckim atakiem. Latem 1943, wobec postępów wojsk alianckich w Afryce i braku zagrożenia niemieckiego na Bliskim Wschodzie, pojawiła się pilna potrzeba przeniesienia Armii nad Morze Śródziemne, m.in. do Libanu, gdzie duże grupy polskich uchodźców wojennych przebywają już od jesieni 1939 roku.",
    "screen_reader_title": "Rozdział 8 Beirut"
  },
  "en": {
    "title": "Beirut",
    "description": "Anders’ Army was dispersed over a very large area, which spanned Iraq, Kurdistan and Syria. Part of its activities there involved protecting the oil fields from possible German attack. In the summer of 1943, given the Allied forces’ progress in Africa and the lack of German threats in the Middle East, an urgent need arose to transfer the army to the Mediterranean Sea. This included, amongst other places, Lebanon – where large numbers of Polish refugees had been staying as early as the autumn of 1939.",
    "screen_reader_title": "Chapter 8 Beirut"
  },
  "ru": {
    "title": "Бейрут",
    "description": "Войска генерала Андерса размещались на обширной территории Ирака, Курдистана и Сирии, в их задачи входила защита нефтяных месторождений от возможного нападения немцев. Летом 1943 года, в связи с продвижением союзных войск в Африке и отсутствием немецкой угрозы на Ближнем Востоке, возникла срочная необходимость перебросить армию к Средиземному морю, в частности в Ливан, где с осени 1939 года находились большие группы польских военных беженцев.",
    "screen_reader_title": "Глава 8 Beirut"
  }
}
</i18n>
