<template>
  <Waypoint @change="onWaypointChange" />
  <div class="quote__image-mobile">
    <div class="quote__image-mobile-inner">
      <picture v-if="visible">
        <source media="(min-width:768px)" :srcset="getImageSource(fileMd)">
        <img :src="getImageSource(fileSm)" :alt="alt" />
      </picture>
      <div v-if="imageDescription !== ''" class="quote__image-mobile-description">
        <button v-if="!isInfoOpen" @click="openInfo" class="quote__image-mobile-description-btn quote__image-mobile-description-btn--open">
          <span class="visually-hidden">{{ $t('default.button-info-open-title') }}</span>
        </button>
        <div v-if="isInfoOpen" class="quote__image-mobile-description-background">
          <button @click="closeInfo" class="quote__image-mobile-description-btn quote__image-mobile-description-btn--close">
            <span class="visually-hidden">{{ $t('default.button-info-close-title') }}</span>
          </button>
          <div class="quote__image-mobile-description-text">
            <span>{{ description }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  name: 'QuoteImageMobile',
  props: ['fileSm', 'fileMd', 'description', 'alt'],
  components: {
    Waypoint
  },
  data () {
    return {
      visible: false,
      isInfoOpen: false
    }
  },
  methods: {
    getImageSource(fileName) {
      return require(`@/assets/images/${fileName}`)
    },
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.visible = true
      }
    },
    openInfo() {
      this.isInfoOpen = true
    },
    closeInfo() {
      this.isInfoOpen = false
    }
  }
}
</script>
