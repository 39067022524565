import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import VueKinesis from 'vue-kinesis'
import VueScreen from 'vue-screen'
import 'vue3-resize/dist/vue3-resize.css'
import Vue3Resize from 'vue3-resize'
import mitt from 'mitt'
import router from './router'

const emitter = mitt()
const app = createApp(App).use(router).use(i18n).use(VueKinesis).use(VueScreen).use(Vue3Resize)

app.config.globalProperties.emitter = emitter
app.mount('#app')
