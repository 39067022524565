<template>
  <section class="section article" data-chapter="bagdad" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="collage bagdad-collage" aria-describedby="bagdad-collage-description">
      <div class="collage__inner">
        <div class="collage__merged" v-if="$screen.width < 1024">
          <ArticleCollageMerged file="07-bagdad" />
        </div>
        <template v-if="$screen.width < 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
        </template>
        <kinesis-container v-if="$screen.width >= 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
          <div class="collage__height">
            <kinesis-element :strength="10"></kinesis-element>
          </div>
          <div class="collage__absolute bagdad-collage__one">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/07-bagdad-rzeka-1.png">
                <img data-src="@/assets/images/07-bagdad-rzeka-1.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute bagdad-collage__two">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/07-bagdad-rzeka-2.png">
                <img data-src="@/assets/images/07-bagdad-rzeka-2.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute bagdad-collage__three">
            <kinesis-element :strength="10" :originX="0" :originY="0">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/07-bagdad-most.png">
                <img data-src="@/assets/images/07-bagdad-most.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute bagdad-collage__four">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/07-bagdad-zolnierz.png">
                <img data-src="@/assets/images/07-bagdad-zolnierz.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute bagdad-collage__five">
            <kinesis-element :strength="30" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/07-bagdad-palma-2.png">
                <img data-src="@/assets/images/07-bagdad-palma-2.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute bagdad-collage__six">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/07-bagdad-palma.png">
                <img data-src="@/assets/images/07-bagdad-palma.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute bagdad-collage__seven">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/07-bagdad-local-boy.png">
                <img data-src="@/assets/images/07-bagdad-local-boy.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
        </kinesis-container>
      </div>
    </div>
    <div id="bagdad-collage-description" class="visually-hidden">{{ $t('collage_description') }}</div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import ArticleText from '@/components/partials/ArticleText.vue'
import ArticleCollageMerged from '@/components/partials/ArticleCollageMerged.vue'

export default {
  components: {
    ArticleCollageMerged,
    ArticleText
  },
  data() {
    return {
      sectionNumber: 25
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Pejzaż z minaretem",
    "text": "Szlak Armii Andersa odtworzyć można rekonstruując historię powstających po drodze wystaw malarskich. Jedna z nich odbyła się w Bagdadzie. Co na niej pokazano?",
    "article_url": "https://culture.pl/pl/superartykul/obrazki-z-wystawy-malarstwo-na-szlaku-armii-andersa",
    "collage_description": "Grafika stworzona techniką kolażu. Most w Bagdadzie, obok niego siedzi rysownik Stanisław Gliwa – z notesem w dłoni. Pośrodku grafika mężczyzny w tradycyjnym stroju, z dzbanem wody na pasku."
  },
  "en": {
    "title": "Landscape with a minaret",
    "text": "The path of Anders’ Army can be reconstructed through the story of the painting exhibitions it presented along the way. One took place in Baghdad. What artworks were shown there?",
    "article_url": "https://culture.pl/en/feature/soldiers-artists-the-exhibitions-of-anders-army",
    "collage_description": "A bridge in Baghdad. Stanisław Gliwa – a draughtsman, sitting next to it with a notepad in his hand. In the centre – a man in traditional clothes, with a belt water container."
  },
  "ru": {
    "title": "Пейзаж с минаретом",
    "text": "Маршрут Армии Андерса можно реконструировать, воссоздав историю выставок картин, написанных в пути. Одна из них прошла в Багдаде. Что увидели зрители?",
    "article_url": "https://culture.pl/ru/superarticle/kartinki-s-vystavki-zhivopis-na-puti-armii-andersa",
    "collage_description": "Графика, выполненная в технике коллажа. Мост в Багдаде, рядом с которым сидит карикатурист Станислав Глива с блокнотом в руках. В центре — графическое изображение мужчины в национальном костюме, с флягой на поясе."
  }
}
</i18n>
