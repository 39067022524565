<template>
  <section ref="Section" class="section quote epilogue-quote" id="biografie" data-chapter="epilog" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="quote__inner quote__inner--without-author-picture">
      <template v-if="$screen.width < 1024">
        <Waypoint @change="onWaypointChange" />
        <QuoteImageMobile fileSm="14-epilogue-quote-sm.jpg" fileMd="14-epilogue-quote-md.jpg" :description="$t('image_description')" :alt="$t('author_picture_alt')"/>
      </template>
      <figure>
        <blockquote>
          <p><span>{{ $t('quote') }}</span></p>
        </blockquote>
        <figcaption>
          <span class="quote__author-name"><span>{{ $t('author_name') }}</span></span>
        </figcaption>
      </figure>
      <div class="epilogue-quote__goto">
        <div class="epilogue-quote__flex">
          <div class="epilogue-quote__goto-inner">
            <p class="epilogue-quote__goto-text">{{ $t('link_title') }}</p>
            <span class="epilogue-quote__goto-arrow"></span>
            <a class="epilogue-quote__link" :href="$t('link_url')" target="_blank" rel="noreferrer">
              <span class="visually-hidden">{{ $t('default.goto-article-label') }} {{ $t('default.new-tab-info') }}</span>
            </a>
          </div>
        </div>
      </div>
      <template v-if="$screen.width >= 1024">
        <div class="quote__background-image epilogue-quote__background-image lazy-background" :title="$t('author_picture_alt')"></div>
        <div class="quote__background-image-description">
          <p>{{ $t('image_description') }}</p>
        </div>
      </template>
    </div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'
import QuoteImageMobile from '@/components/partials/QuoteImageMobile.vue'

export default {
  components: {
    QuoteImageMobile,
    Waypoint
  },
  data() {
    return {
      sectionNumber: 47
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "quote": "Jesteśmy małą Polską, tworzymy Polskę wędrowną.",
    "author_name": "gen. Władysław Anders",
    "image_description": "Fot. Instytut Polski i Muzeum gen. Sikorskiego",
    "link_title": "Poznaj biogramy artystów Armii Andersa",
    "link_url": "https://culture.pl/pl/indeks-a-jak-artysci-andersa",
    "author_picture_alt": "Czarno-biała grafika ze zdjęciem gen. Andersa przy drogowskazie w Iranie, wyznaczającym kierunki do Warszawy i Teheranu. Po lewej stronie, obok gen. Andersa stoi pięciu żołnierzy Armii Polskiej na Wschodzie w mundurach letnich. W górze białe litery w dwóch rzędach. Cytat jego wypowiedzi. "
  },
  "en": {
    "quote": "We are a little Poland; we are creating a wandering Poland.",
    "author_name": "General Władysław Anders",
    "image_description": "Photo: The Polish Institute and Sikorski Museum",
    "link_title": "Read the biographies of the artists of Anders’ Army",
    "link_url": "https://culture.pl/en/index-a-is-for-artists-in-arms",
    "author_picture_alt": ""
  },
  "ru": {
    "quote": "Мы — малая Польша, мы — странствующая Польша.",
    "author_name": "Генерал Владислав Андерс",
    "image_description": "Генерал Владислав Андерс",
    "link_title": "Познакомьтесь с биографиями артистов Армии Андерса",
    "link_url": "https://culture.pl/ru/indeks-artisty-armii-andersa",
    "author_picture_alt": "Черно-белая графика с фотографией генерала Андерса у дорожного указателя в Иране, с направлениями «Варшава» и «Тегеран». Слева, рядом с генералом Андерсом, стоят пять солдат польской армии на Востоке в летней форме.Вверху белые буквы в два ряда. Цитата из речи Андерса."
  }
}
</i18n>
