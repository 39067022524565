{
  "accessibility-declaration-title": "Deklaracja dostępności",
  "accessibility-declaration-url": "https://artistsinarms.pl/deklaracja-dostepnosci.html",
  "back-to-start": "Wróć do początku",
  "button-contrast-title": "Kontrast",
  "button-info-close-title": "Zamknij więcej informacji",
  "button-info-open-title": "Otwórz więcej informacji",
  "button-navigation-close-title": "Zamknij menu",
  "button-navigation-open-title": "Otwórz menu",
  "goto-article-label": "Przejdź do artykułu",
  "iam-url": "https://iam.pl/pl",
  "new-tab-info": "(otwiera się w nowej zakładce)",
  "niepodlegla-url": "https://niepodlegla.gov.pl/",
  "page-title": "Artists in Arms: Sztuka i kultura na szlaku Armii Andersa 1941-1945",
  "scroll-down": "Zjedź w dół",
  "video-message": "Twoja przegladarka nie wspiera elementu video."
}
