<template>
  <section class="section article" data-chapter="isfahan" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="collage isfahan-collage" aria-describedby="isfahan-collage-description">
      <div class="collage__inner">
        <div class="collage__merged" v-if="$screen.width < 1024">
          <ArticleCollageMerged file="06-isfahan" />
        </div>
        <template v-if="$screen.width < 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
        </template>
        <kinesis-container v-if="$screen.width >= 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
          <div class="collage__height">
            <kinesis-element :strength="10"></kinesis-element>
          </div>
          <div class="collage__absolute isfahan-collage__one">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/06-isfahan-panorama-2.png">
                <img data-src="@/assets/images/06-isfahan-panorama-2.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute isfahan-collage__two">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/06-isfahan-ordonka.png">
                <img data-src="@/assets/images/06-isfahan-ordonka.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute isfahan-collage__three">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/06-isfahan-panorama.png">
                <img data-src="@/assets/images/06-isfahan-panorama.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute isfahan-collage__four">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/06-isfahan-simply-red.png">
                <img data-src="@/assets/images/06-isfahan-simply-red.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute isfahan-collage__five">
            <kinesis-element :strength="30" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/06-isfahan-youngster.png">
                <img data-src="@/assets/images/06-isfahan-youngster.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute isfahan-collage__six">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/06-isfahan-maszynistka.png">
                <img data-src="@/assets/images/06-isfahan-maszynistka.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute isfahan-collage__seven">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/06-isfahan-drzewa.png">
                <img data-src="@/assets/images/06-isfahan-drzewa.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
        </kinesis-container>
      </div>
    </div>
    <div id="isfahan-collage-description" class="visually-hidden">{{ $t('collage_description') }}</div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import ArticleText from '@/components/partials/ArticleText.vue'
import ArticleCollageMerged from '@/components/partials/ArticleCollageMerged.vue'

export default {
  components: {
    ArticleCollageMerged,
    ArticleText
  },
  data() {
    return {
      sectionNumber: 21
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Uchodźcy mile widziani!",
    "text": "Dzięki zorganizowanej akcji międzynarodowej udało się znaleźć dom dla dziesiątek tysięcy uratowanych z ZSRR dzieci na czterech kontynentach: od Nowej Zelandii po Meksyk.",
    "article_url": "https://culture.pl/pl/superartykul/armia-cywilna-kobiety-dzieci-i-inni",
    "collage_description": "Grafika stworzona techniką kolażu. W tle krajobraz gór w Iranie. Po lewej uśmiechnięta twarz Hanki Ordonówny. Pośrodku radosny chłopiec w mundurze, z naręczem bochenków chleba. W lewym dolnym rogu – sylwetka kobiety piszącej na maszynie."
  },
  "en": {
    "title": "Refugees welcome!",
    "text": "Thanks to an international effort, tens of thousands of children rescued from the USSR received homes on four continents – from New Zealand to Mexico.",
    "article_url": "https://culture.pl/en/feature/a-civilian-army-women-children-others",
    "collage_description": "In the background – a mountainous landscape in Iran. On the left – the smiling face of Hanka Ordonówna. In the centre – a happy boy in a military uniform, with an armful of bread loaves. In the bottom left-hand corner – a typist."
  },
  "ru": {
    "title": "Беженцы — добро пожаловать!",
    "text": "Благодаря организованной международной акции десяткам тысяч спасенных из СССР детей удалось найти дом на четырех континентах: от Новой Зеландии до Мексики.",
    "article_url": "https://culture.pl/ru/superarticle/armiya-grazhdanskikh-zhenschiny-deti-i-prochie",
    "collage_description": "Графика, выполненная в технике коллажа. На заднем плане — пейзаж гор в Иране. Слева улыбающееся лицо Ганки Ордонувны. В центре — радостный мальчик в военной форме держит в руках буханки хлеба. В левом нижнем углу — силуэт машинистки, печатающей на машинке."
  }
}
</i18n>
