<template>
  <section class="section article" data-chapter="jerozolima" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="collage jerozolima-collage" aria-describedby="jerozolima-collage-description">
      <div class="collage__inner">
        <div class="collage__merged" v-if="$screen.width < 1024">
          <ArticleCollageMerged file="09-jerozolima" />
        </div>
        <template v-if="$screen.width < 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
        </template>
        <kinesis-container v-if="$screen.width >= 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
          <div class="collage__height">
            <kinesis-element :strength="10"></kinesis-element>
          </div>
          <div class="collage__absolute jerozolima-collage__one">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/09-jerozolima-dom.png">
                <img data-src="@/assets/images/09-jerozolima-dom.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute jerozolima-collage__two">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/09-jerozolima-bg.png">
                <img data-src="@/assets/images/09-jerozolima-bg.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute jerozolima-collage__three">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/09-jerozolima-zolnierz-2.png">
                <img data-src="@/assets/images/09-jerozolima-zolnierz-2.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute jerozolima-collage__four">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/09-jerozolima-gazik.png">
                <img data-src="@/assets/images/09-jerozolima-gazik.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute jerozolima-collage__five">
            <kinesis-element :strength="30" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/09-jerozolima-akwarelka.png">
                <img data-src="@/assets/images/09-jerozolima-akwarelka.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute jerozolima-collage__six">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/09-jerozolima-zolnierz-1.png">
                <img data-src="@/assets/images/09-jerozolima-zolnierz-1.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute jerozolima-collage__seven">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/09-jerozolima-kaktus.png">
                <img data-src="@/assets/images/09-jerozolima-kaktus.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
        </kinesis-container>
      </div>
    </div>
    <div id="jerozolima-collage-description" class="visually-hidden">{{ $t('collage_description') }}</div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import ArticleText from '@/components/partials/ArticleText.vue'
import ArticleCollageMerged from '@/components/partials/ArticleCollageMerged.vue'

export default {
  components: {
    ArticleCollageMerged,
    ArticleText
  },
  data() {
    return {
      sectionNumber: 32
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Pisarze i dezerterzy",
    "text": "Pobyt w Palestynie podziałał twórczo na wielu literatów znajdujących się w szeregach Armii. Z kolei dla licznych żołnierzy pochodzenia żydowskiego był to ostatni przystanek na szlaku - odegrali ważną rolę w powstaniu państwa Izrael.",
    "article_url": "https://culture.pl/pl/superartykul/pisarze-i-dezerterzy",
    "collage_description": "Grafika stworzona techniką kolażu. W tle narożny budynek w Tel-Awiwie z napisem „Bank P.K.O”. Przed nim po bokach dwóch polskich żołnierzy w letnich mundurach. Z przodu jeep Polskich Sił Zbrojnych na Zachodzie. Centralnie akwarela - grafika trzech postaci w strojach bliskowschodnich. Środkowa – wysoka kobieta z koszem na głowie. Akwarela - grafika kaktusa."
  },
  "en": {
    "title": "Writers & deserters",
    "text": "Many writers who found themselves in the ranks of Anders’ Army took creative inspiration from their time in Palestine. For many of those of Jewish descent, this became their last stop on the trail – where they would later play an important role in the rise of the state of Israel.",
    "article_url": "https://culture.pl/en/feature/writers-deserters-the-polish-experience-in-the-promised-land",
    "collage_description": "In the background –  a corner building in Tel Aviv with a signboard reading ‘Bank P.K.O.’. In front of it are two Polish soldiers in summer uniforms. In the front – a jeep of the Polish Armed Forces in the West. In the centre – a fragment of a watercolour painting, with three figures in traditional Middle Eastern clothes. The one in the middle is a woman with a basket on her head.  A watercolour painting of a cactus."
  },
  "ru": {
    "title": "Писатели и дезертиры",
    "text": "Палестина вдохновляла многих писателей, находившихся в рядах армии. Для многочисленных солдат еврейского происхождения это была последняя остановка в пути — они сыграли важную роль в создании государства Израиль.",
    "article_url": "https://culture.pl/ru/superarticle/pisateli-i-dezertiry-v-armii-andersa",
    "collage_description": "Графика, выполненная в технике коллажа. На заднем плане — угловое здание в Тель-Авиве с надписью «Bank P.K.O». Перед ним по бокам стоят два польских солдата в летней форме. Впереди джип польских военных сил. В центре — акварельная графика с изображением трех фигур в ближневосточных костюмах. Посередине стоит высокая женщина с корзиной на голове. Акварель: кактус."
  }
}
</i18n>
