<template>
  <section class="section video" data-chapter="buzuluk" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="video__inner">
      <Video file="02-buzuluk" a11yid="buzuluk-caption" :a11ydescription="$t('a11y_description')" />
      <VideoDescription :caption="$t('caption')" :source="$t('source')" />
      <VideoPreloader />
    </div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import Video from '@/components/partials/Video.vue'
import VideoDescription from '@/components/partials/VideoDescription.vue'
import VideoPreloader from '@/components/partials/VideoPreloader.vue'

export default {
  components: {
    Video,
    VideoDescription,
    VideoPreloader
  },
  data() {
    return {
      sectionNumber: 8
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "caption": "Obóz polskiej Armii w ZSRR.",
    "source": "Źródło: Instytut Polski i Muzeum im. gen. Sikorskiego w Londynie",
    "a11y_description": "Śnieżna zima. Obóz Armii Andersa w ZSRR. Uzbrojeni żołnierze wybiegają z namiotów. Żołnierze w szeregu podczas apelu. "
  },
  "en": {
    "caption": "A Polish army camp in the USSR,",
    "source": "video: The Polish Institute and Sikorski Museum in London",
    "a11y_description": "Snowy winter. Anders’ Army military camp in the USSR. Armed soldiers run out of the tents. Soldiers lined up during a roll call."
  },
  "ru": {
    "caption": "Лагерь Польской армии в СССР,",
    "source": "видео: Польским институтом и музеем имени генерала Сикорского в Лондоне",
    "a11y_description": "Снежная зима. Лагерь армии Андерса в СССР. Из палаток выбегают вооруженные солдаты. Солдаты в строю во время переклички."
  }
}
</i18n>
