<template>
  <section class="section video" data-chapter="teheran" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="video__inner">
      <Video file="05-teheran" a11yid="teheran-caption" :a11ydescription="$t('a11y_description')" />
      <VideoDescription :caption="$t('caption')" :source="$t('source')" />
      <VideoPreloader />
    </div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import Video from '@/components/partials/Video.vue'
import VideoDescription from '@/components/partials/VideoDescription.vue'
import VideoPreloader from '@/components/partials/VideoPreloader.vue'

export default {
  components: {
    Video,
    VideoDescription,
    VideoPreloader
  },
  data() {
    return {
      sectionNumber: 19
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "caption": "Orkiestra Warsaw Parade pod batutą Henryka Warsa, 1937.",
    "source": "Źródło: Instytut Polski i Muzeum im. gen. Sikorskiego w Londynie",
    "a11y_description": "Mała sala widowiskowa. W tle ciemna kurtyna. Tam grafika kuli ziemskiej otoczona jasnym kluczem wiolinowym. Czarno-biały zapis żywiołowego występu małej orkiestry jazzowej złożonej z kilkunastu mężczyzn. Dyryguje Henryk Wars. Radosny kontrabasista, w tle sekcja dęta. Z tyłu w rzędzie – czterech saksofonistów. "
  },
  "en": {
    "caption": "Warsaw Parade Orchestra, conducted by Henryk Wars, 1937,",
    "source": "video: The Polish Institute and Sikorski Museum in London",
    "a11y_description": "A small auditorium. In the background – a dark curtain. There – a graphic of the globe surrounded by a light-coloured treble clef. A black-and-white recording of a lively performance by a small jazz orchestra made up of a dozen men. Henryk Wars as the conductor. A joyous double-bass player with a brass band in the background. In the back – four saxophonists in a row."
  },
  "ru": {
    "caption": "Оркестр Warsaw Parade под управлением Хенрика Варса, 1937,",
    "source": "видео: Польским институтом и музеем имени генерала Сикорского в Лондоне",
    "a11y_description": "Небольшой зрительный зал. На заднем плане темный занавес, на нем изображен земной шар внутри скрипичного ключа. Черно-белые съемки: выступление небольшого джазового оркестра, полтора десятка человек. Дирижер — Хенрик Варс. Улыбающийся контрабасист, духовой оркестр на заднем плане. В заднем ряду — четыре саксофониста."
  }
}
</i18n>
