<template>
  <section class="section article" data-chapter="kair" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="collage kair-collage" aria-describedby="kair-collage-description">
      <div class="collage__inner">
        <div class="collage__merged" v-if="$screen.width < 1024">
          <ArticleCollageMerged file="10-kair" />
        </div>
        <template v-if="$screen.width < 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
        </template>
        <kinesis-container v-if="$screen.width >= 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
          <div class="collage__height">
            <kinesis-element :strength="10"></kinesis-element>
          </div>
          <div class="collage__absolute kair-collage__one">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/10-kair-sfinks.png">
                <img data-src="@/assets/images/10-kair-sfinks.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute kair-collage__two">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/10-kair-pattern.png">
                <img data-src="@/assets/images/10-kair-pattern.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute kair-collage__three">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/10-kair-piramida.png">
                <img data-src="@/assets/images/10-kair-piramida.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute kair-collage__four">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/10-kair-triangle-blue.png">
                <img data-src="@/assets/images/10-kair-triangle-blue.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute kair-collage__five">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/10-kair-triangle-yellow.png">
                <img data-src="@/assets/images/10-kair-triangle-yellow.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute kair-collage__six">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/10-kair-tancerz.png">
                <img data-src="@/assets/images/10-kair-tancerz.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute kair-collage__seven">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/10-kair-triangle-red.png">
                <img data-src="@/assets/images/10-kair-triangle-red.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute kair-collage__eight">
            <kinesis-element :strength="20" :originX="0" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/10-kair-tancerka.png">
                <img data-src="@/assets/images/10-kair-tancerka.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute kair-collage__nine">
            <kinesis-element :strength="30" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/10-kair-palma.png">
                <img data-src="@/assets/images/10-kair-palma.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
        </kinesis-container>
      </div>
    </div>
    <div id="kair-collage-description" class="visually-hidden">{{ $t('collage_description') }}</div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import ArticleText from '@/components/partials/ArticleText.vue'
import ArticleCollageMerged from '@/components/partials/ArticleCollageMerged.vue'

export default {
  components: {
    ArticleCollageMerged,
    ArticleText
  },
  data() {
    return {
      sectionNumber: 35
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Teatr na piaskach",
    "text": "W Egipcie grali pod piramidami, we Włoszech – przy huku ostatnich wystrzałów. Teatr Dramatyczny 2. Korpusu to wyjątkowe zjawisko w historii polskiego teatru.",
    "article_url": "https://culture.pl/pl/superartykul/melpomena-na-piaskach-teatr-na-szlaku-armii-andersa",
    "collage_description": "Grafika stworzona techniką kolażu. W lewym górnym rogu grafika Sfinksa z jednym okiem, częściowo zakryta fragmentem piramidy. Przed nią kilku żołnierzy idących w prawą stronę. W lewym dolnym rogu Jadwiga Domańska w przedstawieniu „Turandot” wystawionym przez Teatr Dramatyczny 2. Korpusu Polskiego. Z jej lewej dłoni - wiązka światła w trzech pastelowych kolorach – niebieski, czerwony, żółty."
  },
  "en": {
    "title": "Theatre in the Desert",
    "text": "In Egypt, they played against the backdrop of the pyramids. In Italy, to the roar of the last shots being fired. The Dramatyczny Theatre of the 2nd Corps is a unique phenomenon in the history of Polish theatre.",
    "article_url": "https://culture.pl/en/feature/polish-plays-in-the-desert-the-dramatyczny-theatre-of-the-polish-ii-corps",
    "collage_description": "Top left corner – the image of a single-eyed Sphinx, partially covered with part of a pyramid. In front of it are a few soldiers walking to the right-hand side. In the bottom left-hand side is Jadwiga Domańska in the performance ‘Turandot’ by the Dramatyczny Theatre of the Polish II Corps. Out of her left hand – a three-pastel-colour beam of light in blue, red, yellow."
  },
  "ru": {
    "title": "Театр посреди песков",
    "text": "В Египте играли под пирамидами, в Италии — под грохот последних выстрелов. «Драматический театр 2-го Корпуса» — уникальное явление в истории польского театра.",
    "article_url": "https://culture.pl/ru/superarticle/melpomena-v-pustyne-teatr-v-armii-andersa",
    "collage_description": "Графика, выполненная в технике коллажа. В левом верхнем углу — Сфинкс, его частично заслоняет фрагмент пирамиды. Перед ним — несколько солдат. В левом нижнем углу — Ядвига Доманская в спектакле «Турандот» Драматического театра 2-го Польского корпуса. Из ее левой руки выходит пучок света трех пастельных цветов — синего, красного, желтого."
  }
}
</i18n>
