<template>
  <section ref="Section" class="section map epilogue-map" id="szlak-armii-andersa" data-chapter="szlak-armii-andersa"
           :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="map-wrapper">
      <div id="map-container" class="map-wrapper-outer">
        <h3>{{ $t('title') }}</h3>
        <div class="map-wrapper-inner" :style="containerStyles">
          <template v-if="$screen.width > 1920">
            <img src="@/assets/images/Mapa_szlak-Armii-Andersa2560x1440.jpg" alt="Mapa szlak Armii Andersa" ref="mapImage">
          </template>
          <template v-else-if="$screen.width > 1366">
            <img src="@/assets/images/Mapa_szlak-Armii-Andersa1920x1080.jpg" alt="Mapa szlak Armii Andersa" ref="mapImage">
          </template>
          <template v-else-if="$screen.width > 1024">
            <img src="@/assets/images/Mapa_szlak-Armii-Andersa1366x768.jpg" alt="Mapa szlak Armii Andersa" ref="mapImage">
          </template>
          <template v-else-if="$screen.width > 0">
            <img v-if="this.$i18n.locale === 'pl'" :style="imageStyles" src="@/assets/images/Mapa_szlak-Armii-Andersa_mobile.jpg" alt="Mapa szlak Armii Andersa" ref="mapImage">
            <img v-else-if="this.$i18n.locale === 'en'" :style="imageStyles" src="@/assets/images/Mapa_szlak-Armii-Andersa_mobile-en.png" alt="Map The Trail of Anders’ Army" ref="mapImage">
            <img v-else-if="this.$i18n.locale === 'ru'" :style="imageStyles" src="@/assets/images/Mapa_szlak-Armii-Andersa_mobile-ru.png" alt="Kарта Путь Армии Андерса" ref="mapImage">
          </template>
          <svg xmlns="http://www.w3.org/2000/svg" width="1100" height="640" viewBox="0 0 1091 640" id="army-path" ref="armyPath">
          <defs>
            <clipPath id="laoea">
              <path fill="#fff"
                    d="M744 163.986c0-6.35 5.15-11.5 11.5-11.5 6.352 0 11.5 5.15 11.5 11.5 0 6.352-5.148 11.5-11.5 11.5-6.35 0-11.5-5.148-11.5-11.5z"/>
            </clipPath>
          </defs>
          <g>
            <g>
              <path id="point-griazowiec" class="point point-1" fill="#8d183a" d="M577.833 8.152a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <g>
                <path id="point-buzuluk" class="point point-2" fill="#8d183a" d="M750 163.986a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
              </g>
              <g>
                <path id="point-buzuluk-outline" class="outline outline-2" fill="none" stroke="#8d183a" stroke-miterlimit="20" stroke-width="2"
                      d="M744 163.986c0-6.35 5.15-11.5 11.5-11.5 6.352 0 11.5 5.15 11.5 11.5 0 6.352-5.148 11.5-11.5 11.5-6.35 0-11.5-5.148-11.5-11.5z"
                      clip-path="url(&quot;#laoea&quot;)"/>
              </g>
            </g>
            <g>
              <path id="point-taszkient" class="point point-3" fill="#8d183a" d="M992.833 405.152a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-turkmenbaszy" class="point point-4" fill="#8d183a" d="M758.833 429.152a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-bandar-e-anzali" class="point point-5" fill="#8d183a" d="M709.833 474.238a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-teheran" class="point point-6" fill="#8d183a" d="M737.833 507.152a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-isfahan" class="point point-7" fill="#8d183a" d="M740.036 559.348a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-bagdad" class="point point-8" fill="#8d183a" d="M636.833 549.152a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-bejrut" class="point point-9" fill="#8d183a" d="M510.703 540.074a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-jerozolima" class="point point-10" fill="#8d183a" d="M506.833 575.152a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-kair" class="point point-11" fill="#8d183a" d="M455.833 610.984a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-aleksandria" class="point point-12" fill="#8d183a" d="M431.833 586.984a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-tarent" class="point point-13" fill="#8d183a" d="M247.833 423.484a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-bari" class="point point-14" fill="#8d183a" d="M242.333 407.484a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-monte-cassino" class="point point-15" fill="#8d183a" d="M200.333 404.484a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-bolonia" class="point point-16" fill="#8d183a" d="M161.333 343.484a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path id="point-londyn" class="point point-17" fill="#8d183a" d="M.333 194.484a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0z"/>
            </g>
            <g>
              <path class="line line-1-2" fill="none" stroke="#1d1d1e" stroke-dasharray="2 2" stroke-miterlimit="20" stroke-width=".5"
                    d="M583.333 20.034v0l24.796 43.573v0l24.869 9.152v0l73.957 74.564v0l29.33 10.496v0"/>
              <path class="mask mask-1-2" fill="none" stroke="#EEEBE6" stroke-dasharray="0" stroke-miterlimit="20" stroke-width="2"
                    d="M583.333 20.034v0l24.796 43.573v0l24.869 9.152v0l73.957 74.564v0l29.33 10.496v0"/>
            </g>
            <g>
              <path class="line line-2-3" fill="none" stroke="#1d1d1e" stroke-miterlimit="20" stroke-width="2"
                    d="M998.349 394.604v0-20.261 0L896.16 316.569v0S884.757 248.261 878 222.153c-11.15-9.441-104.682-50.438-104.682-50.438v0"/>
            </g>
            <g>
              <path class="line line-3-4" fill="none" stroke="#1d1d1e" stroke-miterlimit="20" stroke-width="2"
                    d="M991.329 414.184v0l-21.169 14.353v0h-49.686v0l-66.816-40.725v0l-14.685 17.27v0l-64.882 19.915v0"/>
            </g>
            <g>
              <path class="line line-4-5" fill="none" stroke="#1d1d1e" stroke-dasharray=".5 5" stroke-linecap="round" stroke-miterlimit="20"
                    stroke-width="2" d="M754.868 434.617c-29.317 3.405-36.282 29.91-36.282 29.91"/>
              <path class="mask mask-4-5" fill="none" stroke="#DAD2C7" stroke-dasharray=".5 5" stroke-linecap="round" stroke-miterlimit="20"
                    stroke-width="4" d="M754.868 434.617c-29.317 3.405-36.282 29.91-36.282 29.91"/>
            </g>
            <g>
              <path class="line line-5-6" fill="none" stroke="#1d1d1e" stroke-miterlimit="20" stroke-width="2"
                    d="M714.413 484.658v0l-1.651 11.017v0l18.39 7.466v0"/>
            </g>
            <g>
              <path class="line line-6-7" fill="none" stroke="#1d1d1e" stroke-miterlimit="20" stroke-width="2"
                    d="M739.82 518.639v0l-8.881 14.815v0l11.321 14.21v0"/>
            </g>
            <g>
              <path class="line line-7-8" fill="none" stroke="#1d1d1e" stroke-miterlimit="20" stroke-width="2"
                    d="M735.001 556.3v0l-30.41-12.768v0l-49.358 2.757v0"/>
            </g>
            <g>
              <path class="line line-8-9" fill="none" stroke="#1d1d1e" stroke-miterlimit="20" stroke-width="2"
                    d="M628.89 549.799v0l-48.938 6.282v0L544.921 538.9v0h-19.55v0"/>
            </g>
            <g>
              <path class="line line-9-10" fill="none" stroke="#1d1d1e" stroke-miterlimit="20" stroke-width="2"
                    d="M520.21 547.49v13.25l-7.007 4.108v0"/>
            </g>
            <g>
              <path class="line line-10-11" fill="none" stroke="#1d1d1e" stroke-miterlimit="20" stroke-width="2"
                    d="M512.083 583.938v31.77l-41.991-2.022v0"/>
            </g>
            <g>
              <path class="line line-11-12" fill="none" stroke="#1d1d1e" stroke-miterlimit="20" stroke-width="2"
                    d="M455.833 603.028l-10.28-10.354"/>
            </g>
            <g>
              <path class="line line-12-13" fill="none" stroke="#1d1d1e" stroke-dasharray=".5 5" stroke-linecap="round" stroke-miterlimit="20"
                    stroke-width="2" d="M431.834 580.482l-173.001-145.83"/>
              <path class="mask mask-12-13" fill="none" stroke="#DAD2C7" stroke-dasharray=".5 5" stroke-linecap="round" stroke-miterlimit="20"
                    stroke-width="4" d="M431.834 580.482l-173.001-145.83"/>
            </g>
            <g>
              <path class="line line-13-14" fill="none" stroke="#1d1d1e" stroke-miterlimit="20" stroke-width="2" d="M255.333 416.484v-4.806"/>
            </g>
            <g>
              <path class="line line-14-15" fill="none" stroke="#1d1d1e" stroke-miterlimit="20" stroke-width="2"
                    d="M239.538 404.984v0l-9.544-3.297v0l-14.044 1.995v0"/>
            </g>
            <g>
              <path class="line line-15-16" fill="none" stroke="#1d1d1e" stroke-miterlimit="20" stroke-width="2"
                    d="M208.751 395.71v0l3.67-13.209v0l-8.358-19.863v0l-27.487-12.535v0"/>
            </g>
            <g>
              <path class="line line-16-17" fill="none" stroke="#1d1d1e" stroke-dasharray="2 2" stroke-miterlimit="20" stroke-width=".5"
                    d="M161.333 334.549c-37.87-98.975-145.591-132.667-145.591-132.667"/>
              <path class="mask mask-16-17" fill="none" stroke="#EEEBE6" stroke-dasharray="2 2" stroke-miterlimit="20" stroke-width="2"
                    d="M161.333 334.549c-37.87-98.975-145.591-132.667-145.591-132.667"/>
            </g>
            <g>
              <g>
                <text class="text text-1"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning: normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(565)"
                      text-anchor="end">
                  <tspan>{{$t('Gryazovets')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-2"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning:normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(730 160)"
                      text-anchor="end">
                  <tspan>{{$t('Buzuluk')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-3"
                      dominant-baseline="text-before-edge"
                      style="line-height: 27px; font-kerning: normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(1013 395)">
                  <tspan>{{$t('Tashkent')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-4"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning: normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(750 380)"
                      text-anchor="end">
                  <tspan>{{$t('Turkmenbaszy')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-4-1"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning: normal"
                      fill="#202021"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(750 405)"
                      text-anchor="end">
                  <tspan>{{$t('Turkmenbaszy_2')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-5"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning: normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(700 465)"
                      text-anchor="end">
                  <tspan>{{$t('Anzali')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-5-1"
                      dominant-baseline="text-before-edge"
                      style="line-height:18px; font-kerning:normal;"
                      fill="#202021"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(700 489)"
                      text-anchor="end">
                  <tspan>{{$t('Anzali_2')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-6"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning:normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(760 495)">
                  <tspan>{{$t('Tehran')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-7"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px;
                      font-kerning:normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(760 550)">
                  <tspan>{{$t('Isfahan')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-8"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning:normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(643 561)">
                  <tspan>{{$t('Baghdad')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-9"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning: normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(500 528)"
                      text-anchor="end">
                  <tspan>{{$t('Beirut')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-10"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning: normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(527 573)">
                  <tspan>{{$t('Jerusalem')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-11"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning: normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(449 620)">
                  <tspan>{{$t('Cairo')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-12"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning: normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(420 580)"
                      text-anchor="end">
                  <tspan>{{$t('Alexandria')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-13"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning: normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(270 413)">
                  <tspan>{{$t('Taranto')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-14"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning: normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(259 385)">
                  <tspan>{{$t('Bari')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-15"
                      dominant-baseline="text-before-edge"
                      style="line-height: 18px; font-kerning: normal"
                      fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'"
                      font-size="16"
                      font-weight="700"
                      letter-spacing=".32"
                      transform="translate(190 396)"
                      text-anchor="end">
                  <tspan>{{$t('Monte-Cassino')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-16" dominant-baseline="text-before-edge" style="line-height:18px;font-kerning:normal" fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'" font-size="16" font-weight="700"
                      letter-spacing=".32" transform="translate(182 333)">
                  <tspan>{{$t('Bologna')}}</tspan>
                </text>
              </g>
            </g>
            <g>
              <g>
                <text class="text text-17" dominant-baseline="text-before-edge" style="line-height:18px;font-kerning:normal" fill="#8d183a"
                      font-family="'HK Grotesk','HKGrotesk-Bold'" font-size="16" font-weight="700"
                      letter-spacing=".32" transform="translate(26 185)">
                  <tspan>{{$t('London')}}</tspan>
                </text>
              </g>
            </g>
          </g>
        </svg>
        </div>
      </div>
    </div>
    <div class="controls" v-if="$screen.width <= 768">
      <button class="plus">+</button>
      <button class="minus">-</button>
    </div>
    <resize-observer @notify="handleResize" :showTrigger="true" />
  </section>
</template>

<script>
import anime from 'animejs';
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";

gsap.registerPlugin(Draggable);

function getPathLength(className) {
  if (document.getElementsByClassName(className)[0]) {
    return Math.round(document.getElementsByClassName(className)[0].getTotalLength())
  }

  return 0
}

export default {
  data() {
    return {
      sectionNumber: 49,
      timeline: {},
      draggable: {},
      currentZoom: 12,
      animated: false,
      containerStyles: {},
      imageStyles: {},
      resizeTimeout: {}
    }
  },
  mounted() {
    if (!this.animated) {
      this.animationSetup()
    }
    this.handleResize()
  },
  created (){
    setTimeout(() => {
      this.emitter.on('sectionIsActive', this.sectionIsActive)
    }, 500)
  },
  methods: {
    sectionIsActive(activeSection) {
      if (activeSection === this.sectionNumber) {
        this.handleResize()

        if (this.$screen.width > 1024) {
          this.animation()
        }
      }
    },
    handleZoom(event) {
      if (event.target.classList.contains('plus')) {
        if (this.currentZoom < 20) {
          this.currentZoom += 1
          this.getImageStyles()
        }
      } else {
        if (this.currentZoom > 0) {
          this.currentZoom -= 1
          this.getImageStyles()
        }
      }
    },
    handleResize() {
      let vm = this

      clearTimeout(this.resizeTimeout)
      this.resizeTimeout = setTimeout(() => {
        vm.getContainerStyles()
        vm.draggableSetup()
        vm.getImageStyles()
      }, 500)
    },
    getContainerStyles() {
      let styles = {}
      const mapWrapper = document.querySelector('.epilogue-map .map-wrapper')
      const mapInner = document.querySelector('.epilogue-map .map-wrapper-inner img')
      const width = this.$screen.width
      const height = this.$screen.height

      if (width && width >= 1024 && height && mapInner && mapWrapper && mapInner.clientWidth && mapInner.clientHeight) {
        const windowRatio = width/height
        const mapRatio = mapInner.clientWidth/mapInner.clientHeight

        if (mapRatio - windowRatio > 0) {
          // match width
          styles = {
            width: '100%',
            height: 'auto',
            marginTop: (height - mapInner.clientHeight) / 2 + 'px'
          }
        } else {
          // match height
          styles = {
            maxWidth: height * mapRatio + 'px',
            maxHeight: height + 'px',
            marginLeft: (width - mapInner.clientWidth) / 2 + 'px'
          }
        }
        this.containerStyles = styles
      } else {
        styles = {
          maxHeight: width * .8 + 'px'
        }
        this.containerStyles = styles
      }
    },
    getImageStyles () {
      const width = this.$screen.width

      if (width && width < 1024) {
        const factor = 5
        let scale = 100 + (this.currentZoom * factor)

        this.imageStyles = {
          width: scale + '%',
          height: 'auto',
          display: 'block'
        }
      } else {
        this.imageStyles = {}
      }
    },
    animationSetup() {
      this.timeline = anime.timeline({
        autoplay: false
      })

      const initialPointColor = 'transparent'
      const targetColor = '#8d183a'
      const targetColorSecondary = '#202021'
      const pointColorDuration = 700
      const lineDuration = 1000
      const linesLength = [];

      linesLength.push(0) // to move index by 1
      linesLength.push(getPathLength('line-1-2'))
      linesLength.push(getPathLength('line-2-3'))
      linesLength.push(getPathLength('line-3-4'))
      linesLength.push(getPathLength('line-4-5'))
      linesLength.push(getPathLength('line-5-6'))
      linesLength.push(getPathLength('line-6-7'))
      linesLength.push(getPathLength('line-7-8'))
      linesLength.push(getPathLength('line-8-9'))
      linesLength.push(getPathLength('line-9-10'))
      linesLength.push(getPathLength('line-10-11'))
      linesLength.push(getPathLength('line-11-12'))
      linesLength.push(getPathLength('line-12-13'))
      linesLength.push(getPathLength('line-13-14'))
      linesLength.push(getPathLength('line-14-15'))
      linesLength.push(getPathLength('line-15-16'))
      linesLength.push(getPathLength('line-16-17'))

      const maxLineLength = Math.max.apply(Math, linesLength)

      this.timeline.add({
        targets: '.epilogue-map .point-1, .epilogue-map .text-1',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        delay: 1000,
        easing: 'linear'
      })

      document.getElementsByClassName('mask-1-2')[0].style.strokeDasharray = linesLength[1];
      this.timeline.add({
        targets: '.epilogue-map .mask-1-2',
        strokeDashoffset: [0, -linesLength[1]],
        duration: lineDuration * (linesLength[1] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-2,.epilogue-map .text-2',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration / 2,
        easing: 'linear'
      }, '-=' + pointColorDuration / 2)

      this.timeline.add({
        targets: '.epilogue-map .outline-2',
        strokeWidth: [0, 2],
        duration: pointColorDuration / 2 ,
        easing: 'linear'
      })

      document.getElementsByClassName('line-2-3')[0].style.strokeDasharray = linesLength[2];
      this.timeline.add({
        targets: '.epilogue-map .line-2-3',
        strokeDashoffset: [-linesLength[2], 0],
        duration: lineDuration * (linesLength[2] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-3, .epilogue-map .text-3',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      document.getElementsByClassName('line-3-4')[0].style.strokeDasharray = linesLength[3];
      this.timeline.add({
        targets: '.epilogue-map .line-3-4',
        strokeDashoffset: [linesLength[3], 0],
        duration: lineDuration * (linesLength[3] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-4, .epilogue-map .text-4',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .text-4-1',
        fill: [initialPointColor, targetColorSecondary],
        duration: pointColorDuration,
        easing: 'linear'
      }, '-=' + pointColorDuration)

      document.getElementsByClassName('mask-4-5')[0].style.strokeDasharray = linesLength[4];
      this.timeline.add({
        targets: '.epilogue-map .mask-4-5',
        strokeDashoffset: [0, -linesLength[4]],
        duration: lineDuration * (linesLength[4] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-5, .epilogue-map .text-5',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .text-5-1',
        fill: [initialPointColor, targetColorSecondary],
        duration: pointColorDuration,
        easing: 'linear'
      }, '-=' + pointColorDuration)

      document.getElementsByClassName('line-5-6')[0].style.strokeDasharray = linesLength[5];
      this.timeline.add({
        targets: '.epilogue-map .line-5-6',
        strokeDashoffset: [linesLength[5], 0],
        duration: lineDuration * (linesLength[5] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-6, .epilogue-map .text-6',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      document.getElementsByClassName('line-6-7')[0].style.strokeDasharray = linesLength[6];
      this.timeline.add({
        targets: '.epilogue-map .line-6-7',
        strokeDashoffset: [linesLength[6], 0],
        duration: lineDuration * (linesLength[6] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-7, .epilogue-map .text-7',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      document.getElementsByClassName('line-7-8')[0].style.strokeDasharray = linesLength[7];
      this.timeline.add({
        targets: '.epilogue-map .line-7-8',
        strokeDashoffset: [linesLength[7], 0],
        duration: lineDuration * (linesLength[7] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-8,.epilogue-map .text-8',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      document.getElementsByClassName('line-8-9')[0].style.strokeDasharray = linesLength[8];
      this.timeline.add({
        targets: '.epilogue-map .line-8-9',
        strokeDashoffset: [linesLength[8], 0],
        duration: lineDuration * (linesLength[8] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-9, .epilogue-map .text-9',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      document.getElementsByClassName('line-9-10')[0].style.strokeDasharray = linesLength[9];
      this.timeline.add({
        targets: '.epilogue-map .line-9-10',
        strokeDashoffset: [linesLength[9], 0],
        duration: lineDuration * (linesLength[9] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-10, .epilogue-map .text-10',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      document.getElementsByClassName('line-10-11')[0].style.strokeDasharray = linesLength[10];
      this.timeline.add({
        targets: '.epilogue-map .line-10-11',
        strokeDashoffset: [linesLength[10], 0],
        duration: lineDuration * (linesLength[10] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-11, .epilogue-map .text-11',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      document.getElementsByClassName('line-11-12')[0].style.strokeDasharray = linesLength[11];
      this.timeline.add({
        targets: '.epilogue-map .line-11-12',
        strokeDashoffset: [linesLength[11], 0],
        duration: lineDuration * (linesLength[11] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-12, .epilogue-map .text-12',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      document.getElementsByClassName('mask-12-13')[0].style.strokeDasharray = linesLength[12];
      this.timeline.add({
        targets: '.mask-12-13',
        strokeDashoffset: [0, -linesLength[12]],
        duration: lineDuration * (linesLength[12] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-13, .epilogue-map .text-13',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      document.getElementsByClassName('line-13-14')[0].style.strokeDasharray = linesLength[13];
      this.timeline.add({
        targets: '.epilogue-map .line-13-14',
        strokeDashoffset: [linesLength[13], 0],
        duration: lineDuration * (linesLength[13] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-14, .epilogue-map .text-14',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      document.getElementsByClassName('line-14-15')[0].style.strokeDasharray = linesLength[14];
      this.timeline.add({
        targets: '.epilogue-map .line-14-15',
        strokeDashoffset: [linesLength[14], 0],
        duration: lineDuration * (linesLength[14] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-15, .epilogue-map .text-15',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      document.getElementsByClassName('line-15-16')[0].style.strokeDasharray = linesLength[15];
      this.timeline.add({
        targets: '.epilogue-map .line-15-16',
        strokeDashoffset: [linesLength[15], 0],
        duration: lineDuration * (linesLength[15] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-16, .epilogue-map .text-16',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })

      document.getElementsByClassName('mask-16-17')[0].style.strokeDasharray = linesLength[16];
      this.timeline.add({
        targets: '.epilogue-map .mask-16-17',
        strokeDashoffset: [0, -linesLength[16]],
        duration: lineDuration * (linesLength[16] / maxLineLength),
        easing: 'linear'
      })

      this.timeline.add({
        targets: '.epilogue-map .point-17, .epilogue-map .text-17',
        fill: [initialPointColor, targetColor],
        duration: pointColorDuration,
        easing: 'linear'
      })
    },
    animation() {
      if (this.animated) {
        return false
      }

      this.animated = true
      this.timeline.play()
    },
    draggableSetup() {
      if (this.$screen.width < 1024) {
        const outer = document.querySelectorAll('.epilogue-map .map-wrapper-inner')[0];
        const element = document.querySelectorAll('.epilogue-map .map-wrapper-inner img')[0];

        if (typeof this.draggable.enable === 'function') {
          this.draggable.enable()
          this.draggable.update()
        } else {
          this.draggable = Draggable.create(element, {
            bounds: outer,
            inertia: true
          })
        }

        const plus = document.querySelectorAll('.epilogue-map .controls .plus')[0];
        const minus = document.querySelectorAll('.epilogue-map .controls .minus')[0];

        if (plus) {
          plus.addEventListener('click', this.handleZoom)
        }

        if (minus) {
          minus.addEventListener('click', this.handleZoom)
        }
      } else {
        if (typeof this.draggable.disable === 'function') {
          this.draggable.kill()
        }
      }
    },
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Szlak Armii Andersa",
    "Gryazovets": "Griazowiec",
    "Buzuluk": "Buzułuk",
    "Tashkent": "Taszkent",
    "Turkmenbaszy": "Turkmenbaszy",
    "Turkmenbaszy_2": "(daw. Krasnowodzk)",
    "Anzali": "Bandar-e Anzali",
    "Anzali_2": "(daw. Pahlawi)",
    "Tehran": "Teheran",
    "Isfahan": "Isfahan",
    "Baghdad": "Bagdad",
    "Beirut": "Bejrut",
    "Jerusalem": "Jerozolima",
    "Cairo": "Kair",
    "Alexandria": "Aleksandria",
    "Taranto": "Tarent",
    "Bari": "Bari",
    "Monte-Cassino": "Monte Cassino",
    "Bologna": "Bolonia",
    "London": "Londyn"
  },
  "en": {
    "title": "The Trail of Anders’ Army",
    "Gryazovets": "Gryazovets",
    "Buzuluk": "Buzuluk",
    "Tashkent": "Tashkent",
    "Turkmenbaszy": "Turkmenbashi",
    "Turkmenbaszy_2": "(formerly Krasnovodsk)",
    "Anzali": "Bandar-e Anzali",
    "Anzali_2": "(formerly Pahlavi)",
    "Tehran": "Tehran",
    "Isfahan": "Isfahan",
    "Baghdad": "Baghdad",
    "Beirut": "Beirut",
    "Jerusalem": "Jerusalem",
    "Cairo": "Cairo",
    "Alexandria": "Alexandria",
    "Taranto": "Taranto",
    "Bari": "Bari",
    "Monte-Cassino": "Monte Cassino",
    "Bologna": "Bologna",
    "London": "London"
  },
  "ru": {
    "title": "Путь Армии Андерса",
    "Gryazovets": "Грязовец",
    "Buzuluk": "Бузулук",
    "Tashkent": "Ташкент",
    "Turkmenbaszy": "Туркменбаши",
    "Turkmenbaszy_2": "(бывший Красноводск)",
    "Anzali": "Бендер-Энзели",
    "Anzali_2": "(бывший Пехлеви)",
    "Tehran": "Тегеран",
    "Isfahan": "Исфахан",
    "Baghdad": "Багдад",
    "Beirut": "Бейрут",
    "Jerusalem": "Иерусалим",
    "Cairo": "Каир",
    "Alexandria": "Александрия",
    "Taranto": "Таранто",
    "Bari": "Бари",
    "Monte-Cassino": "Монте-Кассино",
    "Bologna": "Болонья",
    "London": "Лондон"
  }
}
</i18n>
