<template>
  <section class="section article" data-chapter="teheran" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="collage teheran-collage" aria-describedby="teheran-collage-description">
      <div class="collage__inner">
        <div class="collage__merged" v-if="$screen.width < 1024">
          <ArticleCollageMerged file="05-teheran" />
        </div>
        <template v-if="$screen.width < 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
        </template>
        <kinesis-container v-if="$screen.width >= 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
          <div class="collage__height">
            <kinesis-element :strength="10"></kinesis-element>
          </div>
          <div class="collage__absolute teheran-collage__one">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/05-teheran-slonce.png">
                <img data-src="@/assets/images/05-teheran-slonce.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute teheran-collage__two">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/05-teheran-smoking.png">
                <img data-src="@/assets/images/05-teheran-smoking.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute teheran-collage__three">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/05-teheran-triangle-2.png">
                <img data-src="@/assets/images/05-teheran-triangle-2.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute teheran-collage__four">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/05-teheran-mury.png">
                <img data-src="@/assets/images/05-teheran-mury.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute teheran-collage__five">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/05-teheran-skrzypce.png">
                <img data-src="@/assets/images/05-teheran-skrzypce.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute teheran-collage__six">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/05-teheran-akordeon.png">
                <img data-src="@/assets/images/05-teheran-akordeon.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute teheran-collage__seven">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/05-teheran-triangle.png">
                <img data-src="@/assets/images/05-teheran-triangle.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute teheran-collage__eight">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/05-teheran-zolnierz.png">
                <img data-src="@/assets/images/05-teheran-zolnierz.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute teheran-collage__nine">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/05-teheran-zolnierz.png">
                <img data-src="@/assets/images/05-teheran-zolnierz.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute teheran-collage__ten">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/05-teheran-konno.png">
                <img data-src="@/assets/images/05-teheran-konno.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute teheran-collage__eleven">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/05-teheran-miejscowy.png">
                <img data-src="@/assets/images/05-teheran-miejscowy.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
        </kinesis-container>
      </div>
    </div>
    <div id="teheran-collage-description" class="visually-hidden">{{ $t('collage_description') }}</div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import ArticleText from '@/components/partials/ArticleText.vue'
import ArticleCollageMerged from '@/components/partials/ArticleCollageMerged.vue'

export default {
  components: {
    ArticleCollageMerged,
    ArticleText
  },
  data() {
    return {
      sectionNumber: 18
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Cały ten jazz!",
    "text": "Wiele gwiazd przedwojennej estrady znalazło schronienie w Armii. Wśród nich byli ojcowie polskiego jazzu.",
    "article_url": "https://culture.pl/pl/superartykul/polska-piosenka-idzie-na-wojne ",
    "collage_description": "Grafika stworzona techniką kolażu. Pośrodku ozdobna brama pałacu w Teheranie. W jej łuku uśmiechnięty Gwidon Borucki z akordeonem. Po lewej wychylona sylwetka Adama Astona ze skrzypcami w dłoni."
  },
  "en": {
    "title": "All that jazz!",
    "text": "A number of stars from the pre-war Polish stage found refuge in the army – including the fathers of Polish jazz.",
    "article_url": "https://culture.pl/en/feature/polish-song-goes-to-war",
    "collage_description": "In the centre – an ornamental palace gate in Tehran. In the gate arch – the smiling face of Gwidon Borucki with an accordion. On the left – Adam Aston in a leaning posture, with a violin in his hand."
  },
  "ru": {
    "title": "Весь этот джаз!",
    "text": "Многие звезды довоенной эстрады нашли пристанище в армии. Среди них были основатели польского джаза.",
    "article_url": "https://culture.pl/ru/superarticle/polskaya-pesnya-idet-na-voynu",
    "collage_description": "Графика, выполненная в технике коллажа. В центре — украшенные ворота Тегеранского дворца. В арке улыбающийся Гвидон Боруцкий с аккордеоном. Слева Адам Астон со скрипкой в руках."
  }
}
</i18n>
