<template>
  <section ref="Section" class="section cover" id="krasnowodzk" data-chapter="krasnowodzk" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description_1') }}</p>
        <p>{{ $t('description_2') }}</p>
      </div>
      <div class="cover__number" aria-hidden="true">04</div>
      <div class="cover__title" aria-hidden="true">{{ $t('title') }}</div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 13
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Krasnowodzk",
    "description_1": "W kwietniu 1942 Sowieci wstrzymali całkowicie rekrutację do Armii Andersa. Na szczeblach dowództwa zapada decyzja o ewakuacji do Iranu. W ramach zorganizowanej akcji ZSRR opuszcza 114 tysięcy osób: cywilów i wojskowych. Do dziś uchodzi to za największy exodus w historii ZSRR.",
    "description_2": "Jednak Polaków, którzy pozostali po drugiej stronie granicy, było znacznie więcej niż tych, którym udało się wydostać. Wkrótce po ewakuacji na podstawie akcji \"paszportyzacji\" wszystkim Polakom pozostającym w ZSRR narzucono obywatelstwo sowieckie.",
    "screen_reader_title": "Rozdział 4 Krasnowodzk"
  },
  "en": {
    "title": "Krasnovodsk",
    "description_1": "In April 1942, the Soviets halted recruitment to Anders’ Army altogether. From the command levels came a decision to evacuate to Persia (today’s Iran). The aftermath led to over 114,000 people leaving the USSR – civilians and military alike. To this day, it is still considered the largest exodus in the history of the USSR.",
    "description_2": "But the Poles who remained were much more numerous than those who left. Soon after the evacuation, those still in the USSR were forced to adopt Soviet citizenship – part of the so-called ‘passportisation’ policy.",
    "screen_reader_title": "Chapter 4 Krasnovodsk"
  },
  "ru": {
    "title": "Красноводск",
    "description_1": "В апреле 1942 года Советский Союз полностью остановил прием в Армию Андерса. На уровне командования принимается решение об эвакуации в Иран. Во время акции СССР покидают более 114 тысяч человек: гражданских и военнослужащих. До сих пор это считается самым масштабным массовым выездом в истории СССР.",
    "description_2": "Однако поляков, которые остались по другую сторону границы, было намного больше, чем тех, кому удалось выбраться. Вскоре после этой эвакуации всем полякам, оставшимся в СССР, на основании «паспортизации» навязали советское гражданство.",
    "screen_reader_title": "Глава 4 Красноводск"
  }
}
</i18n>
