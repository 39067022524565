<template>
  <section ref="EpilogueAnimation" class="section animation epilogue-animation" data-chapter="epilog" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="section__inner">
      <template v-if="$screen.width < 1024">
        <Waypoint @change="onWaypointChange" :options="intersectionOptions" />
      </template>
      <template v-if="$screen.width >= 1024">
        <Waypoint @change="onWaypointChangeDesktop" :options="intersectionOptions" />
      </template>
      <div class="epilogue-animation__timeline epilogue-animation__timeline--frame-1">
        <div class="epilogue-animation__text">
          <div class="epilogue-animation__figure frame-1">
            <span class="animated-figure animated-figure-1">1334</span>
          </div>
          <div class="epilogue-animation__phrase frame-1">{{ $t('text_1') }}</div>
          <div class="epilogue-animation__figure frame-2">
            <span class="animated-figure animated-figure-2">120 000</span>
          </div>
          <div class="epilogue-animation__phrase frame-2">{{ $t('text_2') }}</div>
          <div class="epilogue-animation__figure frame-3">
            <span class="animated-figure animated-figure-3">12 500</span>
          </div>
          <div class="epilogue-animation__phrase frame-3">{{ $t('text_3') }}</div>
        </div>
      </div>
    </div>
    <resize-observer @notify="handleResize" :showTrigger="true" />
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 48,
      autoAnimationId: '',
      autoAnimationDelay: 4000,
      currentFrameNumber: 1,
      downKeys: [39, 40],
      upKeys: [37, 38],
      isAnimating: false,
      timeout: 700,
      totalFrameNumber: 3,
      intersectionOptions: {
        root: null,
        rootMargin: '0px 0px 0px 0px',
        threshold: [0.5]
      }
    }
  },
  methods: {
    handleWheel(event) {
      event.stopPropagation()
      if (this.isAnimating) {
        return
      }
      let direction = -event.deltaY
      if (direction < 0 && this.hasNext()) {
        this.next()
      } else if (direction > 0 && this.hasPrev()) {
        this.prev()
      }
    },
    handleKeyUp(event) {
      if (this.isAnimating) {
        return
      }
      let key = event.which
      if (this.upKeys.includes(key) && this.hasPrev()) {
        this.prev()
      } else if (this.downKeys.includes(key) && this.hasNext()) {
        this.next()
      }
    },
    next() {
      if (this.currentFrameNumber >= this.totalFrameNumber) {
        this.stopAnimation()
        this.$parent.scrollDown()
        document.removeEventListener('keyup', this.handleKeyUp)
        document.addEventListener('keyup', this.$parent.handleKeyUp)
        return
      }
      this.Animation(++this.currentFrameNumber)
    },
    prev() {
      if (this.currentFrameNumber <= 1) {
        this.stopAnimation()
        this.$parent.scrollUp()
        document.removeEventListener('keyup', this.handleKeyUp)
        document.addEventListener('keyup', this.$parent.handleKeyUp)
        return
      }
      this.Animation(--this.currentFrameNumber)
    },
    Animation(slideNumber) {
      const timeline = document.getElementsByClassName('epilogue-animation__timeline')[0]
      timeline.className = 'epilogue-animation__timeline'
      timeline.classList.add(`epilogue-animation__timeline--frame-${slideNumber}`)
      this.stopAnimation()
    },
    hasNext() {
      return this.currentFrameNumber <= this.totalFrameNumber
    },
    hasPrev() {
      return this.currentFrameNumber >= 1
    },
    stopAnimation() {
      this.isAnimating = true
      setTimeout(() => {
        this.isAnimating = false
      }, this.timeout)
    },
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN' && this.$screen.width < 1024) {
        this.startAutoAnimation()
        const section = this.$refs.EpilogueAnimation

        section.scrollIntoView({
          behavior: 'smooth'
        })
      }
      if (waypointState.going === 'OUT' && this.$screen.width < 1024) {
        this.stopAutoAnimation()
      }
    },
    onWaypointChangeDesktop(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN' && this.$screen.width >= 1024) {
        document.removeEventListener('keyup', this.$parent.handleKeyUp)
        document.addEventListener('keyup', this.handleKeyUp)
      }
    },
    startAutoAnimation() {
      this.autoAnimationId = setInterval(this.autoAnimation, this.autoAnimationDelay)
    },
    stopAutoAnimation() {
      clearInterval(this.autoAnimationId)
    },
    autoAnimation() {
      ++this.currentFrameNumber
      if (this.currentFrameNumber > this.totalFrameNumber) {
        this.currentFrameNumber = 1
      }
      const timeline = document.getElementsByClassName('epilogue-animation__timeline')[0]
      timeline.className = 'epilogue-animation__timeline'
      timeline.classList.add(`epilogue-animation__timeline--frame-${this.currentFrameNumber}`)
    },
    handleResize({ width }) {
      if (width >= 1024) {
        this.stopAutoAnimation()
        this.Animation(1)
      }
    }
  },
  mounted() {
    const section = this.$refs.EpilogueAnimation
    if (this.$screen.width >= 1024) {
      section.addEventListener('wheel', this.handleWheel)
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "text_1": "dni",
    "text_2": "osób",
    "text_3": "kilometrów"
  },
  "en": {
    "text_1": "days",
    "text_2": "people",
    "text_3": "kilometres"
  },
  "ru": {
    "text_1": "дня",
    "text_2": "человек",
    "text_3": "километров"
  }
}
</i18n>
