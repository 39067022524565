<template>
  <div class="language-switcher">
    <ul class="language-switcher__items">
      <li v-for="(lang, i) in langs" :key="`lang-${i}`" @click="switchLocale(lang)" @keyup.enter="switchLocale(lang)" class="language-switcher__item" :class="`language-switcher__item--${modeClass}`" tabindex="0">
        <span class="language-switcher__lang" :class="[`language-switcher__lang--${modeClass}`, currentLang(lang)]">{{ lang }}</span>
        <span v-if="isNotLast(i)" class="language-switcher__pipe" :class="`language-switcher__pipe--${modeClass}`"></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'LanguageSwitcher',
  props: ['mode'],
  data() {
    return {
      langs: ['pl', 'en', 'ru'],
      modeClass: this.mode
    }
  },
  methods: {
    switchLocale(lang) {
      if (this.$i18n.locale !== lang) {
        this.$i18n.locale = lang
        this.$router.push({ path: lang })
      }
    },
    isNotLast(i) {
      if (i < this.langs.length - 1) {
        return true
      }
      return false
    },
    currentLang(lang) {
      if (this.$i18n.locale === lang) {
        return 'active'
      }
      return ''
    }
  }
}
</script>
