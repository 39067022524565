<template>
  <section class="section video" data-chapter="krasnowodzk" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="video__inner">
      <Video file="04-krasnowodzk" a11yid="krasnowodzk-caption" :a11ydescription="$t('a11y_description')" />
      <VideoDescription :caption="$t('caption')" :source="$t('source')" />
      <VideoPreloader />
    </div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import Video from '@/components/partials/Video.vue'
import VideoDescription from '@/components/partials/VideoDescription.vue'
import VideoPreloader from '@/components/partials/VideoPreloader.vue'

export default {
  components: {
    Video,
    VideoDescription,
    VideoPreloader
  },
  data() {
    return {
      sectionNumber: 14
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "caption": "Polscy uchodźcy w Persji.",
    "source": "Źródło: Instytut Polski i Muzeum im. gen. Sikorskiego w Londynie",
    "a11y_description": "Liczna grupa uchodźców wita się z bliskimi. Ludzie w każdym wieku, z dorobkiem życia docierają do obozu na Bliskim Wschodzie."
  },
  "en": {
    "caption": "Polish refugees in Persia,",
    "source": "video: The Polish Institute and Sikorski Museum in London",
    "a11y_description": "A numerous group of refugees greet their nearest and dearest. People of all ages, carrying everything they own, reach the refugee camp in the Middle East."
  },
  "ru": {
    "caption": "Польские беженцы в Персии,",
    "source": "видео: Польским институтом и музеем имени генерала Сикорского в Лондоне",
    "a11y_description": "Многочисленные беженцы приветствуют своих близких. Люди всех возрастов, нагруженные вещами, прибывают в лагерь на Ближнем Востоке."
  }
}
</i18n>
