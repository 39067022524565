<template>
  <section ref="Section" class="section cover" id="buzuluk" data-chapter="buzuluk" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description') }}</p>
      </div>
      <div class="cover__number" aria-hidden="true">02</div>
      <div class="cover__title" aria-hidden="true">{{ $t('title') }}</div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 7
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Buzułuk",
    "description": "W sierpniu 1941 roku, po ataku III Rzeszy na ZSRR, z obozów zwalniani są polscy obywatele. Mimo radzieckiej polityki dezinformacji i utrudnień z całej Rosji do punktów zbornych ściągają dziesiątki tysięcy obywateli II RP. Na południu Rosji, w Buzułuku, rozpoczyna się formowanie polskiej armii pod wodzą zwolnionego z Łubianki generała Władysława Andersa.",
    "screen_reader_title": "Rozdział 2 Buzułuk"
  },
  "en": {
    "title": "Buzuluk",
    "description": "In August 1941, after the Third Reich attacked the USSR, Polish citizens were released from the camps. In spite of the difficulties and the Soviet politics of disinformation, tens of thousands of citizens of the Polish Second Republic travelled across Russia to reach dedicated assembly points. In the north of Russia, in Buzuluk, a Polish army began to form under the leadership of General Władysław Anders – a former Polish Army general who had been released from Moscow’s Lubyanka prison.",
    "screen_reader_title": "Chapter 2 Buzuluk"
  },
  "ru": {
    "title": "Бузулук",
    "description": "В августе 1941 года, после нападения Германии на СССР, из лагерей освобождают польских граждан. Несмотря на советскую политику дезинформации, на созданные советскими властями трудности, со всей России в пункты сбора прибывают десятки тысяч граждан Польской Республики. На юге России, в Бузулуке, начинается формирование Польской армии под командованием освобожденного из Лубянской тюрьмы генерала Владислава Андерса.",
    "screen_reader_title": "Глава 2 Бузулук"
  }
}
</i18n>
