<template>
  <section class="section video" data-chapter="monte-cassino" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="video__inner">
      <Video file="11-monte-cassino" a11yid="monte-cassino-caption" :a11ydescription="$t('a11y_description')" />
      <VideoDescription :caption="$t('caption')" :source="$t('source')" />
      <VideoPreloader />
    </div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import Video from '@/components/partials/Video.vue'
import VideoDescription from '@/components/partials/VideoDescription.vue'
import VideoPreloader from '@/components/partials/VideoPreloader.vue'

export default {
  components: {
    Video,
    VideoDescription,
    VideoPreloader
  },
  data() {
    return {
      sectionNumber: 40
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "caption": "Polskie oddziały w pobliżu Monte Cassino, 1944.",
    "source": "Źródło: Instytut Polski i Muzeum im. gen. Sikorskiego w Londynie",
    "a11y_description": "Środkowe Włochy. Wojskowy samochód pokonuje zakręt. Nad drogą wiszą zerwane przewody elektryczne. Kolumny pojazdów wojskowych mijają się wolno po piaszczystej drodze."
  },
  "en": {
    "caption": "Polish units near Monte Cassino, 1944,",
    "source": "video: The Polish Institute and Sikorski Museum in London",
    "a11y_description": "Central Italy. A military car takes a right turn. Broken electric cables hang above the road. Columns of military vehicles slowly pass each other on a sandy road."
  },
  "ru": {
    "caption": "Польские отряды вблизи Монте-Кассино, 1944 год,",
    "source": "видео: Польским институтом и музеем имени генерала Сикорского в Лондоне",
    "a11y_description": "Центральная Италия. Военная машина преодолевает крутой поворот. Над дорогой нависают оборванные электрические провода. Колонны военных машин медленно проезжают по песчаной дороге."
  }
}
</i18n>
