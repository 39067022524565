<template>
  <section class="section cover intro-cover" data-chapter="intro" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description_1') }}</p>
        <p>{{ $t('description_2') }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      sectionNumber: 2
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "description_1": "W lecie 1941 roku w centralnej Rosji powstała polska armia, jedna z najbardziej niezwykłych formacji wojskowych w historii. W jej skład weszli obywatele II Rzeczypospolitej: dawni żołnierze Wojska Polskiego, więźniowie gułagów oraz cywile, wśród nich liczne grono artystów i ludzi kultury. Armia otoczyła opieką również wysiedlone w głąb ZSRR dzieci i kobiety.",
    "description_2": "Losy tych ludzi podczas długiego marszu do domu przez trzy kontynenty – a szczególnie rola kultury i sztuki w obliczu doświadczenia wojny - to główny temat naszej opowieści.",
    "screen_reader_title": "Wstęp"
  },
  "en": {
    "description_1": "In the summer of 1941, a Polish army came into being in central Russia – it was one of the most unusual military formations in history. It was made up of citizens of the Second Polish Republic, including former Polish Army soldiers, newly released gulag prisoners, and civilians – all of whom included many artists, and others who had been involved in cultural activities. The army also took in women and children who had been displaced deep within the USSR.",
    "description_2": "This project centres on the fates of these people during their long march home across three continents – especially on the unique role of arts and culture in their wartime experiences.",
    "screen_reader_title": "Introduction"
  },
  "ru": {
    "description_1": "Летом 1941 года в центральной России появилась польская армия. Это было одно из самых необычных армейских формирований в истории. В него вошли граждане Польской Республики: бывшие солдаты Польской армии, узники ГУЛАГа и гражданские, в числе которых — артисты, художники, деятели культуры. Армия заботилась и о выселенных вглубь СССР детях и женщинах.",
    "description_2": "Судьба этих людей во время долгого пути домой через три континента, а кроме того, роль культуры и искусства во время войны — главная тема нашего рассказа.",
    "screen_reader_title": "Вступление"
  }
}
</i18n>
