<template>
  <section class="section quote jerozolima-quote" data-chapter="jerozolima" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="quote__inner quote__inner--without-author-picture">
      <template v-if="$screen.width < 1024">
        <QuoteImageMobile fileSm="09-jerozolima-quote-sm.jpg" fileMd="09-jerozolima-quote-md.jpg" :description="$t('image_description')" :alt="$t('author_picture_alt')" />
      </template>
      <figure>
        <blockquote>
          <p>
            <span>{{ $t('quote_1') }}<br />
            {{ $t('quote_2') }}<br />
            {{ $t('quote_3') }}<br />
            {{ $t('quote_4') }}</span>
          </p>
        </blockquote>
        <figcaption>
          <span class="quote__author-name"><span>{{ $t('author_name') }}</span></span>
          <span class="quote__author-title"><span>{{ $t('author_title') }}</span></span>
        </figcaption>
      </figure>
      <template v-if="$screen.width >= 1024">
        <div class="quote__background-image jerozolima-quote__background-image lazy-background" role="img" :aria-label="$t('author_picture_alt')"></div>
        <div class="quote__background-image-description">
          <p><span>{{ $t('image_description') }}</span></p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import QuoteImageMobile from '@/components/partials/QuoteImageMobile.vue'

export default {
  components: {
    QuoteImageMobile
  },
  data() {
    return {
      sectionNumber: 33
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "quote_1": "– Stąd się nie wychodzi – mówił konwojent, który pilnował nas w drodze do obozu przejściowego nad brzegiem Peczory. [...]",
    "quote_2": "– Zostaniecie uwolnieni we wrześniu 1948 – poinformował mnie obozowy kancelista [...]",
    "quote_3": "– Nigdy nie zobaczycie państwa żydowskiego – zapewniali mnie oficerowie śledczy.",
    "quote_4": "A jednak.",
    "author_name": "Menachem Begin,",
    "author_title": "żołnierz Armii Andersa, premier Izraela 1977-1983",
    "image_description": "Fot. Wikimedia Commons",
    "author_picture_alt": "Czarno-biała fotografia ze zdjęciem 5 osób, z których w dolnym rzędzie, pierwszy z lewej to Menachem Begin (żołnierz Armii Andersa, premier Izraela w latach 1977-1983) w mundurze Armii Polskiej na Wschodzie. Obok niego, po prawej jego żona - Aliza. Pośrodku białe litery w kilku rzędach. Cytat jego wypowiedzi.",

  },
  "en": {
    "quote_1": "‘People never get out of here’, said the sentry who escorted me to the transit camp on the River Pechora. [...]",
    "quote_2": "‘You will be released in September 1948’, a camp office clerk told me [...]",
    "quote_3": "‘You will never see a Jewish state’, my interrogators assured me.",
    "quote_4": "Nonetheless, I did.",
    "author_name": "Menachem Begin,",
    "author_title": "soldier in Anders’ Army, Prime Minister of Israel 1977-1983",
    "image_description": "Photo: Wikimedia Commons",
    "author_picture_alt": "A black-and-white photograph of five persons. The first in the lower row is Menachem Begin (a soldier of Anders’ Army, the prime minister of Israel from 1977 to 1983) wearing the Polish Army in the East military uniform. Next to him on the right – his wife Aliza. In the centre white letters in a few rows. Begin’s statement is quoted.",
  },
  "ru": {
    "quote_1": "«Отсюда не выходят», — сказал мне конвоир на пути в Печорлаг.",
    "quote_2": "«День освобождения: 20 сентября 1948 года», — записал конторщик перевалочного лагеря.",
    "quote_3": "НКВДшники, потешаясь, говорили мне: «Нет, вы еврейского государства не увидите».",
    "quote_4": "И тем не менее…",
    "author_name": "Менахем Бегин,",
    "author_title": "солдат Армии Андерса, премьер-министр Израиля в 1977–1983 годах",
    "image_description": "Фото: Wikimedia Commons",
    "author_picture_alt": "Черно-белая фотография с изображением 5 человек, первый слева в нижнем ряду  — Менахем Бегин (солдат Армии Андерса, премьер-министра Израиля в 1977-1983 гг.) в форме Войска Польского на Востоке. Рядом с ним, справа, его жена Ализа. В центре белые буквы в несколько рядов. Цитата из его речи",

  }
}
</i18n>
