<template>
  <section class="section video" data-chapter="isfahan" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="video__inner">
      <Video file="06-isfahan" a11yid="isfahan-caption" :a11ydescription="$t('a11y_description')" />
      <VideoDescription :caption="$t('caption')" :source="$t('source')" />
      <VideoPreloader />
    </div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import Video from '@/components/partials/Video.vue'
import VideoDescription from '@/components/partials/VideoDescription.vue'
import VideoPreloader from '@/components/partials/VideoPreloader.vue'

export default {
  components: {
    Video,
    VideoDescription,
    VideoPreloader
  },
  data() {
    return {
      sectionNumber: 23
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "caption": "Polskie dzieci w wiosce dla uchodźców w Afryce Wschodniej.",
    "source": "Źródło: Instytut Polski i Muzeum im. gen. Sikorskiego w Londynie",
    "a11y_description": "Grupa dzieci żywiołowo maszeruje w lewą stronę. W tle dzieci z flagą marszowym krokiem idą w prawo.  Obóz uchodźców. W tle okrągłe afrykańskie chaty, kryte strzechą. Pośrodku przechodzi kobieta z dwoma wiadrami. Kilkuletni chłopiec ciekawsko podbiega przed kamerę."
  },
  "en": {
    "caption": "Polish children in a refugee village in East Africa,",
    "source": "video: The Polish Institute and Sikorski Museum in London",
    "a11y_description": "A group of children walk energetically to the left side. In the background – children, holding a flag, march to the right. Refugee camp. In the background – rounded, thatched African huts. In the centre – a woman holding two buckets walks by. A boy of a few years old runs up with curiosity to the camera."
  },
  "ru": {
    "caption": "Польские дети в деревне для беженцев в Восточной Африке,",
    "source": "видео: Польским институтом и музеем имени генерала Сикорского в Лондоне",
    "a11y_description": "Группа детей оживленно марширует. На заднем плане справа маршируют дети с флагом.  Лагерь беженцев. Круглые африканские хижины с соломенными крышами на заднем плане. Женщина с двумя ведрами идет посередине. Маленький мальчик с любопытством подбегает к камере."
  }
}
</i18n>
