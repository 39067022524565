<template>
  <template v-if="$screen.width < 1024">
    <Waypoint @change="onWaypointChange" />
  </template>
  <figure>
    <video muted loop playsinline id="video" :poster="getPoster()" preload="metadata" ref="Video" :aria-describedby="a11yId">
      <source v-if="$screen.width <= 852" :src="getVideo('852x480')" type="video/mp4">
      <source v-else-if="$screen.width > 852 && $screen.width <= 1280" :src="getVideo('1280x720')" type="video/mp4">
      <source v-else :src="getVideo('1920x1080')" type="video/mp4">
      {{ $t('default.video-message') }}
    </video>
    <figcaption :id="a11yId" class="visually-hidden">
      {{ a11yDescription }}
    </figcaption>
  </figure>
</template>

<script>
import { Waypoint } from 'vue-waypoint'
import { useScreen } from 'vue-screen'

export default {
  name: 'Video',
  props: ['file', 'a11yid', 'a11ydescription'],
  components: {
    Waypoint
  },
  data () {
    return {
      a11yId: this.a11yid,
      a11yDescription: this.a11ydescription,
      fileName: this.file,
      videoElement: ''
    }
  },
  setup() {
    const screen = useScreen()
    return {
      screen
    }
  },
  methods: {
    getPoster() {
      if (screen.width <= 852) {
        return require(`@/assets/video/${this.fileName}-poster-md.jpg`)
      } else if (screen.width > 852 && screen.width <= 1280) {
        return require(`@/assets/video/${this.fileName}-poster-lg.jpg`)
      } else {
        return require(`@/assets/video/${this.fileName}-poster-xl.jpg`)
      }
    },
    getVideo(screenResolution) {
      return require(`@/assets/video/${this.fileName}-${screenResolution}.mp4`)
    },
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.videoElement.load()
        this.videoElement.play()
        this.videoElement.addEventListener('playing', this.handleVideoPlaying)
        this.videoElement.addEventListener('pause', this.handleVideoPause)
      }
      if (waypointState.going === 'OUT') {
        this.videoElement.pause()
      }
    },
    handleVideoPlaying(event) {
      const video = event.target
      const preloader = video.closest('section').getElementsByClassName('video__preloader')[0]

      preloader.classList.add('video__preloader--hidden')
      video.removeEventListener('playing', this.handleVideoPlaying)
    },
    handleVideoPause(event) {
      const video = event.target
      const tmpSrc = video.currentSrc
      const playtime = video.currentTime

      video.src = ''
      video.load()
      video.src = tmpSrc
      video.currentTime = playtime
      video.removeEventListener('pause', this.handleVideoPause)
    },
  },
  mounted () {
    this.videoElement = this.$refs.Video
  }
}
</script>
