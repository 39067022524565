<template>
  <section ref="Section" class="section cover" id="jerozolima" data-chapter="jerozolima" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description') }}</p>
      </div>
      <div class="cover__number" aria-hidden="true">09</div>
      <div class="cover__title" aria-hidden="true">{{ $t('title') }}</div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 31
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Jerozolima",
    "description": "W Palestynie kwitł emigracyjny ośrodek polskiej kultury, a pobyt w śródziemnomorskim klimacie był wytchnieniem dla żołnierzy zmęczonych spiekotą irackiej pustyni. W Tel Awiwie i Jerozolimie spotykali swoich rodaków, polskich Żydów, którzy osiedlili się tu przed wojną – dzięki tej kulturowej bliskości mogli się tu poczuć \"prawie jak w domu\".",
    "screen_reader_title": "Rozdział 9 Jerozolima"
  },
  "en": {
    "title": "Jerusalem",
    "description": "A hive of emigrant Polish culture bloomed in Palestine. The Mediterranean climate was a respite for the soldiers, who were tired after sweltering in the Iraqi desert. In Tel Aviv and Jerusalem, they met their compatriots – Polish Jews who had settled there before the war. Thanks to their cultural closeness, they could almost feel at home there.",
    "screen_reader_title": "Chapter 9 Jerusalem"
  },
  "ru": {
    "title": "Иерусалим",
    "description": "В Палестине сформировался настоящий центр польской культуры. Средиземноморский климат помогал солдатам, уставшим от знойной иракской пустыни, восстановить силы. В Тель-Авиве и Иерусалиме они встречали соотечественников, польских евреев, поселившихся здесь до войны — благодаря культурной близости польские солдаты могли чувствовать себя здесь «почти как дома».",
    "screen_reader_title": "Глава 9 Иерусалим"
  }
}
</i18n>
