<template>
  <section class="section article" data-chapter="taszkient" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="collage taszkient-collage" aria-describedby="taszkient-collage-description">
      <div class="collage__inner">
        <div class="collage__merged" v-if="$screen.width < 1024">
          <ArticleCollageMerged file="03-taszkient" />
        </div>
        <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
        <kinesis-container v-if="$screen.width >= 1024">
          <div class="collage__height">
            <kinesis-element :strength="10"></kinesis-element>
          </div>
          <div class="collage__absolute taszkient-collage__one">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/03-taszkient-wielblad.png">
                <img data-src="@/assets/images/03-taszkient-wielblad.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute taszkient-collage__two">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/03-taszkient-ulica.png">
                <img data-src="@/assets/images/03-taszkient-ulica.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute taszkient-collage__three">
            <kinesis-element :strength="30" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/03-taszkient-triangle.png">
                <img data-src="@/assets/images/03-taszkient-triangle.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute taszkient-collage__four">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/03-taszkient-wielblad-glowa.png">
                <img data-src="@/assets/images/03-taszkient-wielblad-glowa.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute taszkient-collage__five">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/03-taszkient-krzeslo.png">
                <img data-src="@/assets/images/03-taszkient-krzeslo.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute taszkient-collage__six">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/03-taszkient-czapski.png">
                <img data-src="@/assets/images/03-taszkient-czapski.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
        </kinesis-container>
      </div>
    </div>
    <div id="taszkient-collage-description" class="visually-hidden">{{ $t('collage_description') }}</div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import ArticleText from '@/components/partials/ArticleText.vue'
import ArticleCollageMerged from '@/components/partials/ArticleCollageMerged.vue'

export default {
  components: {
    ArticleCollageMerged,
    ArticleText
  },
  data() {
    return {
      sectionNumber: 11
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Album graniczny",
    "text": "W Uzbekistanie Józef Czapski zaczyna pisać dziennik. Tę niezwykłą wizualno-tekstową opowieść będzie prowadził przez następne pół wieku.",
    "article_url": "https://culture.pl/pl/superartykul/migawki-do-portretu-wojenne-dzienniki-jozefa-czapskiego",
    "collage_description": "Grafika stworzona techniką kolażu. Po lewej Józef Czapski na czerwonym krześle. W tle fragment historycznej zabudowy miasta Taszkient. Nad Czapskim głowa wielbłąda. "
  },
  "en": {
    "title": "Album from the border",
    "text": "In Uzbekistan, Czapski began keeping a diary, in images and writing. He would continue to develop its remarkable contents over the next half century.",
    "article_url": "https://culture.pl/en/feature/snapshots-of-a-portrait-the-wartime-diaries-of-jozef-czapski",
    "collage_description": "On the left – Józef Czapski on a chair. In the background – a fragment of the historic buildings of Tashkent. Above it – a camel’s head. "
  },
  "ru": {
    "title": "Пограничный альбом",
    "text": "В Узбекистане Юзеф Чапский начинает вести дневник. Это необычное, иллюстрированное рисунками повествование он будет продолжать следующие полвека.",
    "article_url": "https://culture.pl/ru/superarticle/yuzef-chapskiy-shtrikhi-k-portretu",
    "collage_description": "Графика, выполненная в технике коллажа. Слева Юзеф Чапский на красном стуле. На заднем плане фрагмент исторических зданий Ташкента. Над Чапским — голова верблюда."
  }
}
</i18n>
