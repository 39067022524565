<template>
  <div class="contrast-switcher">
    <button @click="switchContrast" class="contrast-switcher__btn" :class="`contrast-switcher__btn--${modeClass}`">
      <span class="visually-hidden">{{ $t('default.button-contrast-title') }}</span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ContrastSwitcher',
  props: ['mode'],
  data () {
    return {
      isHighContrast: false,
      modeClass: this.mode
    }
  },
  methods: {
    switchContrast() {
      if (!this.isHighContrast) {
        document.getElementsByTagName('body')[0].classList.add('high-contrast')
        this.isHighContrast = true
      } else {
        document.getElementsByTagName('body')[0].classList.remove('high-contrast')
        this.isHighContrast = false
      }
    }
  }
}
</script>
