{
  "accessibility-declaration-title": "Декларация доступности ",
  "accessibility-declaration-url": "https://artistsinarms.pl/deklaratsiya-dostupnosti.html",
  "back-to-start": "В начало",
  "button-contrast-title": "Контраст",
  "button-info-close-title": "Закрыть дополнительную информации",
  "button-info-open-title": "Открыть дополнительную информацию ",
  "button-navigation-close-title": "Закрыть меню",
  "button-navigation-open-title": "Open menu",
  "goto-article-label": "Читать статью",
  "iam-url": "https://iam.pl/en",
  "new-tab-info": "(откроется в новой вкладке)",
  "niepodlegla-url": "https://niepodlegla.gov.pl/ru",
  "page-title": "Армия художников. Культура и искусство на маршрутах армии Андерса 1941-1945",
  "scroll-down": "Вниз",
  "video-message": "Ваш браузер не поддерживает тег video."
}
