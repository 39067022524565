<template>
  <section class="section quote monte-cassino-quote" data-chapter="monte-cassino" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="quote__inner">
      <template v-if="$screen.width < 1024">
        <QuoteImageMobile fileSm="11-monte-cassino-quote-sm.jpg" fileMd="11-monte-cassino-quote-md.jpg" :description="$t('image_description')" :alt="$t('author_picture_alt')"/>
        <QuoteAuthorPictureMobile file="11-monte-cassino-quote-author-picture-sm.png" :alt="$t('author_picture_alt')" />
      </template>
      <template v-if="$screen.width >= 1024">
        <div class="quote__author-picture">
          <div class="quote__author-picture-inner">
            <picture class="lazy">
              <source media="(min-width:1921px)" data-srcset="@/assets/images/11-monte-cassino-quote-author-picture.png">
              <img data-src="@/assets/images/11-monte-cassino-quote-author-picture.png" :alt="$t('author_picture_alt')" class="monte-cassino-quote__author-image" />
            </picture>
          </div>
        </div>
      </template>
      <figure>
        <blockquote>
          <p><span>{{ $t('quote') }}</span></p>
        </blockquote>
        <figcaption>
          <span class="quote__author-name"><span>{{ $t('author_name') }}</span></span>
          <span class="quote__author-title"><span>{{ $t('author_title') }}</span></span>
        </figcaption>
      </figure>
      <template v-if="$screen.width >= 1024">
        <div class="quote__background-image monte-cassino-quote__background-image lazy-background"></div>
        <div class="quote__background-image-description">
          <p><span>{{ $t('image_description') }}</span></p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import QuoteImageMobile from '@/components/partials/QuoteImageMobile.vue'
import QuoteAuthorPictureMobile from '@/components/partials/QuoteAuthorPictureMobile.vue'

export default {
  components: {
    QuoteImageMobile,
    QuoteAuthorPictureMobile
  },
  data() {
    return {
      sectionNumber: 39
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "quote": "Była to bez wątpienia bitwa wielka. Chcieliśmy jej, żyliśmy myślą o niej w Palestynie, w Iraku, w Egipcie, szkoląc się na pustyni, nasłuchując wiadomości z Polski.",
    "author_name": "Gustaw Herling-Grudziński,",
    "author_title": "pisarz",
    "author_picture_alt": "Czarno-biała grafika z mapą wzgórz w okolicy góry Monte Cassino w tle. W lewym dolnym rogu fragment białego koła, na nim portret Gustawa Herlinga-Grudzińskiego. Wzrok skierowany w obiektyw aparatu. Pośrodku białe litery w kilku rzędach. Cytat jego wypowiedzi. ",
    "image_description": "Fot. Biblioteka Narodowa Polona"
  },
  "en": {
    "quote": "It was, without a doubt, the great battle. We had longed for it; we had lived on the thought of it in Palestine, in Iraq, in Egypt, while training in the desert, waiting for any news from Poland.",
    "author_name": "Gustaw Herling-Grudziński,",
    "author_title": "writer",
    "author_picture_alt": "A black-and-white graphic map of the hills near the Monte Cassino mountain. In the bottom left corner – a piece of a white circle, with Gustaw Herling-Grudzinski’s portrait on it. His gaze is directed to the camera lens. In the centre – white letters in a few rows. A quote from his statement.",
    "image_description": "Photo: National Digital Library Polona"
  },
  "ru": {
    "quote": "Это была, без сомнения, великая битва. Мы жаждали ее, жили мыслью о ней в Палестине, Ираке, Египте, во время учений в пустыне, вслушиваясь в новости из Польши.",
    "author_name": "Густав Херлинг-Грудзинский,",
    "author_title": "писатель",
    "author_picture_alt": "Черно-белая графика с картой холмов вокруг Монте-Кассино на заднем плане. В левом нижнем углу — фрагмент белого круга, на нем портрет Густава Херлинга-Грудзинского. Он смотрит в объектив камеры. В центре белые буквы в несколько рядов. Цитата из его речи.",
    "image_description": "Фото: Национальная библиотека Polona"
  }
}
</i18n>
