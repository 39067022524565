<template>
  <section class="section quote buzuluk-quote" data-chapter="buzuluk" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="quote__inner">
      <template v-if="$screen.width < 1024">
        <QuoteImageMobile fileSm="02-buzuluk-quote-sm.jpg" fileMd="02-buzuluk-quote-md.jpg" :description="$t('image_description')" :alt="$t('author_picture_alt')" />
        <QuoteAuthorPictureMobile file="02-buzuluk-quote-author-picture-sm.png" :alt="$t('author_picture_alt')" />
      </template>
      <template v-if="$screen.width >= 1024">
        <div class="quote__author-picture">
          <div class="quote__author-picture-inner">
            <picture class="lazy">
              <source media="(min-width:1921px)" data-srcset="@/assets/images/02-buzuluk-quote-author-picture.png">
              <img data-src="@/assets/images/02-buzuluk-quote-author-picture.png" :alt="$t('author_picture_alt')" class="buzuluk-quote__author-image" />
            </picture>
          </div>
        </div>
      </template>
      <figure>
        <blockquote>
          <p><span>{{ $t('quote') }}</span></p>
        </blockquote>
        <figcaption>
          <span class="quote__author-name"><span>{{ $t('author_name') }}</span></span>
          <span class="quote__author-title"><span>{{ $t('author_title') }}</span></span>
        </figcaption>
      </figure>
      <template v-if="$screen.width >= 1024">
        <div class="quote__background-image buzuluk-quote__background-image lazy-background"></div>
        <div class="quote__background-image-description">
          <p><span>{{ $t('image_description') }}</span></p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import QuoteImageMobile from '@/components/partials/QuoteImageMobile.vue'
import QuoteAuthorPictureMobile from '@/components/partials/QuoteAuthorPictureMobile.vue'

export default {
  components: {
    QuoteImageMobile,
    QuoteAuthorPictureMobile
  },
  data() {
    return {
      sectionNumber: 9
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "quote": "Serce mi się ścisnęło, gdy patrzyłem na tych nędzarzy, i zapytywałem się w duchu, czy uda się z nich jeszcze zrobić wojsko. [...] Dość było popatrzeć w te błyszczące oczy, z których wyzierała wola i wiara [...] Pierwszy i – daj, Boże – ostatni raz w życiu przyjąłem defiladę żołnierzy bez butów.",
    "author_name": "Władysław Anders,",
    "author_title": "dowódca Polskich Sił Zbrojnych w ZSRR",
    "author_picture_alt": "Czarno biała fotografia.  W tle – perspektywiczne ujęcie żołnierzy Armii Polskiej w ZSRR w zimowych mundurach podczas apelu. W lewym dolnym rogu fragment białego koła, na nim portret gen. Andersa w mundurze, spoglądającego w lewo. Pośrodku białe litery w kilku rzędach. Cytat wypowiedzi gen. Władysława Andersa.",
    "image_description": "Fot. www.audiovis.nac.gov.pl (NAC)"
  },
  "en": {
    "quote": "Looking at those down-and-out people, my heart nearly burst, and I asked myself, silently, if it would even be possible to make an army out of them. […] It was enough to look into their bright eyes, which were shining with faith and willpower […] It was the first and – God willing – last time I ever received a parade of barefooted soldiers.",
    "author_name": "Władysław Anders,",
    "author_title": "commander of the Polish Armed Forces in the USSR",
    "author_picture_alt": "A black-and-white photograph. In the background – a perspective shot of the Polish Army in the USSR in winter uniforms, during a military role call. In the bottom left corner, a piece of a white circle, on it – a portrait of Józef Czapski in a military uniform, glancing to the left.",
    "image_description": "Photo: www.audiovis.nac.gov.pl (NAC)"
  },
  "ru": {
    "quote": "У меня сжималось сердце, когда я смотрел на этих несчастных. Я мысленно спрашивал себя, удастся ли еще сделать из них армию. [...] Но достаточно было посмотреть в их горящие глаза, светящиеся волей и верой [...] Первый и — даст Бог — последний раз в жизни я принимал парад, в котором шли босые солдаты.",
    "author_name": "Владислав Андерс,",
    "author_title": "командующий польскими вооруженными силами в СССР",
    "author_picture_alt": "Черно-белая фотография.  На заднем плане — солдаты Войска Польского в СССР в зимней форме, во время переклички. В левом нижнем углу — фрагмент белого круга, на нем — портрет  смотрящего влево генерала Андерса в мундире. В центре белые буквы в несколько рядов. Цитата из обращения генерала Владислава Андерса.",
    "image_description": "Фото: www.audiovis.nac.gov.pl (NAC)"
  }
}
</i18n>
