<template>
  <section ref="Section" class="section cover" id="teheran" data-chapter="teheran" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description') }}</p>
      </div>
      <div class="cover__number" aria-hidden="true">05</div>
      <div class="cover__title" aria-hidden="true">{{ $t('title') }}</div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 17
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Teheran",
    "description": "Ewakuacja z ZSRR do Persji przebiegała drogą lądową i morską. W tym ostatnim przypadku statki pełne uchodźców płynęły z Krasnowodzka do Pahlawi, określanego przez Polaków \"bramą do wolności\". Uchodźcy i żołnierze zostali rozlokowani po całym Iranie: od Meszhedu i Teheranu po Ahwaz i Arak.",
    "screen_reader_title": "Rozdział 5 Teheran"
  },
  "en": {
    "title": "Tehran",
    "description": "The evacuation from the USSR to Persia took place on land and by sea. In the latter case, boats full of refugees sailed from Krasnovodsk to Pahlavi – which the Poles referred to as ‘the gateway to freedom’. The soldiers and refugees alike were deployed across Iran: from Mashhad and Tehran, to Ahvaz and Arak.",
    "screen_reader_title": "Chapter 5 Teheran"
  },
  "ru": {
    "title": "Тегеран",
    "description": "Эвакуация из СССР в Персию осуществлялась по суше и по морю. В последнем случае корабли с беженцами отправлялись из Красноводска в Пехлеви, который поляки называли «воротами к свободе». Беженцы и солдаты были размещены по всему Ирану: от Мешхеда и Тегерана до Ахваза и Эрака.",
    "screen_reader_title": "Глава 5 Тегеран"
  }
}
</i18n>
