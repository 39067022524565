<template>
  <section ref="Section" class="section footer" id="o-projekcie" data-chapter="o-projekcie" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="footer__inner">
      <h2 class="visually-hidden">{{ $t('about_project_header') }}</h2>
      <div class="footer__bar footer__bar--top">
        <h3 class="footer__header">{{ $t('partners') }}</h3>
        <div class="footer__flex footer__flex--center">
          <div class="footer__column footer__column--grow-1">
            <div class="footer__partner">
              <div class="footer__partner-logo footer__partner-logo--ipms">
                <a href="#" target="_blank" rel="nofollow noopener noreferrer">
                  <img :alt="$t('partner_1')" src="@/assets/images/instytut-polski-muzeum-sikorskiego-londyn.jpg" width="83" height="83" class="footer__partner-logo footer__partner-logo--instytut-polski-muzeum-sikorskiego" />
                  <span class="visually-hidden">{{ $t('default.new-tab-info') }}</span>
                </a>
              </div>
              <div class="footer__partner-title">{{ $t('partner_1') }}</div>
            </div>
          </div>
          <div class="footer__column footer__column--grow-1">
            <div class="footer__partner">
              <div class="footer__partner-logo footer__partner-logo--mnk">
                <a v-if="this.$i18n.locale === 'pl'" href="https://mnk.pl/" target="_blank" rel="nofollow noopener noreferrer">
                  <img :alt="$t('partner_2')" src="@/assets/images/mnk-logo.svg" width="61" height="59" />
                  <span class="visually-hidden">{{ $t('default.new-tab-info') }}</span>
                </a>
                <a v-else href="https://mnk.pl/en" target="_blank" rel="nofollow noopener noreferrer">
                  <img :alt="$t('partner_2')" src="@/assets/images/mnk-logo.svg" width="61" height="59" />
                  <span class="visually-hidden">{{ $t('default.new-tab-info') }}</span>
                </a>
              </div>
              <div class="footer__partner-title">{{ $t('partner_2') }}</div>
            </div>
          </div>
          <div class="footer__column footer__column--grow-1">
            <div class="footer__partner">
              <div class="footer__partner-logo footer__partner-logo--ilfkp">
                <a href="#" target="_blank" rel="nofollow noopener noreferrer">
                  <img :alt="$t('partner_3')" src="@/assets/images/instytut-literacki-fundacja-kp-logo.svg" width="176" height="61" />
                  <span class="visually-hidden">{{ $t('default.new-tab-info') }}</span>
                </a>
              </div>
              <div class="footer__partner-title">{{ $t('partner_3') }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer__bar footer__bar--middle">
        <div class="footer__flex">
          <div class="footer__column footer__column--grow-1">
            <div class="footer__text">{{ $t('financing') }}</div>
          </div>
          <div class="footer__column footer__column--grow-2">
            <div class="footer__financing-logo footer__financing-logo--mkdnis">
              <a href="https://www.gov.pl/web/kultura" target="_blank" rel="nofollow noopener noreferrer">
                <img v-if="this.$i18n.locale === 'pl'" alt="Ministerstwo Kultury, Dziedzictwa Narodowego i Sportu" src="@/assets/images/mkdnis-logo.svg" width="144" height="61" />
                <img v-else alt="Ministry of Culture, National Heritage and Sport of the Republic of Poland" src="@/assets/images/mkdnis-logo-en.svg" width="140" height="62" />
                <span class="visually-hidden">{{ $t('default.new-tab-info') }}</span>
              </a>
            </div>
            <div class="footer__financing-logo footer__financing-logo--niepodlegla">
              <a :href="$t('default.niepodlegla-url')" target="_blank" rel="nofollow noopener noreferrer">
                <img alt="Niepodległa logo" src="@/assets/images/niepodlegla-logo.svg" width="196" height="59" />
                <span class="visually-hidden">{{ $t('default.new-tab-info') }}</span>
              </a>
            </div>
            <div class="footer__financing-logo footer__financing-logo--culture">
              <a :href="$t('default.iam-url')" target="_blank" rel="nofollow noopener noreferrer">
                <img v-if="this.$i18n.locale === 'pl'" alt="Instytut Adama Mickiewicza logo" src="@/assets/images/logo-culture-iam.svg" width="140" height="46" />
                <img v-else alt="Adam Mickiewicz Institute logo" src="@/assets/images/logo-culture-iam-en.svg" width="140" height="46" />
                <span class="visually-hidden">{{ $t('default.new-tab-info') }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer__bar footer__bar--bottom">
        <div class="footer__flex">
          <div class="footer__column footer__column--text footer__column--grow-1">
            <h3 class="footer__header">{{ $t('visual_sources_header') }}</h3>
            <div class="footer__text">{{ $t('visual_sources_text') }}</div>
            <h3 class="footer__header">{{ $t('photo_material_header') }}</h3>
            <div class="footer__text">
              {{ $t('photo_material_text_1') }}<br />
              {{ $t('photo_material_text_2') }}<br />
              {{ $t('photo_material_text_3') }}<br />
              {{ $t('photo_material_text_4') }}
            </div>
          </div>
          <div class="footer__column footer__column--text footer__column--grow-1">
            <h3 class="footer__header">{{ $t('thanks_header') }}</h3>
            <div class="footer__text">{{ $t('thanks_text') }}</div>
          </div>
          <div class="footer__column footer__column--text footer__column--grow-1">
            <h3 class="footer__header">{{ $t('producer_header') }}</h3>
            <div class="footer__text">{{ $t('producer_text') }}</div>
            <h3 class="footer__header footer__header--margin-top-double">{{ $t('work_header') }}</h3>
            <div class="footer__text">{{ $t('work_text') }}</div>
            <h3 class="footer__header footer__header--margin-top-double">{{ $t('design_header') }}</h3>
            <div class="footer__text">{{ $t('design_text') }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer__back">
      <a href="#wstep" @click="handleClick(0)" class="footer__back-link">{{ $t('default.back-to-start') }}</a>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  name: 'Footer',
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 50
    }
  },
  methods: {
    handleClick(sectionNumber) {
      this.$parent.handleClick(sectionNumber)
    },
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "about_project_header": "O projekcie",
    "partners": "Partnerzy",
    "partner_1": "Instytut Polski i Muzeum im. gen. Sikorskiego w Londynie",
    "partner_2": "Muzeum Narodowe w Krakowie",
    "partner_3": "Instytut Literacki — Fundacja \"Kultury\" Paryskiej",
    "financing": "Sfinansowano ze środków Ministra Kultury, Dziedzictwa Narodowego i Sportu w ramach Programu Wieloletniego NIEPODLEGŁA na lata 2017–2022",
    "visual_sources_header": "Materiały wideo",
    "visual_sources_text": "Wszystkie materiały wideo pochodzą ze zbiorów Instytutu Polskiego i Muzeum im. gen. Sikorskiego w Londynie (IPMS).",
    "photo_material_header": "Materiały fotograficzne",
    "photo_material_text_1": "Narodowe Archiwum Cyfrowe (NAC)",
    "photo_material_text_2": "Biblioteka Narodowa Polona",
    "photo_material_text_3": "Instytut Literacki — Fundacja \"Kultury\" Paryskiej",
    "photo_material_text_4": "Instytut Polski i Muzeum im. gen. Sikorskiego (IPMS)",
    "thanks_header": "Podziękowania",
    "thanks_text": "Dziękujemy pani Weronice Orkisz za wyrażenie zgody na wykorzystanie prac Józefa Czapskiego oraz pani Krystynie Mew za możliwość wykorzystania prac Edwarda Herzbauma.",
  "producer_header": "Producent",
    "producer_text": "Instytut Adama Mickiewicza",
    "work_header": "Koncepcja & Tekst",
    "work_text": "Mikołaj Gliński, Patryk Zakrzewski",
    "design_header": "Projekt graficzny",
    "design_text": "rzeczyobrazkowe"
  },
  "en": {
    "about_project_header": "About project",
    "partners": "Partners",
    "partner_1": "The Polish Institute and Sikorski Museum in London",
    "partner_2": "National Museum in Kraków",
    "partner_3": "Literary Institute – Paris ‘Kultura’ Foundation",
    "financing": "Financed by the Ministry of Culture, National Heritage and Sport of the Republic of Poland as part of the multi-annual programme NIEPODLEGŁA 2017–2022",
    "visual_sources_header": "Videos",
    "visual_sources_text": "All video footage is part of the archival collection of The Polish Institute and Sikorski Museum in London.",
    "photo_material_header": "Photos",
    "photo_material_text_1": "National Digital Archive (NAC)",
    "photo_material_text_2": "National Digital Library Polona",
    "photo_material_text_3": "Literary Institute – Paris ‘Kultura’ Foundation",
    "photo_material_text_4": "The Polish Institute and Sikorski Museum in London",
    "thanks_header": "Special thanks",
    "thanks_text": "We would like to express our gratitude to Mrs Weronika Orkisz and Mrs Krystyna Mew for allowing us to present the works of Józef Czapski and Edward Herzbaum, respectively.",
    "producer_header": "Producer",
    "producer_text": "Adam Mickiewicz Institute",
    "work_header": "Concept & text",
    "work_text": "Mikołaj Gliński, Patryk Zakrzewski",
    "design_header": "Graphic design",
    "design_text": "rzeczyobrazkowe"
  },
  "ru": {
    "about_project_header": "O проекте",
    "partners": "Партнеры",
    "partner_1": "Польский институт и музей имени генерала Сикорского в Лондоне",
    "partner_2": "Национальный музей в Кракове",
    "partner_3": "Институт литературы — Фонд парижской «Культуры»",
    "financing": "Финансируется Министерством культуры, национального наследия и спорта как часть многолетнего проекта NIEPODLEGŁA, реализуемого в 2017–2022 годах.",
    "visual_sources_header": "Источники фото- и видеоматериалов:",
    "visual_sources_text": "Все видеоматериалы предоставлены Польским институтом и музеем имени генерала Сикорского в Лондоне (IPMS)",
    "photo_material_header": "Фотоматериалы",
    "photo_material_text_1": "Национальный цифровой архив (NAC)",
    "photo_material_text_2": "Национальная библиотека Polona",
    "photo_material_text_3": "Институт литературы — Фонд парижской «Культуры»",
    "photo_material_text_4": "Польский институт и музей имени генерала Сикорского в Лондоне (IPMS)",
    "thanks_header": "Благодарности:",
    "thanks_text": "Благодарим Веронику Оркиш за позволение использовать работы Юзефа Чапского и Кристину Мев за возможность использовать работы Эдварда Херцбаума.",
    "producer_header": "Продукция:",
    "producer_text": "Институт Адама Мицкевича",
    "work_header": "Концепция, сценарий и текст:",
    "work_text": " Миколай Глинский, Патрик Закшевский",
    "design_header": "Графика:",
    "design_text": "rzeczyobrazkowe"
  }
}
</i18n>
