<template>
  <section class="section intro" id="wstep" data-chapter="intro" :data-number="sectionNumber">
    <div class="section__inner">
      <div class="intro__header">
        <img src="@/assets/images/00-artists-in-arms-header-landscape.svg" v-if="$screen.landscape" class="intro__header-image" alt="" />
        <img src="@/assets/images/00-artists-in-arms-header-portrait.svg" v-if="$screen.portrait" class="intro__header-image" alt="" />
        <img src="@/assets/images/00-artists-in-arms-header-landscape-hc.svg" v-if="$screen.landscape" class="intro__header-image intro__header-image--hc" alt="" />
        <img src="@/assets/images/00-artists-in-arms-header-portrait-hc.svg" v-if="$screen.portrait" class="intro__header-image intro__header-image--hc" alt="" />
        <h1 class="visually-hidden"><span lang="en">Artists in arms</span>. {{ $t('title_top') }} {{ $t('title_bottom') }}</h1>
        <h1 class="intro__title" aria-hidden="true">{{ $t('title_top') }} <span>{{ $t('title_bottom') }}</span></h1>
      </div>
      <div class="intro__scroll-down">
        <span class="intro__scroll-down-label">{{ $t('default.scroll-down') }}</span>
        <span class="intro__scroll-down-pipe"></span>
      </div>
      <div class="intro__footer">
        <div class="intro__footer-inner">
          <div class="intro__logos">
            <a href="https://www.gov.pl/web/kultura" target="_blank" rel="nofollow noopener noreferrer" class="intro__logo intro__logo--mkdnis">
              <img v-if="this.$i18n.locale === 'pl'" alt="Ministerstwo Kultury, Dziedzictwa Narodowego i Sportu" src="@/assets/images/mkdnis-logo.svg" width="99" height="42" />
              <img v-else alt="Ministry of Culture, National Heritage and Sport of the Republic of Poland" src="@/assets/images/mkdnis-logo-en.svg" width="100" height="45" />
              <span class="visually-hidden">{{ $t('default.new-tab-info') }}</span>
            </a>
            <a :href="$t('default.niepodlegla-url')" target="_blank" rel="nofollow noopener noreferrer" class="intro__logo intro__logo--niepodlegla">
              <img alt="Niepodległa logo" src="@/assets/images/niepodlegla-logo.svg" width="130" height="40" />
              <span class="visually-hidden">{{ $t('default.new-tab-info') }}</span>
            </a>
            <a :href="$t('default.iam-url')" target="_blank" rel="nofollow noopener noreferrer" class="intro__logo intro__logo--culture">
              <img v-if="this.$i18n.locale === 'pl'" alt="Instytut Adama Mickiewicza logo" src="@/assets/images/logo-culture-iam.svg" width="100" height="33" />
              <img v-else alt="Adam Mickiewicz Institute logo" src="@/assets/images/logo-culture-iam-en.svg" width="100" height="33" />
              <span class="visually-hidden">{{ $t('default.new-tab-info') }}</span>
            </a>
          </div>
          <div class="intro__footer-controls">
            <LanguageSwitcher mode="light" />
            <ContrastSwitcher mode="light" />
            <div class="intro__footer-break"></div>
            <div class="intro__footer-accessibility-declaration">
              <AccessibilityDeclaration />
            </div>
          </div>
        </div>
      </div>
    </div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
import ContrastSwitcher from '@/components/ContrastSwitcher.vue'
import AccessibilityDeclaration from '@/components/AccessibilityDeclaration.vue'

export default {
  components: {
    LanguageSwitcher,
    ContrastSwitcher,
    AccessibilityDeclaration
  },
  data() {
    return {
      sectionNumber: 0
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title_top": "Sztuka i kultura na szlaku Armii Andersa",
    "title_bottom": "1941-1945"
  },
  "en": {
    "title_top": "Arts & Culture on the Trail of Anders’ Army",
    "title_bottom": "1941-1945"
  },
  "ru": {
    "title_top": "Искусство и культура на боевом пути Армии Андерса 1941-1945",
    "title_bottom": ""
  }
}
</i18n>
