{
  "accessibility-declaration-title": "Accessibility declaration",
  "accessibility-declaration-url": "https://artistsinarms.pl/accessibility-declaration.html",
  "back-to-start": "Return to start",
  "button-contrast-title": "Contrast",
  "button-info-close-title": "Close more info",
  "button-info-open-title": "Open more info",
  "button-navigation-close-title": "Close menu",
  "button-navigation-open-title": "Open menu",
  "goto-article-label": "Read the full story",
  "iam-url": "https://iam.pl/en",
  "new-tab-info": "(opens in a new window))",
  "niepodlegla-url": "https://niepodlegla.gov.pl/en",
  "page-title": "Artists in Arms: Arts & Culture on the Trail of the Anders Army 1941-1945",
  "scroll-down": "Scroll down",
  "video-message": "Your browser does not support the video tag."
}
