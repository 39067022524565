<template>
  <section class="section video" data-chapter="bolonia" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="video__inner">
      <Video file="12-bolonia" a11yid="bolonia-caption" :a11ydescription="$t('a11y_description')" />
      <VideoDescription :caption="$t('caption')" :source="$t('source')" />
      <VideoPreloader />
    </div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import Video from '@/components/partials/Video.vue'
import VideoDescription from '@/components/partials/VideoDescription.vue'
import VideoPreloader from '@/components/partials/VideoPreloader.vue'

export default {
  components: {
    Video,
    VideoDescription,
    VideoPreloader
  },
  data() {
    return {
      sectionNumber: 43
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "caption": "Polscy żołnierze i mieszkańcy na ulicach Bolonii po wyzwoleniu miasta, kwiecień 1945.",
    "source": "Źródło: Instytut Polski i Muzeum im. gen. Sikorskiego w Londynie",
    "a11y_description": "Bolonia, Włochy. Grupa gapiów w średnim wieku uśmiecha się i macha do kamery. Mężczyzna robi zdjęcie operatorowi.  Ulicą tryumfalnie przejeżdżają żołnierze na motorach i w samochodach. Tłum wokół bije brawo."
  },
  "en": {
    "caption": "Polish soldiers & residents in the streets of Bologna after the city’s liberation, April 1945,",
    "source": "video: The Polish Institute and Sikorski Museum in London",
    "a11y_description": "Bologna, Italy. A group of middle-aged onlookers smile and wave at the camera. A man takes a photo of the camera operator. Along the street the soldiers victoriously drive cars and ride motorcycles. The crowd cheers them on."
  },
  "ru": {
    "caption": "Польские солдаты и местные жители на улицах Болоньи после освобождения города, апрель 1945 года,",
    "source": "видео: Польским институтом и музеем имени генерала Сикорского в Лондоне",
    "a11y_description": "Болонья, Италия. Группа зрителей среднего возраста улыбается и машет в камеру. Мужчина делает фото для оператора. Солдаты на мотоциклах и в автомобилях с триумфом проезжают по улице. Толпа вокруг аплодирует. "
  },
}
</i18n>
