<template>
  <button ref="NavigationOpenBtn" @click="openMenu" class="navigation__btn navigation__btn--open">
    <span class="visually-hidden">{{ $t('default.button-navigation-open-title') }}</span>
  </button>
  <div ref="Navigation" class="navigation" id="navigation">
    <nav>
      <ul class="navigation__items">
        <li @click="handleClick(0)" class="navigation__item">
          <a href="#wstep" class="navigation__link navigation__link--intro navigation__link--wstep">
            <span class="visually-hidden">{{ $t('introduction') }}</span>
          </a>
        </li>
      </ul>
      <ul class="navigation__items">
        <li @click="handleClick(4)" class="navigation__item">
          <a href="#griazowiec" class="navigation__link navigation__link--griazowiec">
            <span>01</span> {{ $t('griazowiec') }}
          </a>
        </li>
        <li @click="handleClick(7)" class="navigation__item">
          <a href="#buzuluk" class="navigation__link navigation__link--buzuluk">
            <span>02</span> {{ $t('buzuluk') }}
          </a>
        </li>
        <li @click="handleClick(10)" class="navigation__item">
          <a href="#taszkient" class="navigation__link navigation__link--taszkient">
            <span>03</span> {{ $t('taszkient') }}
          </a>
        </li>
        <li @click="handleClick(13)" class="navigation__item">
          <a href="#krasnowodzk" class="navigation__link navigation__link--krasnowodzk">
            <span>04</span> {{ $t('krasnowodzk') }}
          </a>
        </li>
        <li @click="handleClick(17)" class="navigation__item">
          <a href="#teheran" class="navigation__link navigation__link--teheran">
            <span>05</span> {{ $t('teheran') }}
          </a>
        </li>
        <li @click="handleClick(20)" class="navigation__item">
          <a href="#isfahan" class="navigation__link navigation__link--isfahan">
            <span>06</span> {{ $t('isfahan') }}
          </a>
        </li>
        <li @click="handleClick(24)" class="navigation__item">
          <a href="#bagdad" class="navigation__link navigation__link--bagdad">
            <span>07</span> {{ $t('bagdad') }}
          </a>
        </li>
        <li @click="handleClick(27)" class="navigation__item">
          <a href="#beirut" class="navigation__link navigation__link--beirut">
            <span>08</span> {{ $t('beirut') }}
          </a>
        </li>
        <li @click="handleClick(31)" class="navigation__item">
          <a href="#jerozolima" class="navigation__link navigation__link--jerozolima">
            <span>09</span> {{ $t('jerozolima') }}
          </a>
        </li>
        <li @click="handleClick(34)" class="navigation__item">
          <a href="#kair" class="navigation__link navigation__link--kair">
            <span>10</span> {{ $t('kair') }}
          </a>
        </li>
        <li @click="handleClick(37)" class="navigation__item">
          <a href="#monte-cassino" class="navigation__link navigation__link--monte-cassino">
            <span>11</span> {{ $t('monte-cassino') }}
          </a>
        </li>
        <li @click="handleClick(41)" class="navigation__item">
          <a href="#bolonia" class="navigation__link navigation__link--bolonia">
            <span>12</span> {{ $t('bolonia') }}
          </a>
        </li>
        <li @click="handleClick(44)" class="navigation__item">
          <a href="#paryz-londyn-rzym" class="navigation__link navigation__link--paryz-londyn-rzym">
            <span>13</span> {{ $t('paryz-londyn-rzym') }}
          </a>
        </li>
      </ul>
      <ul class="navigation__items">
        <li @click="handleClick(47)" class="navigation__item">
          <a href="#biografie" class="navigation__link navigation__link--epilog navigation__link--biografie">
            {{ $t('biographies') }}
          </a>
        </li>
        <li @click="handleClick(49)" class="navigation__item">
          <a href="#szlak-armii-andersa" class="navigation__link navigation__link--szlak-armii-andersa">
            {{ $t('anders-army-trail') }}
          </a>
        </li>
        <li @click="handleClick(50)" class="navigation__item">
          <a href="#o-projekcie" class="navigation__link navigation__link--o-projekcie">
            {{ $t('about-project') }}
          </a>
        </li>
      </ul>
    </nav>
    <div class="navigation__footer">
      <div class="navigation__footer-inner">
        <div class="navigation__logos">
          <a href="https://www.gov.pl/web/kultura" target="_blank" rel="nofollow noopener noreferrer" class="navigation__logo navigation__logo--mkdnis">
            <img v-if="this.$i18n.locale === 'pl'" alt="Ministerstwo Kultury, Dziedzictwa Narodowego i Sportu" src="@/assets/images/mkdnis-logo-light.svg" width="99" height="42" />
            <img v-else alt="Ministry of Culture, National Heritage and Sport of the Republic of Poland" src="@/assets/images/mkdnis-logo-en-light.svg" width="100" height="45" />
            <span class="visually-hidden">{{ $t('default.new-tab-info') }}</span>
          </a>
          <a :href="$t('default.niepodlegla-url')" target="_blank" rel="nofollow noopener noreferrer" class="navigation__logo navigation__logo--niepodlegla">
            <img alt="Niepodległa logo" src="@/assets/images/niepodlegla-logo-light.svg" width="130" height="40" />
            <span class="visually-hidden">{{ $t('default.new-tab-info') }}</span>
          </a>
          <a :href="$t('default.iam-url')" target="_blank" rel="nofollow noopener noreferrer" class="navigation__logo navigation__logo--culture">
            <img v-if="this.$i18n.locale === 'pl'" alt="Instytut Adama Mickiewicza logo" src="@/assets/images/logo-culture-iam-light.svg" width="100" height="33" />
            <img v-else alt="Adam Mickiewicz Institute logo" src="@/assets/images/logo-culture-iam-en-light.svg" width="100" height="33" />
            <span class="visually-hidden">{{ $t('default.new-tab-info') }}</span>
          </a>
        </div>
        <div class="navigation__footer-controls">
          <LanguageSwitcher mode="dark" />
          <ContrastSwitcher mode="dark" />
          <div class="navigation__footer-break"></div>
          <div class="navigation__footer-accessibility-declaration">
            <AccessibilityDeclaration />
          </div>
        </div>
      </div>
    </div>
    <button ref="NavigationCloseBtn" @click="closeMenu" class="navigation__btn navigation__btn--close">
      <span class="visually-hidden">{{ $t('default.button-navigation-close-title') }}</span>
    </button>
  </div>
</template>

<script>
import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
import ContrastSwitcher from '@/components/ContrastSwitcher.vue'
import AccessibilityDeclaration from '@/components/AccessibilityDeclaration.vue'

export default {
  name: 'Navigation',
  components: {
    LanguageSwitcher,
    ContrastSwitcher,
    AccessibilityDeclaration
  },
  data() {
    return {
      isNavigationOpen: false,
      firstFocusableElement: '',
      lastFocusableElement: ''
    }
  },
  methods: {
    openMenu() {
      this.isNavigationOpen = true
      document.body.classList.add('navigation-opened')
      this.$refs.Navigation.classList.add('navigation--open')
      document.addEventListener('keydown', this.documentKeyDown)
    },
    closeMenu() {
      this.isNavigationOpen = false
      document.body.classList.remove('navigation-opened')
      this.$refs.Navigation.classList.remove('navigation--open')
      document.removeEventListener('keydown', this.documentKeyDown)
    },
    closeMenuOnEsc(e) {
      e = e || window.event
      if (e.key === 'Escape' || e.keyCode === 27) {
        this.closeMenu();
      }
    },
    documentClick(e) {
      let element = this.$refs.NavigationOpenBtn
      let target = null
      if (e && e.target) {
        target = e.target
      }
      if (
        element &&
        element !== target &&
        !element.contains(target) &&
        !this.hasClass(target, 'navigation') &&
        this.isNavigationOpen
      ) {
        this.closeMenu()
      }
    },
    handleClick(sectionNumber) {
      if (this.$screen.width >= 1024) {
        this.$parent.handleClick(sectionNumber)
      }
      this.closeMenu()
    },
    hasClass(element, className) {
      do {
        if (element.classList && element.classList.contains(className)) {
          return true;
        }
        element = element.parentNode
      } while (element)

      return false
    },
    documentKeyDown(e) {
      let isTabPressed = e.key === 'Tab' || e.keyCode === 9
      if (!isTabPressed) {
        return
      }

      if (e.shiftKey) {
        if (document.activeElement === this.firstFocusableElement) {
          this.lastFocusableElement.focus()
          e.preventDefault()
        }
      } else {
        if (document.activeElement === this.lastFocusableElement) {
          this.firstFocusableElement.focus()
          e.preventDefault()
        }
      }
    }
  },
  mounted() {
    document.addEventListener('keyup', this.closeMenuOnEsc)
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    this.firstFocusableElement = this.$refs.Navigation.querySelectorAll(focusableElements)[0]
    this.lastFocusableElement = this.$refs.NavigationCloseBtn
  },
  created: function() {
    document.addEventListener('click', this.documentClick)
  }
}
</script>

<i18n>
{
  "pl": {
    "introduction": "Wstęp",
    "griazowiec": "Griazowiec",
    "buzuluk": "Buzułuk",
    "taszkient": "Taszkent",
    "krasnowodzk": "Krasnowodzk",
    "teheran": "Teheran",
    "isfahan": "Isfahan",
    "bagdad": "Bagdad",
    "beirut": "Beirut",
    "jerozolima": "Jerozolima",
    "kair": "Kair",
    "monte-cassino": "Monte Cassino",
    "bolonia": "Bolonia",
    "paryz-londyn-rzym": "Paryż, Londyn, Rzym",
    "biographies": "Biografie Artystów Andersa",
    "anders-army-trail": "Szlak Armii Andersa",
    "about-project": "O projekcie"
  },
  "en": {
    "introduction": "Introduction",
    "griazowiec": "Gryazovets",
    "buzuluk": "Buzuluk",
    "taszkient": "Tashkent",
    "krasnowodzk": "Krasnovodsk",
    "teheran": "Tehran",
    "isfahan": "Isfahan",
    "bagdad": "Baghdad",
    "beirut": "Beirut",
    "jerozolima": "Jerusalem",
    "kair": "Cairo",
    "monte-cassino": "Monte Cassino",
    "bolonia": "Bologna",
    "paryz-londyn-rzym": "Paris, London, Rome",
    "biographies": "Biographies of the Anders’ artists",
    "anders-army-trail": "The Trail of Anders’ Army",
    "about-project": "About the project"
  },
  "ru": {
    "introduction": "Вступление",
    "griazowiec": "Грязовец",
    "buzuluk": "Бузулук",
    "taszkient": "Ташкент",
    "krasnowodzk": "Красноводск",
    "teheran": "Тегеран",
    "isfahan": "Исфахан",
    "bagdad": "Багдад",
    "beirut": "Бейрут",
    "jerozolima": "Иерусалим",
    "kair": "Каир",
    "monte-cassino": "Монте-Кассино",
    "bolonia": "Болонья",
    "paryz-londyn-rzym": "Париж, Лондон, Рим",
    "biographies": "Артисты в Армии Андерса",
    "anders-army-trail": "Путь Армии Андерса",
    "about-project": "О проекте"
  }
}
</i18n>
