<template>
  <section ref="Section" class="section cover" id="monte-cassino" data-chapter="monte-cassino" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description') }}</p>
      </div>
      <div class="cover__number" aria-hidden="true">11</div>
      <div class="cover__title" aria-hidden="true">{{ $t('title') }}</div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 37
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Monte Cassino",
    "description": "W grudniu 1943 Armia wypłynęła z egipskiego Port Said do włoskiego miasta Tarent. W Italii żołnierze biorą wreszcie udział w walkach – po raz pierwszy w styczniu 1944 nad rzeką Sangro. W maju 1944 powierzone im zostaje zadanie zdobycia klasztoru na Monte Cassino, centralnego punktu w Linii Gustawa – jego zdobycie miało otworzyć aliantom drogę do Rzymu.",
    "screen_reader_title": "Rozdział 11 Monte Cassino"
  },
  "en": {
    "title": "Monte Cassino",
    "description": "In December 1943, the army sailed from Egypt’s Port Said to the Italian town of Taranto. In Italy, the soldiers finally took part in the fighting – the first time being in January 1944, on the River Sangro. In May 1944, they were tasked with capturing the monastery at Monte Cassino, a central point on the Gustav Line. Its conquest was to open the Allies’ path to Rome.",
    "screen_reader_title": "Chapter 11 Monte Cassino"
  },
  "ru": {
    "title": "Монте-Кассино",
    "description": "В декабре 1943 года армия отправилась из египетского Порт-Саида в итальянский город Таранто. В Италии солдаты участвуют в сражениях, первая битва — в январе 1944 года на реке Сангро. В мае 1944 года они получают задание захватить монастырь на Монте-Кассино, ключевой участок на «линии Густава» — его захват должен был открыть союзникам дорогу к Риму.",
    "screen_reader_title": "Глава 11 Монте-Кассино"
  }
}
</i18n>
