<template>
  <div class="video__description">
    <div class="video__description-background" :class="isBackgroundVisible">
      <button @click="isInfoOpen = !isInfoOpen" class="video__description-btn video__description-btn" :class="isOpenOrClose" :aria-expanded="isInfoOpen">
        <span v-if="isInfoOpen" class="visually-hidden">{{ $t('default.button-info-open-title') }}</span>
        <span v-if="!isInfoOpen" class="visually-hidden">{{ $t('default.button-info-close-title') }}</span>
      </button>
      <div v-if="isInfoOpen" class="video__description-text">
        <span>{{ caption }}</span>
        <span>{{ source }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoDescription',
  props: ['caption', 'source'],
  data () {
    return {

      isInfoOpen: false
    }
  },
  computed: {
    isOpenOrClose() {
      if (this.isInfoOpen === true) {
        return 'video__description-btn--close'
      }

      return 'video__description-btn--open'
    },
    isBackgroundVisible() {
      if (this.isInfoOpen === true) {
        return 'video__description-background--visible'
      }

      return ''
    }
  }
}
</script>
