<template>
  <section ref="Section" class="section cover" id="griazowiec" data-chapter="griazowiec" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description') }}</p>
      </div>
      <div class="cover__number" aria-hidden="true">01</div>
      <div class="cover__title" aria-hidden="true">{{ $t('title') }}</div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 4
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Griazowiec",
    "description": "We wrześniu 1939 roku terytorium Rzeczypospolitej zostało podzielone między III Rzeszę a ZSRR. Setki tysięcy polskich obywateli zostało deportowanych w głąb Rosji lub aresztowanych. Wśród nich znajdowało się też kilkanaście tysięcy polskich jeńców wojennych, po których ślad zaginął.",
    "screen_reader_title": "Rozdział 1 Griazowiec"
  },
  "en": {
    "title": "Gryazovets",
    "description": "In September 1939, the territory of the Polish Republic was divided between the Third Reich and the USSR. Hundreds of thousands of Polish citizens were deported to Russia or arrested. Amongst them were several thousand Polish prisoners of war – who then vanished without a trace.",
    "screen_reader_title": "Chapter 1 Gryazovets"
  },
  "ru": {
    "title": "Грязовец",
    "description": "В сентябре 1939 года территория Польской Республики была разделена между Германией и СССР. Сотни тысяч польских граждан были депортированы вглубь России или арестованы. В их числе — более десяти тысяч польских военнопленных, о судьбе которых ничего не известно.",
    "screen_reader_title": "Глава 1 Грязовец"
  }
}
</i18n>
