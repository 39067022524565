<template>
  <section class="section quote krasnowodzk-quote" data-chapter="krasnowodzk" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="quote__inner quote__inner--without-author-picture">
      <template v-if="$screen.width < 1024">
        <QuoteImageMobile fileSm="04-krasnowodzk-quote-sm.jpg" fileMd="04-krasnowodzk-quote-md.jpg" :description="$t('image_description')" :alt="$t('author_picture_alt')"/>
      </template>
      <figure>
        <blockquote>
          <p><span>{{ $t('quote') }}</span></p>
        </blockquote>
        <figcaption>
          <span class="quote__author-name"><span>{{ $t('author_name') }}</span></span>
          <span class="quote__author-title"><span>{{ $t('author_title') }}</span></span>
        </figcaption>
      </figure>
      <template v-if="$screen.width >= 1024">
        <div class="quote__background-image krasnowodzk-quote__background-image lazy-background" role="img" :aria-label="$t('author_picture_alt')"></div>
        <div class="quote__background-image-description">
          <p><span>{{ $t('image_description') }}</span></p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import QuoteImageMobile from '@/components/partials/QuoteImageMobile.vue'

export default {
  components: {
    QuoteImageMobile
  },
  data() {
    return {
      sectionNumber: 16
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "quote": "Od chwili gdy stopy nasze dotknęły ziemi perskiej, poczuliśmy się jak ludzie, którzy z ciemności nocnych nagle w jasną słoneczność się wydostali. Znikło, jak różdżką czarodziejską tknięte, koszmarne piekło, a rozwarło się nad nami jasne, ciepłe i gościnne niebo.",
    "author_name": "Klemens Rudnicki,",
    "author_title": "generał Wojska Polskiego",
    "image_description": "Fot: Instytut Polski i Muzeum im. gen. Sikorskiego (IPMS)",
    "author_picture_alt": "Czarno-biała fotografia obozu Wojska Polskiego na Bliskim Wschodzie. Po prawej namioty i kilku żołnierzy stojących obok. Z lewej, bliżej żołnierz stojący tyłem, z twarzą zwróconą w stronę namiotów. Stoi pod prowizorycznym zadaszeniem ze słomy w kształcie parasola. Pośrodku białe litery w kilku rzędach. Cytat wypowiedzi Klemensa Rudnickiego, generała Wojska Polskiego.",
  },
  "en": {
    "quote": "From the moment our feet touched Persian ground, we felt like people who had emerged, suddenly, from the darkness of the night into bright sunlight. It was gone, that nightmarish hell, as if touched by a magic wand – and a bright, warm and hospitable sky had opened up above us.",
    "author_name": "Klemens Rudnicki,",
    "author_title": "Polish Army general",
    "image_description": "Photo: The Polish Institute and Sikorski Museum",
    "author_picture_alt": "A black-and-white photo of the Polish Army camp in the Middle East. On the right – tents and a few soldiers standing next to them. On the left a soldier standing backwards, in a closer perspective, his face directed to the tents. He stands under a provisional roofing made of straw, in the shape of an umbrella. In the centre – white letters in a few rows. A quoted statement of Klemens Rudnicki, the Polish Army general.",
  },
  "ru": {
    "quote": "В ту минуту, когда мы ступили на персидскую землю, мы почувствовали себя как люди, которые из ночной тьмы попали на яркое солнце. Как по мановению волшебной палочки отступил кромешный ад, и над нами простерлось безоблачное, теплое и гостеприимное небо.",
    "author_name": "Клеменс Рудницкий,",
    "author_title": "генерал Польской армии",
    "image_description": "Fot: Польский институт и музей Сикорского (IPMS)",
    "author_picture_alt": "Черно-белая фотография лагеря польской армии на Ближнем Востоке. Справа — палатки и несколько солдат, стоящих рядом. Слева — солдат, стоящий спиной к лагерю, лицо повернуто в сторону палаток. Он стоит под импровизированным навесом из соломы в форме зонтика. В центре белые буквы в несколько рядов. Цитата из обращения  Клеменса Рудницкого, генерала польской армии.",
  }
}
</i18n>
