<template>
  <section class="section article" data-chapter="intro" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="collage intro-collage" aria-describedby="intro-collage-description">
      <div class="collage__inner">
        <div class="collage__merged" v-if="$screen.width < 1024">
          <ArticleCollageMerged file="00-intro" />
        </div>
        <kinesis-container v-if="$screen.width >= 1024">
          <div class="collage__height">
            <kinesis-element :strength="10"></kinesis-element>
          </div>
          <div class="collage__absolute intro-collage__one">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/00-intro-drogowskaz.png">
                <img data-src="@/assets/images/00-intro-drogowskaz.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute intro-collage__two">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/00-intro-czapski.png">
                <img data-src="@/assets/images/00-intro-czapski.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute intro-collage__three">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/00-intro-akwarela.png">
                <img data-src="@/assets/images/00-intro-akwarela.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute intro-collage__four">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/00-intro-swiatlo.png">
                <img data-src="@/assets/images/00-intro-swiatlo.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute intro-collage__five">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/00-intro-naszywka.png">
                <img data-src="@/assets/images/00-intro-naszywka.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute intro-collage__six">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/00-intro-zolnierz.png">
                <img data-src="@/assets/images/00-intro-zolnierz.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute intro-collage__seven">
            <kinesis-element :strength="30" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/00-intro-kobieta-z-medalem.png">
                <img data-src="@/assets/images/00-intro-kobieta-z-medalem.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute intro-collage__eight">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/00-intro-niedzwiedz.png">
                <img data-src="@/assets/images/00-intro-niedzwiedz.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
        </kinesis-container>
      </div>
    </div>
    <div id="intro-collage-description" class="visually-hidden">{{ $t('collage_description') }}</div>
  </section>
</template>

<script>
import ArticleCollageMerged from '@/components/partials/ArticleCollageMerged.vue'

export default {
  components: {
    ArticleCollageMerged
  },
  data() {
    return {
      sectionNumber: 1
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "collage_description": "Grafika stworzona techniką kolażu. Po lewej Major Józef Czapski. Na pierwszym planie jeep’y Wojska Polskiego oświetlające drogę. Pośrodku - niedźwiedź Wojtek. Obok niego biało-czerwona – naszywka na mundur – czerwony owal z białym napisem „Poland”. Po prawej - Jadwiga Domańska w przedstawieniu „Turandot” wręcza niedźwiedziowi Krzyż Czynu Bojowego PSZ na Zachodzie. Centralnie w tle – drewniany drogowskaz Warszawa – Teheran."
  },
  "en": {
    "collage_description": "Artwork created using the collage technique. On the left – Mjr. Józef Czapski. Above him are Polish Army jeeps, their headlights illuminating the road. In the centre – Wojtek the Bear. Next to him – a sew-on-badge reading ‘POLAND’. On the right – Jadwiga Domańska in the performance ‘Turandot’ handing a Polish Armed Forces in the West Military Action Cross to the bear. In the centre –  a wooden road sign at the crossroads reading ‘Warsaw-Tehran’."
  },
  "ru": {
    "collage_description": "Графика, выполненная в технике коллажа. Слева — майор Юзеф Чапский. На первом плане — джипы польской армии, освещающие путь. В центре — медведь Войтек. Рядом, Внизу слева — нашивка для униформы  — белая надпись «Poland» в красном овале. Справа — Ядвига Доманская в костюме из спектакля «Турандот» вручает медведю Крест боевых действий польских вооруженных сил на Западе.  На заднем плане — деревянный указатель Варшава-Тегеран."
  }
}
</i18n>
