<template>
  <section class="section quote taszkient-quote" data-chapter="taszkient" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="quote__inner">
      <template v-if="$screen.width < 1024">
        <QuoteImageMobile fileSm="03-taszkient-quote-sm.jpg" fileMd="03-taszkient-quote-md.jpg" :description="$t('image_description')" :alt="$t('author_picture_alt')"/>
        <QuoteAuthorPictureMobile file="03-taszkient-quote-author-picture.png" :alt="$t('author_picture_alt')" />
      </template>
      <template v-if="$screen.width >= 1024">
        <div class="quote__author-picture">
          <div class="quote__author-picture-inner">
            <picture class="lazy">
              <source media="(min-width:1921px)" data-srcset="@/assets/images/03-taszkient-quote-author-picture.png">
              <img data-src="@/assets/images/03-taszkient-quote-author-picture.png" :alt="$t('author_picture_alt')" class="taszkient-quote__author-image" />
            </picture>
          </div>
        </div>
      </template>
      <figure>
        <blockquote>
          <p><span>{{ $t('quote') }}</span></p>
        </blockquote>
        <figcaption>
          <span class="quote__author-name"><span>{{ $t('author_name') }}</span></span>
          <span class="quote__author-title"><span>{{ $t('author_title') }}</span></span>
        </figcaption>
      </figure>
      <template v-if="$screen.width >= 1024">
        <div class="quote__background-image taszkient-quote__background-image lazy-background"></div>
        <div class="quote__background-image-description">
          <p><span>{{ $t('image_description') }}</span></p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import QuoteImageMobile from '@/components/partials/QuoteImageMobile.vue'
import QuoteAuthorPictureMobile from '@/components/partials/QuoteAuthorPictureMobile.vue'

export default {
  components: {
    QuoteImageMobile,
    QuoteAuthorPictureMobile
  },
  data() {
    return {
      sectionNumber: 12
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "quote": "Tyfus zabierał spośród nich [polskich lekarzy] ofiary, ale to nie było ważne. Ważnym było dla nich to, aby w zgaszonych oczach ludzkich wzniecić iskierkę nadziei, że \"nie zginęła, póki my żyjemy\", a więc trzeba dla Niej żyć, żyć, by miał kto o Nią walczyć.",
    "author_name": "Hanka Ordonówna,",
    "author_title": "piosenkarka, aktorka",
    "author_picture_alt": "Czarno-biała fotografia. W tle fragment zdjęcia – uśmiechnięte twarze polskich dzieci w ZSRR. Ich wzrok skierowany w obiektyw aparatu fotograficznego. W lewym dolnym rogu fragment białego koła. Na nim portret en face Hanki Ordonówny, z delikatnym uśmiechem. Pośrodku białe litery w kilku rzędach. Cytat wypowiedzi Hanki Ordonówny.",
    "image_description": "Fot. Instytut Polski i Muzeum im. gen. Sikorskiego (IPMS) / www.audiovis.nac.gov.pl (NAC)"
  },
  "en": {
    "quote": "Typhus took some of them [Polish doctors] as victims, but that wasn’t important. What was important to them was to kindle a spark of hope in the downcast eyes of the people, that ‘Poland has not yet perished, as long as we still live’. And so for Her, for Poland, they had to live – to live, so that there would still be someone to fight for Her.",
    "author_name": "Hanka Ordonówna,",
    "author_title": "singer & actress",
    "author_picture_alt": "A black and white photograph. In the background a fragment of a photo – smiley faces of the Polish children in USSR. Their gazes directed to the camera lens. In the bottom left corner – a piece of a white circle; on it – an en-face portrait of Hanka Ordonówna, with a subtle smile on her face. In the centre white letters in a few rows. A quote from her statement.",
    "image_description": "Photo: The Polish Institute and Sikorski Museum / www.audiovis.nac.gov.pl (NAC)"
  },
  "ru": {
    "quote": "Тиф уносил жизни [польских врачей], но это было не так важно. Для них важнее всего было зажечь искру надежды в потухших глазах людей, надежду на то, что «еще Польша не погибла, пока мы живем», а значит, нужно жить ради нее, жить, чтобы было, кому за нее бороться.",
    "author_name": "Ганка Ордонувна,",
    "author_title": "певица, актриса",
    "author_picture_alt": "Черно-белая фотография. На заднем плане фрагмент фотографии — улыбающиеся лица польских детей в СССР. Их глаза направлены на объектив камеры. В левом нижнем углу — фрагмент белого круга. В круге — портрет  Ганки Ордонувны, она ласково улыбается. В центре белые буквы в несколько рядов. Цитата из обращения Га́нки Ордонувны.",
    "image_description": "Фото: Польский институт и музей Сикорского (IPMS) / www.audiovis.nac.gov.pl (NAC)"
  }
}
</i18n>
