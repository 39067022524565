<template>
  <section ref="Section" class="section cover" id="bagdad" data-chapter="bagdad" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description') }}</p>
      </div>
      <div class="cover__number" aria-hidden="true">07</div>
      <div class="cover__title" aria-hidden="true">{{ $t('title') }}</div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 24
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Bagdad",
    "description": "W Iraku Armia Andersa połączyła się z siłami Samodzielnej Dywizji Strzelców Karpackich, która wcześniej brała udział w działaniach wojennych w Afryce Północnej. Dowódcą nowo utworzonej Armii Polskiej na Wschodzie został gen. Anders. Pod okiem brytyjskich instruktorów polskie oddziały przechodzą intensywne szkolenia w rejonie Mosulu i Kirkuku.",
    "screen_reader_title": "Rozdział 7 Bagdad"
  },
  "en": {
    "title": "Baghdad",
    "description": "In Iraq, Anders’ Army joined forces with the Independent Carpathian Rifle Division, which had previously participated in military operations in Northern Africa. General Anders became commander of the newly formed Polish Army in the East. Under the guidance of British instructors, the Polish units trained intensively in the regions around Mosul and Kirkuk.",
    "screen_reader_title": "Chapter 7 Baghdad"
  },
  "ru": {
    "title": "Багдад",
    "description": "В Ираке Армия Андерса объединилась с Отдельной карпатской стрелковой бригадой, которая прежде участвовала в военных действиях в Северной Африке. Командиром только что сформированной Польской армии на Востоке стал генерал Андерс. Под наблюдением британских инструкторов польские отряды проходят интенсивное обучение в окрестностях Мосула и Киркука.",
    "screen_reader_title": "Глава 7 Багдад"
  }
}
</i18n>
