<template>
  <section class="section video" data-chapter="bagdad" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="video__inner">
      <Video file="07-bagdad" a11yid="bagdad-caption" :a11ydescription="$t('a11y_description')" />
      <VideoDescription :caption="$t('caption')" :source="$t('source')" />
      <VideoPreloader />
    </div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import Video from '@/components/partials/Video.vue'
import VideoDescription from '@/components/partials/VideoDescription.vue'
import VideoPreloader from '@/components/partials/VideoPreloader.vue'

export default {
  components: {
    Video,
    VideoDescription,
    VideoPreloader
  },
  data() {
    return {
      sectionNumber: 26
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "caption": "Ćwiczenia wojskowe polskich jednostek w Iraku.",
    "source": "Źródło: Instytut Polski i Muzeum im. gen. Sikorskiego w Londynie",
    "a11y_description": "Kilkunastu żołnierzy kolejno skacze przez wąwóz. Wzbudzają tumany kurzu. Ostatniemu kompan pomaga wyjść z wąwozu. Wzburzony nurt rzeki. Wzdłuż jej brzegu drogą biegnie trzech żołnierzy. Dwóch żołnierzy w kamuflażu podnosi się z łąki. Odbiegają. Żołnierz wbiega na betonowy mostek nad nurtem rzeki."
  },
  "en": {
    "caption": "Exercises by Polish Army units in Iraq,",
    "source": "video: The Polish Institute and Sikorski Museum in London",
    "a11y_description": "A dozen soldiers jump over a ravine one after the other. They raise loads of dust. The last one is assisted by his companion while climbing out of it. A raging river. Alongside its bank – three soldiers running. Two soldiers in camouflage stand up from a meadow. They run away. A soldier runs onto a concrete bridge over the river’s current."
  },
  "ru": {
    "caption": "Военные учения польских подразделений в Ираке,",
    "source": "видео: Польским институтом и музеем имени генерала Сикорского в Лондоне",
    "a11y_description": "Несколько  солдат один за другим перепрыгивают через овраг. Они поднимают тучи пыли. Последнему помогает выбраться из оврага его товарищ. Река очень бурная. Три солдата бегут по берегу реки. Два солдата в камуфляже поднимаются с поляны. Они убегают. Солдат забегает на бетонный мост над рекой."
  }
}
</i18n>
