<template>
  <section class="section video" data-chapter="kair" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="video__inner">
      <Video file="10-kair" a11yid="kair-caption" :a11ydescription="$t('a11y_description')" />
      <VideoDescription :caption="$t('caption')" :source="$t('source')" />
      <VideoPreloader />
    </div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import Video from '@/components/partials/Video.vue'
import VideoDescription from '@/components/partials/VideoDescription.vue'
import VideoPreloader from '@/components/partials/VideoPreloader.vue'

export default {
  components: {
    Video,
    VideoDescription,
    VideoPreloader
  },
  data() {
    return {
      sectionNumber: 36
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "caption": "Występy artystyczne w Egipcie.",
    "source": "Źródło: Instytut Polski i Muzeum im. gen. Sikorskiego w Londynie",
    "a11y_description": "Dwóch żołnierzy radośnie gra na akordeonach. Za nimi, na masce samochodu terenowego wesoło tańczy kobieta. Wykonuje dwa obroty, zastyga - jej twarz oświetlona odbiciem światła słonecznego.  Zapada zmrok. Ciemnowłosa kobieta, z oświetloną twarzą siedzi na tle antycznego posągu Sfinksa. Dwóch żołnierzy słucha jej z zainteresowaniem."
  },
  "en": {
    "caption": "Artistic performances in Egypt,",
    "source": "video: The Polish Institute and Sikorski Museum in London",
    "a11y_description": "Two soldiers joyously play their accordions. Behind them, on a car bonnet – a woman dances joyfully. She turns twice, freezes – her face illuminated by the reflection of sunlight. Twilight. A dark-haired woman with light cast across her face sits in front of the ancient statue of Sphinx. Two soldiers listen to her with interest."
  },
  "ru": {
    "caption": "Выступления артистов в Египте,",
    "source": "видео: Польским институтом и музеем имени генерала Сикорского в Лондоне",
    "a11y_description": "Два солдата задорно играют на аккордеонах. Позади женщина лихо отплясывает на капоте внедорожника. Она делает два оборота, замирает — ее лицо освещено отражением солнечного света.  Опускаются сумерки. Темноволосая женщина с освещенным лицом сидит на фоне древней статуи Сфинкса. Двое солдат с интересом слушают ее."
  }
}
</i18n>
