<template>
  <section ref="Section" class="section cover" id="bolonia" data-chapter="bolonia" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description') }}</p>
      </div>
      <div class="cover__number" aria-hidden="true">12</div>
      <div class="cover__title" aria-hidden="true">{{ $t('title') }}</div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 41
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Bolonia",
    "description": "Polacy posuwają się wzdłuż Adriatyku, wyzwalając Loreto i Ankonę. W październiku znowu trafiają na front. Zwieńczeniem bojowego szlaku staje się udział w zaciętej bitwie o Bolonię w kwietniu 1945 roku.",
    "screen_reader_title": "Rozdział 12 Bolonia"
  },
  "en": {
    "title": "Bologna",
    "description": "The Poles moved along the Adriatic Sea, liberating Loreto and Ancona. By October, they were on the front again. The combat trail culminated in the army fighting in the fierce Battle of Bologna in 1945.",
    "screen_reader_title": "Chapter 12 Bologna"
  },
  "ru": {
    "title": "Болонья",
    "description": "Поляки продвигаются вдоль Адриатики, освобождая Лорето и Анкону. В октябре они снова попадают на фронт. Кульминацией боевого пути становится участие в ожесточенной битве за Болонью в апреле 1945 года.",
    "screen_reader_title": "Глава  12 Болонья"
  }
}
</i18n>
