<template>
  <section ref="Section" class="section cover" id="taszkient" data-chapter="taszkient" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description') }}</p>
      </div>
      <div class="cover__number" aria-hidden="true">03</div>
      <div class="cover__title" aria-hidden="true">{{ $t('title') }}</div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 10
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Taszkent",
    "description": "Pod koniec 1941 roku pełniący obowiązki premiera Rzeczypospolitej na uchodźstwie generał Władysław Sikorski uzyskał u Stalina zgodę na powiększenie armii do 96 tysięcy żołnierzy. Na początku 1942 podjęto decyzję o przeniesieniu polskich oddziałów na południe: do Uzbekistanu, Kirgizji i południowego Kazachstanu. Na miejscu Polacy muszą zmierzyć się z trudnymi warunkami klimatycznymi i dziesiątkującymi oddziały chorobami zakaźnymi. Trwa epidemia tyfusu.",
    "screen_reader_title": "Rozdział 3 Taszkient"
  },
  "en": {
    "title": "Tashkent",
    "description": "At the end of 1941, General Władysław Sikorski, the Acting Prime Minister of the Polish Government in Exile, obtained Stalin’s approval to increase the army’s size to 96,000 soldiers. At the beginning of 1942, a decision was made to move the Polish troops to the south: to Uzbekistan, Kyrgyzstan and southern Kazakhstan. There, the soldiers faced a difficult climate, and infectious diseases decimated entire units. An ongoing epidemic of typhus raged through their numbers.",
    "screen_reader_title": "Chapter 3 Tashkent"
  },
  "ru": {
    "title": "Ташкент",
    "description": "В конце 1941 года исполняющий обязанности премьер-министра Польской Республики в изгнании генерал Владислав Сикорский получил согласие Сталина на увеличение армии до 96 тысяч солдат. В начале 1942 года было принято решение о переброске польских отрядов на юг: в Узбекистан, Киргизию и южный Казахстан. Прибывшие на место поляки сталкиваются с тяжелыми климатическими условиями и инфекционными заболеваниями, приводящими войска в упадок. Продолжается эпидемия сыпного тифа.",
    "screen_reader_title": "Глава 3 Ташкент"
  }
}
</i18n>
