<template>
  <section class="section article" data-chapter="monte-cassino" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="collage monte-cassino-collage" aria-describedby="monte-cassino-collage-description">
      <div class="collage__inner">
        <div class="collage__merged" v-if="$screen.width < 1024">
          <ArticleCollageMerged file="11-monte-cassino" />
        </div>
        <template v-if="$screen.width < 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
        </template>
        <kinesis-container v-if="$screen.width >= 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
          <div class="collage__height">
            <kinesis-element :strength="10"></kinesis-element>
          </div>
          <div class="collage__absolute monte-cassino-collage__one">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/11-monte-cassino-flagi.png">
                <img data-src="@/assets/images/11-monte-cassino-flagi.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute monte-cassino-collage__two">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/11-monte-cassino-melchior.png">
                <img data-src="@/assets/images/11-monte-cassino-melchior.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute monte-cassino-collage__three">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/11-monte-cassino-klasztor.png">
                <img data-src="@/assets/images/11-monte-cassino-klasztor.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute monte-cassino-collage__four">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/11-monte-cassino-bg.png">
                <img data-src="@/assets/images/11-monte-cassino-bg.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute monte-cassino-collage__five">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/11-monte-cassino-maki.png">
                <img data-src="@/assets/images/11-monte-cassino-maki.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute monte-cassino-collage__six">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/11-monte-cassino-reka.png">
                <img data-src="@/assets/images/11-monte-cassino-reka.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute monte-cassino-collage__seven">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/11-monte-cassino-mak.png">
                <img data-src="@/assets/images/11-monte-cassino-mak.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute monte-cassino-collage__eight">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/11-monte-cassino-jeep.png">
                <img data-src="@/assets/images/11-monte-cassino-jeep.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute monte-cassino-collage__nine">
            <kinesis-element :strength="20" :originX="0" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/11-monte-cassino-przedstawienie.png">
                <img data-src="@/assets/images/11-monte-cassino-przedstawienie.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
        </kinesis-container>
      </div>
    </div>
    <div id="monte-cassino-collage-description" class="visually-hidden">{{ $t('collage_description') }}</div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import ArticleText from '@/components/partials/ArticleText.vue'
import ArticleCollageMerged from '@/components/partials/ArticleCollageMerged.vue'

export default {
  components: {
    ArticleCollageMerged,
    ArticleText
  },
  data() {
    return {
      sectionNumber: 38
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Zawód: reporter",
    "text": "Wielka bitwa o Monte Cassino doczekała się monumentalnego dzieła. Melchior Wańkowicz, as polskiego reportażu, na podstawie własnych przeżyć i setek wywiadów opisał ją w trzytomowej książce.",
    "article_url": "https://culture.pl/pl/superartykul/bitwa-o-monte-cassino-sztuka-wojennego-reportazu",
    "collage_description": "Grafika stworzona techniką kolażu. Centralnie w tle klasztor na Monte Cassino w kolorach flagi polskiej. Po lewej czerwone maki wyrastające z grafiki wojskowego jeep’a. Nad klasztorem postać Melchiora Wańkowicza wskazująca palcem prawej dłoni na bok. Za nim flagi Polski i Zjednoczonego Królestwa. Na dole siedzi wielu żołnierzy. Przyglądają się wzgórzu."
  },
  "en": {
    "title": "Through a reporter’s eyes",
    "text": "The great battle at Monte Cassino also gave rise to a monumental work of art. Melchior Wańkowicz, a star of Polish reportage, described it in a three-volume book – based on his own experiences as well as hundreds of interviews.",
    "article_url": "https://culture.pl/en/feature/melchior-wankowicz-monte-cassino-the-art-of-war-reportage",
    "collage_description": "In the background at centre – the Monte Cassino monastery in the Polish national flag colours. On the left – red poppy flowers growing out of a military jeep. Over the monastery – Melchior Wańkowicz pointing his right finger to the side. Behind him – the national flags of Poland and the United Kingdom. At the bottom – many soldiers looking at the hill."
  },
  "ru": {
    "title": "Профессия: репортер",
    "text": "Великая битва за Монте-Кассино увековечена в монументальном произведении. Мельхиор Ванькович, выдающийся польский репортер, на основе собственного опыта и сотен интервью описал ее в трехтомной книге.",
    "article_url": "https://culture.pl/ru/superarticle/bitva-za-monte-kassino-iskusstvo-voennogo-reportazha",
    "collage_description": "Графика, выполненная в технике коллажа. В центре на заднем плане — монастырь на Монте-Кассино в цветах польского флага. Слева — красные маки, военный джип. Над монастырем — Мельхиор Ванькович, указывающего пальцем куда-то вперед. За ним — флаги Польши и Великобритании. Внизу сидят несколько солдат. Они смотрят на вершину холма."
  }
}
</i18n>
