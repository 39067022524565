<template>
  <section class="section quote intro-quote" data-chapter="intro" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="quote__inner">
      <template v-if="$screen.width < 1024">
        <QuoteImageMobile fileSm="00-intro-quote-sm.jpg" fileMd="00-intro-quote-md.jpg" :description="$t('image_description')" :alt="$t('author_picture_alt')" />
        <QuoteAuthorPictureMobile file="00-intro-quote-author-picture-sm.png" :alt="$t('author_picture_alt')" />
      </template>
      <template v-if="$screen.width >= 1024">
        <div class="quote__author-picture">
          <div class="quote__author-picture-inner">
            <picture class="lazy">
              <source media="(min-width:1921px)" data-srcset="@/assets/images/00-intro-quote-author-picture.png">
              <img data-src="@/assets/images/00-intro-quote-author-picture.png" :alt="$t('author_picture_alt')" class=" test intro-quote__author-image" />
            </picture>
          </div>
        </div>
      </template>
      <figure>
        <blockquote>
          <p><span>{{ $t('quote') }}</span></p>
        </blockquote>
        <figcaption>
          <span class="quote__author-name"><span>{{ $t('author_name') }}</span></span>
          <span class="quote__author-title"><span>{{ $t('author_title') }}</span></span>
        </figcaption>
      </figure>
      <template v-if="$screen.width >= 1024">
        <div class="quote__background-image intro-quote__background-image lazy-background"></div>
        <div class="quote__background-image-description">
          <p><span>{{ $t('image_description') }}</span></p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import QuoteImageMobile from '@/components/partials/QuoteImageMobile.vue'
import QuoteAuthorPictureMobile from '@/components/partials/QuoteAuthorPictureMobile.vue'

export default {
  components: {
    QuoteImageMobile,
    QuoteAuthorPictureMobile
  },
  data() {
    return {
      sectionNumber: 3
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "quote": "[Gen. Władysław] Anders był również głównym budowniczym tej \"małej Polski\", która powstała w Iraku i we Włoszech, jej ramy, jej sens głęboki przerastały czysto militarne zadanie wojska. Cóż to było ta \"mała Polska\" na Wschodzie?",
    "author_name": "Józef Czapski,",
    "author_title": "pisarz i malarz",
    "author_picture_alt": "Czarno-biała fotografia. W tle gen. Anders i grupa kilku oficerów pochylonych nad mapą. Centralnie – kuca Józef Czapski. Po prawej żołnierz wskazujący palcem prawej ręki punkt na mapie. W lewym dolnym rogu fragment białego koła, a na nim czarno-białe zdjęcie - portret Józefa Czapskiego w mundurze. Na jego twarzy uśmiech. Pośrodku fotografii nad mapą, białe litery w kilku rzędach. Cytat wypowiedzi Józefa Czapskiego.",
    "image_description": "Fot: Instytut Literacki w Paryżu"
  },
  "en": {
    "quote": "General Władysław Anders was the main architect of this ‘little Poland’ which sprang up in Iraq and Italy – of its framework, and of its deep sense, which grew beyond the army’s purely military objective. But what was this ‘little Poland’ in the East?",
    "author_name": "Józef Czapski,",
    "author_title": "writer & painter",
    "author_picture_alt": "A black-and-white photo. In the background – Gen. Anders and a group of a few officers leaning over a map. In the centre – Józef Czapski crouching. On the right a soldier pointing his right finger at a location on the map.  In the bottom left corner – a piece of a white circle; on it – a black-and-white portrait photo of Józef Czapski in a military uniform. A smile on his face. In the middle of the photograph, above the map, white letters in two rows. A quote from Czapski’s statement.",
    "image_description": "Photo: Kultura Literary Institute"
  },
  "ru": {
    "quote": "[Ген. Владислав] Андерс был одним из главных создателей «малой Польши», появившейся в Ираке и Италии. Цель и смысл ее создания были гораздо шире и глубже чисто военной задачи армии. Что представляла собой эта «малая Польша» на Востоке?",
    "author_name": "Юзеф Чапский,",
    "author_title": "писатель и художник",
    "author_picture_alt": "Черно-белая фотография. На заднем плане генерал Андерс и группа из нескольких офицеров, склонившихся над картой. В центре — Юзеф Чапский. Справа солдат указывает пальцем на точку на карте. В левом нижнем углу — фрагмент белого круга, на нем черно-белая фотография, это портрет Юзефа Чапского в военной форме. На его лице — улыбка. В центре фотографии над картой белые буквы в несколько рядов. Цитата из обращения Юзефа Чапского.",
    "image_description": "Фото: Институт литературы в Париже"
  }
}
</i18n>
