<template>
  <section class="section quote griazowiec-quote" data-chapter="griazowiec" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="quote__inner">
      <template v-if="$screen.width < 1024">
        <QuoteImageMobile fileSm="01-griazowiec-quote-sm.png" fileMd="01-griazowiec-quote-md.png" :description="$t('image_description')" :alt="$t('author_picture_alt')"/>
        <QuoteAuthorPictureMobile file="01-griazowiec-quote-author-picture-sm.png" :alt="$t('author_picture_alt')" />
      </template>
      <template v-if="$screen.width >= 1024">
        <div class="quote__author-picture">
          <div class="quote__author-picture-inner">
            <picture class="lazy">
              <source media="(min-width:1921px)" data-srcset="@/assets/images/01-griazowiec-quote-author-picture.png">
              <img data-src="@/assets/images/01-griazowiec-quote-author-picture.png" :alt="$t('author_picture_alt')" class="griazowiec-quote__author-image" />
            </picture>
          </div>
        </div>
      </template>
      <figure>
        <blockquote>
          <p><span>{{ $t('quote') }}</span></p>
        </blockquote>
        <figcaption>
          <span class="quote__author-name"><span>{{ $t('author_name') }}</span></span>
        </figcaption>
      </figure>
      <template v-if="$screen.width >= 1024">
        <div class="quote__background-image griazowiec-quote__background-image lazy-background"></div>
        <div class="quote__background-image-description">
          <p><span>{{ $t('image_description') }}</span></p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import QuoteImageMobile from '@/components/partials/QuoteImageMobile.vue'
import QuoteAuthorPictureMobile from '@/components/partials/QuoteAuthorPictureMobile.vue'

export default {
  components: {
    QuoteImageMobile,
    QuoteAuthorPictureMobile
  },
  data() {
    return {
      sectionNumber: 6
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "quote": "Nad generałem wisi wielka mapa Rosji, na którą łapczywie i możliwie nieznacznie rzucam okiem. Wyrysowane są na niej wszystkie punkty, gdzie znajdują się obozy więźniów, największe skupiska są oznaczone wielkimi gwiazdami, inne mniejszymi, poza tym jeszcze są kółka i kółeczka. Zdążyłem skonstatować, że największe konstelacje są na półwyspie Kola, w Komi i na Kołymie.",
    "author_name": "Józef Czapski",
    "author_picture_alt": "Grafika szarej mapy ZSRR z I połowy XX wieku. Na niej na czerwono zaznaczone granice i czerwone gwiazdy wskazujące lokalizację łagrów. W lewym dolnym rogu zdjęcie Józefa Czapskiego w mundurze, z prawego profilu.  Pośrodku mapy białe litery w kilku rzędach. Cytat wypowiedzi Józefa Czapskiego.",
    "image_description": "Fot. Biblioteka Narodowa Polona"
  },
  "en": {
    "quote": "Above the general hung a large map of all Russia, at which I cast a hungry eye, as subtly as I could. It showed the locations of all the prison camps on it, with the biggest clusters marked by large stars, other by smaller ones, and various circles, large and small. I managed to note that the biggest constellations were on the Kola Peninsula, in Komi and Kolyma.",
    "author_name": "Józef Czapski",
    "author_picture_alt": "A graphic of the USSR map in the first half of the 20th century. On it – state borders marked in red colour, with red stars depicting labour camps’ locations. In the bottom left corner – a right-profile photo of Józef Czapski in a military uniform. In the centre – white letters in a few rows. A quote from Józef Czapski’s statement.",
    "image_description": "Photo: National Digital Library Polona"
  },
  "ru": {
    "quote": "Над генералом висела огромная карта Советского Союза, которую я жадно, но незаметно рассматривал. На ней были обозначены все пункты, где были расположены лагеря. Самые крупные их скопления были обозначены большими звездами, другие — звездами поменьше, были кружки и кружочки поменьше. Я успел заметить, что самые большие «созвездия» расположены на Кольском полуострове, в Коми и на Колыме.",
    "author_name": "Юзеф Чапский",
    "author_picture_alt": "Изображение серой карты СССР первой половины 20-го века. На нем границы обозначены красным цветом, а красные звезды указывают на места расположения лагерей. В левом нижнем углу — фотография Юзефа Чапского в военной форме, в профиль.  В центре карты — белые буквы в несколько рядов. Цитата из обращения Юзефа Чапского.",
    "image_description": "Фото: Национальная библиотека Polona"
  }
}
</i18n>
