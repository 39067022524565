<template>
  <Waypoint @change="onWaypointChange" />
  <div class="quote__author-picture-mobile">
    <div class="quote__author-picture-mobile-inner">
      <img v-if="visible" :src="getImageSource(file)" :alt="alt || ''" />
    </div>
  </div>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  name: 'QuoteAuthorPictureMobile',
  props: ['file', 'alt'],
  components: {
    Waypoint
  },
  data () {
    return {
      fileName: this.file,
      imageAlt: this.alt,
      visible: false
    }
  },
  methods: {
    getImageSource(fileName) {
      return require(`@/assets/images/${fileName}`)
    },
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.visible = true
      }
    }
  }
}
</script>
