<template>
  <section ref="Section" class="section cover" data-chapter="epilog" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <div class="cover__text">
       <p>{{ $t('text') }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 46
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>
<i18n>
{
  "pl": {
    "text": "Tylko znikoma część żołnierzy zdecydowała się na powrót do kraju w obawie przed represjami i nowym komunistycznym porządkiem. Do końca 1946 roku 91 tysięcy osób związanych z Armią osiedliło się w różnych krajach Europy Zachodniej i w USA. Wielu z nich już nigdy nie wróciło do ojczyzny.",
    "article_url": "http://culture.pl/pl",
    "collage_description": ""
  },
  "en": {
    "text": "Fearing repressive measures and the new communist regime, only a tiny fraction of the Anders’ Army soldiers decided to return to Poland. By the end of the year 1946, 91,000 people with ties to the army had settled in various western European countries, as well as in the USA. Many would never return to their homeland.",
    "article_url": "http://culture.pl/en",
    "collage_description": ""
  },
  "ru": {
    "text": "Только незначительная часть солдат приняла решение о возвращении на родину. До конца 1946 года 91 тысяча человек, связанных с армией, поселилась в разных странах Западной Европы и в США. Многие из них, опасаясь репрессий и нового коммунистического режима, так и не вернулись в Польшу.",
    "article_url": "http://culture.pl/ru",
    "collage_description": ""
  }
}
</i18n>
