<template>
  <section class="section video" data-chapter="beirut" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="video__inner">
      <Video file="08-beirut" a11yid="beirut-caption" :a11ydescription="$t('a11y_description')" />
      <VideoDescription :caption="$t('caption')" :source="$t('source')" />
      <VideoPreloader />
    </div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import Video from '@/components/partials/Video.vue'
import VideoDescription from '@/components/partials/VideoDescription.vue'
import VideoPreloader from '@/components/partials/VideoPreloader.vue'

export default {
  components: {
    Video,
    VideoDescription,
    VideoPreloader
  },
  data() {
    return {
      sectionNumber: 29
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "caption": "Renata Bogdańska i artyści Polish Parade podczas występu w Teheranie.",
    "source": "Źródło: Instytut Polski i Muzeum im. gen. Sikorskiego w Londynie",
    "a11y_description": "Występ sceniczny w sali widowiskowej. W tle grupa muzyków podczas występu. Na pierwszym planie  szczupła blondynka Renata Bogdańska otoczona przez trzech mężczyzn – z uśmiechem śpiewa."
  },
  "en": {
    "caption": "Renata Bogdańska and the artists of the Polish Parade performing in Tehran,",
    "source": "video: The Polish Institute and Sikorski Museum in London",
    "a11y_description": "A performance in a concert hall. In the background – a group of musicians playing. In the foreground – a slim blonde woman, Renata Bogdańska, surrounded by three men, sings with a smile on her face."
  },
  "ru": {
    "caption": "Рената Богданская и артисты Polish Parade во время выступления в Тегеране,",
    "source": "видео: Польским институтом и музеем имени генерала Сикорского в Лондоне",
    "a11y_description": "Выступление на сцене перед зрительным залом. На заднем плане играет группа музыкантов. На переднем плане поет стройная, улыбающаяся блондинка в окружении трех мужчин. Это певица Рената Богданьская."
  }
}
</i18n>
