<template>
  <Navigation />
  <div class="sections">
    <SectionIntro />
    <SectionIntroCollage />
    <SectionIntroIntroduction />
    <SectionIntroQuote />
    <SectionGriazowiecCover />
    <SectionGriazowiecArticle />
    <SectionGriazowiecQuote />
    <SectionBuzulukCover />
    <SectionBuzulukVideo />
    <SectionBuzulukQuote />
    <SectionTaszkientCover />
    <SectionTaszkientArticle />
    <SectionTaszkientQuote />
    <SectionKrasnowodzkCover />
    <SectionKrasnowodzkVideo />
    <SectionKrasnowodzkAnimation />
    <SectionKrasnowodzkQuote />
    <SectionTeheranCover />
    <SectionTeheranArticle />
    <SectionTeheranVideo />
    <SectionIsfahanCover />
    <SectionIsfahanArticle />
    <SectionIsfahanMap />
    <SectionIsfahanVideo />
    <SectionBagdadCover />
    <SectionBagdadArticle />
    <SectionBagdadVideo />
    <SectionBeirutCover />
    <SectionBeirutArticle />
    <SectionBeirutVideo />
    <SectionBeirutQuote />
    <SectionJerozolimaCover />
    <SectionJerozolimaArticle />
    <SectionJerozolimaQuote />
    <SectionKairCover />
    <SectionKairArticle />
    <SectionKairVideo />
    <SectionMonteCassinoCover />
    <SectionMonteCassinoArticle />
    <SectionMonteCassinoQuote />
    <SectionMonteCassinoVideo />
    <SectionBoloniaCover />
    <SectionBoloniaArticle />
    <SectionBoloniaVideo />
    <SectionParyzLondynRzymCover />
    <SectionParyzLondynRzymArticle />
    <SectionEpilogueCover />
    <SectionEpilogueBiographies />
    <SectionEpilogueAnimation />
    <SectionEpilogueMap />
    <SectionFooter />
  </div>
  <ScrollPositionIndicator />
  <resize-observer @notify="handleResize" :showTrigger="true" />
</template>

<script>
import Navigation from './components/Navigation.vue'
import ScrollPositionIndicator from './components/ScrollPositionIndicator.vue'
import SectionIntro from './components/sections/00_intro/00_Intro.vue'
import SectionIntroCollage from './components/sections/00_intro/01_Collage.vue'
import SectionIntroIntroduction from './components/sections/00_intro/02_Introduction.vue'
import SectionIntroQuote from './components/sections/00_intro/03_Quote.vue'
import SectionGriazowiecCover from './components/sections/01_griazowiec/04_Cover.vue'
import SectionGriazowiecArticle from './components/sections/01_griazowiec/05_Article.vue'
import SectionGriazowiecQuote from './components/sections/01_griazowiec/06_Quote.vue'
import SectionBuzulukCover from './components/sections/02_buzuluk/07_Cover.vue'
import SectionBuzulukVideo from './components/sections/02_buzuluk/08_Video.vue'
import SectionBuzulukQuote from './components/sections/02_buzuluk/09_Quote.vue'
import SectionTaszkientCover from './components/sections/03_taszkient/10_Cover.vue'
import SectionTaszkientArticle from './components/sections/03_taszkient/11_Article.vue'
import SectionTaszkientQuote from './components/sections/03_taszkient/12_Quote.vue'
import SectionKrasnowodzkCover from './components/sections/04_krasnowodzk/13_Cover.vue'
import SectionKrasnowodzkVideo from './components/sections/04_krasnowodzk/14_Video.vue'
import SectionKrasnowodzkAnimation from './components/sections/04_krasnowodzk/15_Animation.vue'
import SectionKrasnowodzkQuote from './components/sections/04_krasnowodzk/16_Quote.vue'
import SectionTeheranCover from './components/sections/05_teheran/17_Cover.vue'
import SectionTeheranArticle from './components/sections/05_teheran/18_Article.vue'
import SectionTeheranVideo from './components/sections/05_teheran/19_Video.vue'
import SectionIsfahanCover from './components/sections/06_isfahan/20_Cover.vue'
import SectionIsfahanArticle from './components/sections/06_isfahan/21_Article.vue'
import SectionIsfahanMap from './components/sections/06_isfahan/22_Map.vue'
import SectionIsfahanVideo from './components/sections/06_isfahan/23_Video.vue'
import SectionBagdadCover from './components/sections/07_bagdad/24_Cover.vue'
import SectionBagdadArticle from './components/sections/07_bagdad/25_Article.vue'
import SectionBagdadVideo from './components/sections/07_bagdad/26_Video.vue'
import SectionBeirutCover from './components/sections/08_beirut/27_Cover.vue'
import SectionBeirutArticle from './components/sections/08_beirut/28_Article.vue'
import SectionBeirutVideo from './components/sections/08_beirut/29_Video.vue'
import SectionBeirutQuote from './components/sections/08_beirut/30_Quote.vue'
import SectionJerozolimaCover from './components/sections/09_jerozolima/31_Cover.vue'
import SectionJerozolimaArticle from './components/sections/09_jerozolima/32_Article.vue'
import SectionJerozolimaQuote from './components/sections/09_jerozolima/33_Quote.vue'
import SectionKairCover from './components/sections/10_kair/34_Cover.vue'
import SectionKairArticle from './components/sections/10_kair/35_Article.vue'
import SectionKairVideo from './components/sections/10_kair/36_Video.vue'
import SectionMonteCassinoCover from './components/sections/11_monte-cassino/37_Cover.vue'
import SectionMonteCassinoArticle from './components/sections/11_monte-cassino/38_Article.vue'
import SectionMonteCassinoQuote from './components/sections/11_monte-cassino/39_Quote.vue'
import SectionMonteCassinoVideo from './components/sections/11_monte-cassino/40_Video.vue'
import SectionBoloniaCover from './components/sections/12_bolonia/41_Cover.vue'
import SectionBoloniaArticle from './components/sections/12_bolonia/42_Article.vue'
import SectionBoloniaVideo from './components/sections/12_bolonia/43_Video.vue'
import SectionParyzLondynRzymCover from './components/sections/13_paryz-londyn-rzym/44_Cover.vue'
import SectionParyzLondynRzymArticle from './components/sections/13_paryz-londyn-rzym/45_Article.vue'
import SectionEpilogueCover from './components/sections/14_epilogue/46_Cover.vue'
import SectionEpilogueBiographies from './components/sections/14_epilogue/47_Biographies.vue'
import SectionEpilogueAnimation from './components/sections/14_epilogue/48_Animation.vue'
import SectionEpilogueMap from './components/sections/14_epilogue/49_Map.vue'
import SectionFooter from './components/sections/15_footer/50_Footer.vue'

export default {
  name: 'App',
  components: {
    Navigation,
    ScrollPositionIndicator,
    SectionIntro,
    SectionIntroCollage,
    SectionIntroIntroduction,
    SectionIntroQuote,
    SectionGriazowiecCover,
    SectionGriazowiecArticle,
    SectionGriazowiecQuote,
    SectionBuzulukCover,
    SectionBuzulukVideo,
    SectionBuzulukQuote,
    SectionTaszkientCover,
    SectionTaszkientArticle,
    SectionTaszkientQuote,
    SectionKrasnowodzkCover,
    SectionKrasnowodzkVideo,
    SectionKrasnowodzkAnimation,
    SectionKrasnowodzkQuote,
    SectionTeheranCover,
    SectionTeheranArticle,
    SectionTeheranVideo,
    SectionIsfahanCover,
    SectionIsfahanArticle,
    SectionIsfahanMap,
    SectionIsfahanVideo,
    SectionBagdadCover,
    SectionBagdadArticle,
    SectionBagdadVideo,
    SectionBeirutCover,
    SectionBeirutArticle,
    SectionBeirutVideo,
    SectionBeirutQuote,
    SectionJerozolimaCover,
    SectionJerozolimaArticle,
    SectionJerozolimaQuote,
    SectionKairCover,
    SectionKairArticle,
    SectionKairVideo,
    SectionMonteCassinoCover,
    SectionMonteCassinoArticle,
    SectionMonteCassinoQuote,
    SectionMonteCassinoVideo,
    SectionBoloniaCover,
    SectionBoloniaArticle,
    SectionBoloniaVideo,
    SectionParyzLondynRzymCover,
    SectionParyzLondynRzymArticle,
    SectionEpilogueCover,
    SectionEpilogueBiographies,
    SectionEpilogueAnimation,
    SectionEpilogueMap,
    SectionFooter
  },
  data() {
    return {
      sections: [],
      currentSectionNumber: 0,
      totalSectionNumber: 0,
      isAnimating: false,
      isNavigationOpen: false,
      timeout: 700,
      upKeys: [37, 38],
      downKeys: [39, 40],
      touchSensitivity: 50,
      longSwipe: 200,
      startY: 0,
      urlIdentifiers: [
        { id: 0, name: 'wstep' },
        { id: 4, name: 'griazowiec' },
        { id: 7, name: 'buzuluk' },
        { id: 10, name: 'taszkient' },
        { id: 13, name: 'krasnowodzk' },
        { id: 17, name: 'teheran' },
        { id: 20, name: 'isfahan' },
        { id: 24, name: 'bagdad' },
        { id: 27, name: 'beirut' },
        { id: 31, name: 'jerozolima' },
        { id: 34, name: 'kair' },
        { id: 37, name: 'monte-cassino' },
        { id: 41, name: 'bolonia' },
        { id: 44, name: 'paryz-londyn-rzym' },
        { id: 47, name: 'biografie' },
        { id: 49, name: 'szlak-armii-andersa' },
        { id: 50, name: 'o-projekcie' }
      ]
    }
  },
  methods: {
    handleWheel(event) {
      event.stopPropagation()
      if (this.isAnimating || this.isNavigationOpen) {
        return false
      }
      let direction = -event.deltaY
      if (direction < 0 && this.hasDown()) {
        this.scrollDown()
      } else if (direction > 0 && this.hasUp()) {
        this.scrollUp()
      }
    },
    handleKeyDown(event) {
      if (this.isAnimating) {
        event.preventDefault()

        return
      }
      let key = event.which

      if(key == 9) {
        if(event.shiftKey) {
           document.querySelectorAll('.section__anchor--top').forEach(x=>x.setAttribute('tabindex', '-1'))
           document.querySelectorAll('.section__anchor--bottom').forEach(x=>x.setAttribute('tabindex', '0'))
        } else {
          document.querySelectorAll('.section__anchor--top').forEach(x=>x.setAttribute('tabindex', '0'))
          document.querySelectorAll('.section__anchor--bottom').forEach(x=>x.setAttribute('tabindex', '-1'))
        }
      }
    },
    handleKeyUp(event) {
      if (this.isAnimating || this.isNavigationOpen) {
        return
      }
      let key = event.which

      if (this.upKeys.includes(key) && this.hasUp()) {
        this.scrollUp()
      } else if (this.downKeys.includes(key) && this.hasDown()) {
        this.scrollDown()
      }
    },
    handleTouch(event) {
      event.stopPropagation()
      this.startY = event.touches[0].screenY
      document.addEventListener('touchend', this.removeTouchHandlers)
      document.addEventListener('touchmove', this.handleSwipe)
    },
    handleSwipe(event) {
      if (this.isAnimating || this.isNavigationOpen) {
        return
      }
      let deltaY = event.touches[0].screenY - this.startY
      if (deltaY > this.touchSensitivity) {
        if (this.hasUp()){
          document.removeEventListener('touchmove', this.handleSwipe)
          this.scrollUp()
        } else if (deltaY > this.longSwipe) {
          window.location.reload()
        }
      } else if (deltaY < -this.touchSensitivity && this.hasDown()) {
        document.removeEventListener('touchmove', this.handleSwipe)
        this.scrollDown()
      }
    },
    removeTouchHandlers() {
      document.removeEventListener('touchmove', this.handleSwipe)
      document.removeEventListener('touchend', this.removeHandlers)
    },
    handleClick(sectionNumber) {
      if (this.isAnimating) {
        return
      }
      let direction
      if (sectionNumber > this.currentSectionNumber) {
        direction = 'down'
      } else if (sectionNumber < this.currentSectionNumber) {
        direction = 'up'
      } else {
        return
      }
      this.currentSectionNumber = sectionNumber
      this.scrollTo(sectionNumber, direction)
    },
    scrollDown() {
      if (this.currentSectionNumber + 1 >= this.totalSectionNumber) {
        return
      }
      this.scrollTo(++this.currentSectionNumber, 'down')
    },
    scrollUp() {
      if (this.currentSectionNumber - 1 < 0) {
        return
      }
      this.scrollTo(--this.currentSectionNumber, 'up')
    },
    scrollTo(sectionNumber, direction) {
      if (sectionNumber > this.totalSectionNumber || sectionNumber < 0) {
        return
      }
      this.isAnimating = true
      let newCurrentSection = document.getElementsByTagName('section')[sectionNumber]
      newCurrentSection.classList.add('no-transition')
      if (direction === 'down') {
        newCurrentSection.classList.remove('ex-current-down')
        newCurrentSection.classList.add('ex-current-up')
      } else if (direction === 'up') {
        newCurrentSection.classList.remove('ex-current-up')
        newCurrentSection.classList.add('ex-current-down')
      }
      setTimeout(() => {
        this.isAnimating = true
        this.stopAnimation()
        let currentSection = document.getElementsByClassName('current')[0]
        const classList = ['current', 'ex-current-down', 'ex-current-up']
        currentSection.classList.remove(...classList)
        currentSection.classList.add(`ex-current-${direction}`)
        this.sectionConditionalHide(currentSection)
        newCurrentSection.classList.remove('no-transition')
        newCurrentSection.classList.add('current')
        this.sectionConditionalShow(newCurrentSection)
        this.updateNavigationLinks(newCurrentSection)
        this.updateScrollPositionIndicator(newCurrentSection)
        this.emitter.emit('sectionIsActive', sectionNumber)
      }, 100)

    },
    hasDown() {
      return this.currentSectionNumber < this.totalSectionNumber
    },
    hasUp() {
      return this.currentSectionNumber > 0
    },
    stopAnimation() {
      setTimeout(() => {
        this.isAnimating = false
      }, this.timeout)
    },
    sectionConditionalShow(section) {
      if (section.classList.contains('article')) {
        return this.loadImages(section)
      }
      if (section.classList.contains('quote')) {
        this.loadBackgroundImage(section)
        this.loadImages(section)
        return
      }
      if (section.classList.contains('video')) {
        return this.playVideo(section)
      }
      return
    },
    sectionConditionalHide(section) {
      if (section.classList.contains('video')) {
        return this.pauseVideo(section)
      }
      return
    },
    loadImages(section) {
      let lazySources = [].slice.call(
        section.querySelectorAll('.lazy > source')
      )
      let lazyImages = [].slice.call(
        section.querySelectorAll('.lazy > img')
      )
      lazySources.forEach((lazySource) => {
        lazySource.srcset = this.getImage(lazySource.dataset.srcset)
        lazySource.removeAttribute('data-srcset')
        lazySource.parentElement.classList.remove('lazy')
      })
      lazyImages.forEach((lazyImage) => {
        lazyImage.src = this.getImage(lazyImage.dataset.src)
        lazyImage.removeAttribute('data-src')
      })
    },
    loadBackgroundImage(section) {
      section.getElementsByClassName('quote__background-image')[0].classList.remove('lazy-background')
    },
    playVideo(section) {
      const video = section.querySelector('video')

      if (video) {
        video.load()
        video.play()
        video.addEventListener('playing', this.handleVideoPlaying)
        video.addEventListener('pause', this.handleVideoPause)
      }
    },
    pauseVideo(section) {
      const video = section.querySelector('video')

      if (video) {
        video.pause()
      }
    },
    handleVideoPlaying(event) {
      const video = event.target
      const preloader = video.closest('section').getElementsByClassName('video__preloader')[0]

      preloader.classList.add('video__preloader--hidden')
      video.removeEventListener('playing', this.handleVideoPlaying)
    },
    handleVideoPause(event) {
      const video = event.target
      const tmpSrc = video.currentSrc
      const playtime = video.currentTime

      video.src = ''
      video.load()
      video.src = tmpSrc
      video.currentTime = playtime
      video.removeEventListener('pause', this.handleVideoPause)
    },
    getImage(fileSource) {
      let fileName = fileSource.substring(fileSource.lastIndexOf('/') + 1)
      return require(`@/assets/images/${fileName}`)
    },
    onClassChange(classAttrValue) {
      const classList = classAttrValue.split(' ')
      if (classList.includes('navigation-opened')) {
        this.isNavigationOpen = true
      } else {
        this.isNavigationOpen = false
      }
    },
    updateNavigationLinks(section) {
      const navigation = document.getElementById('navigation')
      if (navigation.getElementsByClassName('navigation__link--active').length > 0) {
        navigation.getElementsByClassName('navigation__link--active')[0].classList.remove('navigation__link--active')
      }
      if (section.id) {
        navigation.getElementsByClassName(`navigation__link--${section.id}`)[0].classList.add('navigation__link--visited')
      }
      if (section.dataset.chapter) {
        navigation.getElementsByClassName(`navigation__link--${section.dataset.chapter}`)[0].classList.add('navigation__link--active')
      }
    },
    updateScrollPositionIndicator(section) {
      if (section.dataset.number) {
        let sectionNumber = parseInt(section.dataset.number)
        ++sectionNumber
        let percent = Math.round((sectionNumber / this.totalSectionNumber) * 100)
        document.getElementById('scroll-position-indicator-bar-color').style.width=`${percent}%`
      }
    },
    handleResize({ width }) {
      if (width >= 1024) {
        document.addEventListener('wheel', this.handleWheel)
        document.addEventListener('keydown', this.handleKeyDown)
        document.addEventListener('keydown', this.handleFirstTab)
        document.addEventListener('keyup', this.handleKeyUp)
        document.addEventListener('touchstart', this.handleTouch)
      } else {
        document.removeEventListener('wheel', this.handleWheel)
        document.removeEventListener('keydown', this.handleKeyDown)
        document.removeEventListener('keydown', this.handleFirstTab)
        document.removeEventListener('keyup', this.handleKeyUp)
        document.removeEventListener('touchstart', this.handleTouch)
      }
    },
    handleFirstTab(event) {
      if (event.keyCode === 9) {
        document.body.classList.add('user-is-tabbing')
        document.removeEventListener('keydown', this.handleFirstTab)
      }
    },
    hashHandler() {
      const hash = location.hash.substr(1)
      const identifier = this.urlIdentifiers.find((identifierName) => identifierName.name === hash)

      if (identifier) {
        this.handleClick(identifier.id)
      }
    }
  },
  mounted() {
    this.sections = document.querySelectorAll('section')
    this.totalSectionNumber = this.sections.length
    document.getElementsByTagName('section')[0].classList.add('current')
    if (this.$screen.width >= 1024) {
      document.addEventListener('wheel', this.handleWheel)
      document.addEventListener('keydown', this.handleKeyDown)
      document.addEventListener('keydown', this.handleFirstTab)
      document.addEventListener('keyup', this.handleKeyUp)
      document.addEventListener('touchstart', this.handleTouch)
      this.hashHandler()
    }

    this.observer = new MutationObserver(mutations => {
      for (const mutation of mutations) {
        const newValue = mutation.target.getAttribute(mutation.attributeName);
        this.$nextTick(() => {
          this.onClassChange(newValue, mutation.oldValue)
        })
      }
    })
    this.observer.observe(document.getElementsByTagName('body')[0], {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class']
    })
  }
}
</script>

<style lang="scss">
  @import "./scss/main.scss";
</style>
