<template>
  <section ref="Section" class="section cover" id="paryz-londyn-rzym" data-chapter="paryz-londyn-rzym" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description') }}</p>
      </div>
      <div class="cover__number" aria-hidden="true">13</div>
      <div class="cover__title" aria-hidden="true">{{ $t('title') }}</div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 44
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Paryż, Londyn, Rzym",
    "description": "Po zakończeniu działań wojennych żołnierze pozostali we Włoszech aż do 1946 roku, kiedy rząd brytyjski podjął decyzję o przeniesieniu 2. Korpusu do Wielkiej Brytanii.",
    "screen_reader_title": "Rozdział 13 Paryż, Londyn, Rzym"
  },
  "en": {
    "title": "Paris, London, Rome",
    "description": "After completing their wartime activities, the soldiers stayed in Italy until 1946, when the British government decided to transfer the Polish II Corps to Great Britain.",
    "screen_reader_title": "Chapter 13 Paris, London, Rome"
  },
  "ru": {
    "title": "Париж, Лондон, Рим",
    "description": "После завершения военных действий солдаты оставались в Италии вплоть до 1946 года, когда британское правительство приняло решение о переводе 2-го корпуса в Великобританию.",
    "screen_reader_title": "Глава 13 Париж, Лондон, Рим"
  }
}
</i18n>
