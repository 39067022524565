<template>
  <section class="section article" data-chapter="beirut" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="collage beirut-collage" aria-describedby="beirut-collage-description">
      <div class="collage__inner">
        <div class="collage__merged" v-if="$screen.width < 1024">
          <ArticleCollageMerged file="08-beirut" />
        </div>
        <template v-if="$screen.width < 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
        </template>
        <kinesis-container v-if="$screen.width >= 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
          <div class="collage__height">
            <kinesis-element :strength="10"></kinesis-element>
          </div>
          <div class="collage__absolute beirut-collage__one">
            <kinesis-element :strength="10" :originX="0" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/08-beirut-swiatlo.png">
                <img data-src="@/assets/images/08-beirut-swiatlo.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute beirut-collage__two">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/08-beirut-kobieta-roza.png">
                <img data-src="@/assets/images/08-beirut-kobieta-roza.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute beirut-collage__three">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/08-beirut-domy.png">
                <img data-src="@/assets/images/08-beirut-domy.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute beirut-collage__four">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/08-beirut-domy.png">
                <img data-src="@/assets/images/08-beirut-domy.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute beirut-collage__five">
            <kinesis-element :strength="10" :originX="100" :originY="0">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/08-beirut-bg.png">
                <img data-src="@/assets/images/08-beirut-bg.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute beirut-collage__six">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/08-beirut-kobieta.png">
                <img data-src="@/assets/images/08-beirut-kobieta.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute beirut-collage__seven">
            <kinesis-element :strength="30" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/08-beirut-charlie-chaplin.png">
                <img data-src="@/assets/images/08-beirut-charlie-chaplin.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute beirut-collage__eight">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/08-beirut-reka-kobiety.png">
                <img data-src="@/assets/images/08-beirut-reka-kobiety.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
        </kinesis-container>
      </div>
    </div>
    <div id="beirut-collage-description" class="visually-hidden">{{ $t('collage_description') }}</div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import ArticleText from '@/components/partials/ArticleText.vue'
import ArticleCollageMerged from '@/components/partials/ArticleCollageMerged.vue'

export default {
  components: {
    ArticleCollageMerged,
    ArticleText
  },
  data() {
    return {
      sectionNumber: 28
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Kabaret na wojnie",
    "text": "Dwudziestolecie międzywojenne to epoka rozkwitu kabaretu. Scenicznego humoru nie zabrakło też na froncie.",
    "article_url": "http://culture.pl/pl/superartykul/humor-w-czasie-marnym-artysci-kabaretowi-na-wojennym-szlaku",
    "collage_description": "Grafika stworzona techniką kolażu. Centralnie – Nina Oleńska z puszkami żywności, obok niej postać aktora Feliksa Fabiana jako Charlie Chaplin. Lewy górny róg – sylwetka Renaty Bogdańskiej z czerwoną różą w lewej dłoni."
  },
  "en": {
    "title": "Wartime cabaret",
    "text": "Cabaret had blossomed in the Interwar period – and onstage humour never lacked on the front.",
    "article_url": "http://culture.pl/en/feature/humour-in-a-miserable-time-cabaret-artists-on-the-war-trail",
    "collage_description": "In the centre – the actor Nina Oleńska with food cans. On the right – the figure of the actor Feliks Fabian as Charlie Chaplin. Top left corner – the figure of Renata Bogdańska with a red rose in her left hand."
  },
  "ru": {
    "title": "Кабаре на войне",
    "text": "Межвоенное двадцатилетие — эпоха расцвета кабаре. На фронте тоже не было недостатка в сценическом юморе.",
    "article_url": "http://culture.pl/ru/superarticle/yumor-v-mrachnoe-vremya-artisty-kabare-na-voyne",
    "collage_description": "Графика, выполненная в технике коллажа. В центре — Нина Оленская с банками еды, рядом с ней справа — актер Феликс Фабиан в роли Чарли Чаплина. В левом верхнем углу — силуэт Ренаты Богданской с красной розой в левой руке."
  }
}
</i18n>
