<template>
  <section class="section article" data-chapter="bolonia" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="collage bolonia-collage" aria-describedby="bolonia-collage-description">
      <div class="collage__inner">
        <div class="collage__merged" v-if="$screen.width < 1024">
          <ArticleCollageMerged file="12-bolonia" />
        </div>
        <template v-if="$screen.width < 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
        </template>
        <kinesis-container v-if="$screen.width >= 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
          <div class="collage__height">
            <kinesis-element :strength="10"></kinesis-element>
          </div>
          <div class="collage__absolute bolonia-collage__one">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/12-bolonia-piazza.png">
                <img data-src="@/assets/images/12-bolonia-piazza.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute bolonia-collage__two">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/12-bolonia-cameraman.png">
                <img data-src="@/assets/images/12-bolonia-cameraman.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute bolonia-collage__three">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/12-bolonia-flaga.png">
                <img data-src="@/assets/images/12-bolonia-flaga.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute bolonia-collage__four">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/12-bolonia-tlum.png">
                <img data-src="@/assets/images/12-bolonia-tlum.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute bolonia-collage__five">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/12-bolonia-flagowy.png">
                <img data-src="@/assets/images/12-bolonia-flagowy.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute bolonia-collage__six">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/12-bolonia-swiatlo.png">
                <img data-src="@/assets/images/12-bolonia-swiatlo.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute bolonia-collage__seven">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/12-bolonia-waszynski.png">
                <img data-src="@/assets/images/12-bolonia-waszynski.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute bolonia-collage__eight">
            <kinesis-element :strength="30" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/12-bolonia-odznaka.png">
                <img data-src="@/assets/images/12-bolonia-odznaka.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
        </kinesis-container>
      </div>
    </div>
    <div id="bolonia-collage-description" class="visually-hidden">{{ $t('collage_description') }}</div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import ArticleCollageMerged from '@/components/partials/ArticleCollageMerged.vue'
import ArticleText from '@/components/partials/ArticleText.vue'

export default {
  components: {
    ArticleCollageMerged,
    ArticleText
  },
  data() {
    return {
      sectionNumber: 42
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Kamera i Akcja!",
    "text": "Chłopiec z wołyńskiego sztetla, czołowy polski reżyser międzywojnia, arystokrata i kronikarz Armii Andersa – poznajcie Michała Waszyńskiego.",
    "article_url": "https://culture.pl/pl/superartykul/michal-waszynski-polski-ksiaze-z-kowla",
    "collage_description": "Grafika stworzona techniką kolażu. Pierwszy plan – reżyser Michał Waszyński, w tle ludność na ulicach Bolonii. Lewy górny róg – żołnierz polski jako operator kamery, z której światło skierowane jest na grafikę odznaki pamiątkowej 2. Korpusu Polski we Włoszech i żołnierza mocującego flagę Polski."
  },
  "en": {
    "title": "Lights, camera, action!",
    "text": "Meet Michał Waszyński: a boy from a shtetl in Volhynia, a reigning Interwar Polish director, an aristocrat – and a chronicler of Anders’ Army.",
    "article_url": "https://culture.pl/en/feature/michal-waszynski-the-polish-prince-from-kovel",
    "collage_description": "In the foreground – the director Michał Waszyński. In the background – the citizens of Bologna. Top left corner – a Polish soldier working as a camera operator. The light beam from the camera’s lens is directed to the graphic of the Polish Army in Italy II Corps Commemorative Badge and a soldier attaching a Polish flag."
  },
  "ru": {
    "title": "Камера и мотор!",
    "text": "Мальчик из еврейского местечка, ведущий польский режиссер межвоенного периода, аристократ и летописец Армии Андерса — знакомьтесь: Михал Вашиньский.",
    "article_url": "https://culture.pl/ru/superarticle/mikhal-vashinskiy-polskiy-princ-iz-kovelya",
    "collage_description": "Графика, выполненная в технике коллажа. На переднем плане — режиссер Михал Вашинский, на заднем плане — прохожие на улицах Болоньи. Левый верхний угол — польский солдат-кинооператор. Памятный значок 2-го польского корпуса в Италии. Солдат поправляет польский флаг."
  }
}
</i18n>
