<template>
    <div class="article__text">
        <div class="article__text-centered">
            <div class="article__text-inner">
                <h3>{{ title }}</h3>
                <p>{{ description }}</p>
                <div class="article__goto" ref="ArticleGotoLabel">
                    <span class="label" aria-hidden="true">{{ $t('default.goto-article-label') }}</span>
                    <span class="arrow"></span>
                </div>
                <a class="article__link" :href="url" v-on:focus="onFocus" v-on:blur="onBlur" target="_blank" rel="noreferrer">
              <span class="visually-hidden">{{ $t('default.goto-article-label') }} {{ $t('default.new-tab-info') }}</span>
            </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ArticleText',
    props: ['title', 'description', 'url'],
    data() {
        return {
     
        }
    },
    methods: {
        onFocus() {
            this.$refs.ArticleGotoLabel.classList.add('article__goto--active')
        },
        onBlur() {
            this.$refs.ArticleGotoLabel.classList.remove('article__goto--active')
        }
    },

}
</script>
