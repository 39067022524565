<template>
    <Waypoint @change="onWaypointChange" />
    <picture v-if="visible">
        <source media="(min-width:768px)" :srcset="getImageSource('md')">
        <img :src="getImageSource('sm')" alt="" />
    </picture>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
    name: 'ArticleCollageMerged',
    props: ['file'],
    components: {
        Waypoint
    },
    data() {
        return {
            fileName: this.file,
            visible: false
        }
    },
    methods: {
        getImageSource(breakpoint) {
            return require(`@/assets/images/${this.file}-article-${breakpoint}.png`)
        },
        onWaypointChange(waypointState) {
            // Going can be: 'IN', 'OUT'
            // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
            if (waypointState.going === 'IN') {
                this.visible = true
            }
        }
    }
}
</script>
