<template>
  <section class="section article" data-chapter="griazowiec" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="collage griazowiec-collage" aria-describedby="griazowiec-collage-description">
      <div class="collage__inner">
        <div class="collage__merged" v-if="$screen.width < 1024">
          <ArticleCollageMerged file="01-griazowiec" />
        </div>
        <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
        <kinesis-container v-if="$screen.width >= 1024">
          <div class="collage__height">
            <kinesis-element :strength="10"></kinesis-element>
          </div>
          <div class="collage__absolute griazowiec-collage__one">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/01-griazowiec-las.png">
                <img data-src="@/assets/images/01-griazowiec-las.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute griazowiec-collage__two">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/01-griazowiec-dokument.png">
                <img data-src="@/assets/images/01-griazowiec-dokument.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute griazowiec-collage__three">
            <kinesis-element :strength="30" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/01-griazowiec-triangle.png">
                <img data-src="@/assets/images/01-griazowiec-triangle.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute griazowiec-collage__four">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/01-griazowiec-czapski.png">
                <img data-src="@/assets/images/01-griazowiec-czapski.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute griazowiec-collage__five">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/01-griazowiec-druty.png">
                <img data-src="@/assets/images/01-griazowiec-druty.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute griazowiec-collage__six">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/01-griazowiec-swiatlo.png">
                <img data-src="@/assets/images/01-griazowiec-swiatlo.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute griazowiec-collage__seven">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/01-griazowiec-guzik.png">
                <img data-src="@/assets/images/01-griazowiec-guzik.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
        </kinesis-container>
      </div>
    </div>
    <div id="griazowiec-collage-description" class="visually-hidden">{{ $t('collage_description') }}</div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import ArticleText from '@/components/partials/ArticleText.vue'
import ArticleCollageMerged from '@/components/partials/ArticleCollageMerged.vue'

export default {
  components: {
    ArticleCollageMerged,
    ArticleText
  },
  data() {
    return {
      sectionNumber: 5
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Śledztwo",
    "text": "Zagadkę losu zaginionych kolegów próbuje rozwikłać zwolniony właśnie z obozu malarz i pisarz Józef Czapski.",
    "article_url": "https://culture.pl/pl/superartykul/czapski-sledztwo-na-nieludzkiej-ziemi",
    "collage_description": "Grafika stworzona techniką kolażu. Centralnie w tle autoportret Józefa Czapskiego. Po lewej płot i zasieki łagru. Z wieży wartowniczej smuga światła latarki skierowana w prawy dolny róg. Na zasiekach – guzik od munduru WP. Po prawej grafika lasu sosnowego. W tle decyzja o wymordowaniu polskich jeńców wojennych."
  },
  "en": {
    "title": "Investigation",
    "text": "Only just released from a Soviet camp himself, the painter and writer Józef Czapski endeavoured to unravel the mystery of his missing colleagues’ fates.",
    "article_url": "https://culture.pl/en/feature/jozef-czapskis-investigation-in-an-inhuman-land",
    "collage_description": "In the centre – a self-portrait drawing of Józef Czapski. On the left – the abatis and the fence of a Gulag labour camp. A spotlight from a guard tower directed to the bottom right-hand corner. On the abatis – a Polish Army uniform button. On the right – the image of a pinewood. In the background – a photocopy of a Soviet document with the decision to exterminate the Polish prisoners of war."
  },
  "ru": {
    "title": "Расследование",
    "text": "Тайну судьбы пропавших товарищей пробует раскрыть освобожденный из лагеря художник и писатель Юзеф Чапский.",
    "article_url": "https://culture.pl/ru/superarticle/yuzef-chapskiy-rassledovanie-na-beschelovechnoy-zemle",
    "collage_description": "Графика, выполненная в технике коллажа. В центре — автопортрет Юзефа Чапского. Слева колючая проволока лагеря. Полоса света факела со сторожевой башни, направленная в правый нижний угол. На перетяжках — пуговица от мундира Польского Войска. Справа изображение соснового леса. На заднем плане — приказ о расстреле польских военнопленных."
  }
}
</i18n>
