<template>
  <section class="section article" data-chapter="paryz-londyn-rzym" :data-number="sectionNumber">
    <a class="section__anchor section__anchor--top" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="collage paryz-londyn-rzym-collage" aria-describedby="paryz-londyn-rzym-collage-description">
      <div class="collage__inner">
        <div class="collage__merged" v-if="$screen.width < 1024">
          <ArticleCollageMerged file="13-paryz-londyn-rzym" />
        </div>
        <template v-if="$screen.width < 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
        </template>
        <kinesis-container v-if="$screen.width >= 1024">
          <ArticleText :title="$t('title')" :description="$t('text')" :url="$t('article_url')" />
          <div class="collage__height">
            <kinesis-element :strength="10"></kinesis-element>
          </div>
          <div class="collage__absolute paryz-londyn-rzym-collage__one">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/13-paryz-londyn-rzym-most-kultura.png">
                <img data-src="@/assets/images/13-paryz-londyn-rzym-most-kultura.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute paryz-londyn-rzym-collage__two">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/13-paryz-londyn-rzym-hemar.png">
                <img data-src="@/assets/images/13-paryz-londyn-rzym-hemar.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute paryz-londyn-rzym-collage__three">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/13-paryz-londyn-rzym-triangle.png">
                <img data-src="@/assets/images/13-paryz-londyn-rzym-triangle.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute paryz-londyn-rzym-collage__four">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/13-paryz-londyn-rzym-most.png">
                <img data-src="@/assets/images/13-paryz-londyn-rzym-most.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute paryz-londyn-rzym-collage__five">
            <kinesis-element :strength="20" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/13-paryz-londyn-rzym-jerzy.png">
                <img data-src="@/assets/images/13-paryz-londyn-rzym-jerzy.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute paryz-londyn-rzym-collage__six">
            <kinesis-element :strength="30" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/13-paryz-londyn-rzym-zosia.png">
                <img data-src="@/assets/images/13-paryz-londyn-rzym-zosia.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
          <div class="collage__absolute paryz-londyn-rzym-collage__seven">
            <kinesis-element :strength="10" :originX="100" :originY="100">
              <picture class="lazy">
                <source media="(min-width:1024px)" data-srcset="@/assets/images/13-paryz-londyn-rzym-reka.png">
                <img data-src="@/assets/images/13-paryz-londyn-rzym-reka.png" alt="" />
              </picture>
            </kinesis-element>
          </div>
        </kinesis-container>
      </div>
    </div>
    <div id="paryz-londyn-rzym-collage-description" class="visually-hidden">{{ $t('collage_description') }}</div>
    <a class="section__anchor section__anchor--bottom" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
  </section>
</template>

<script>
import ArticleText from '@/components/partials/ArticleText.vue'
import ArticleCollageMerged from '@/components/partials/ArticleCollageMerged.vue'

export default {
  components: {
    ArticleCollageMerged,
    ArticleText
  },
  data() {
    return {
      sectionNumber: 45
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Z dala od domu",
    "text": "Zdecydowana większość żołnierzy i żołnierek Andersa nigdy nie wróciła do Polski. Rozsiani po całym świecie tworzyli prężne ośrodki polskiej kultury.",
    "article_url": "https://culture.pl/pl/superartykul/paryz-londyn-rzym-polska-diaspora-andersa",
    "collage_description": "Grafika stworzona techniką kolażu. London Bridge, za nim sylwetka poety Mariana Hemara, obok postaci Zofii Hertz i Jerzego Giedroycia. Po prawej w tle – winieta magazynu Kultura."
  },
  "en": {
    "title": "Far from home",
    "text": "The vast majority of soldiers in Anders’ Army would never return to Poland. They would instead form thriving international centres of Polish culture scattered across the world.",
    "article_url": "https://culture.pl/en/feature/paris-london-rome-anders-polish-diaspora",
    "collage_description": "London Bridge. Behind it – the figure of the poet Marian Hemar next to Zofia Hertz and Jerzy Giedroyć. On the right, in the background – the masthead of ‘Kultura’ magazine."
  },
  "ru": {
    "title": "Вдали от дома",
    "text": "Подавляющее большинство солдат Андерса больше не вернулось в Польшу. Разбросанные по всему миру, они создали динамичные центры польской культуры.",
    "article_url": "https://culture.pl/ru/feature/paris-london-rome-anders-polish-diaspora",
    "collage_description": "Графика, выполненная в технике коллажа. London Bridge, за ним — силуэт поэта Мариана Хемара, рядом фигуры Зофьи Герц и Ежи Гедройца. Справа на заднем плане — виньетка журнала «Kultura»"
  }
}
</i18n>
