import { createRouter, createWebHistory } from 'vue-router'
import i18n from '../i18n'

const routes = [{
    path: '/:locale',
    component: {
      template: "<router-view></router-view>"
    },
    children: []
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.afterEach((to) => {
  const locale = to.params.locale
  const supported_locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(',')

  if (!supported_locales.includes(locale)) {
    i18n.global.locale = 'pl'

    return
  }
  if (i18n.global.locale !== locale) {
    i18n.global.locale = locale
  }
})

export default router
