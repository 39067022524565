<template>
  <section ref="Section" class="section cover" id="kair" data-chapter="kair" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <template v-if="$screen.width < 1024">
      <Waypoint @change="onWaypointChange" />
    </template>
    <div class="cover__inner">
      <h2 class="visually-hidden">{{ $t('screen_reader_title') }}</h2>
      <div class="cover__text">
        <p>{{ $t('description') }}</p>
      </div>
      <div class="cover__number" aria-hidden="true">10</div>
      <div class="cover__title" aria-hidden="true">{{ $t('title') }}</div>
    </div>
  </section>
</template>

<script>
import { Waypoint } from 'vue-waypoint'

export default {
  components: {
    Waypoint
  },
  data() {
    return {
      sectionNumber: 34
    }
  },
  methods: {
    onWaypointChange(waypointState) {
      // Going can be: 'IN', 'OUT'
      // Direction can be: 'UP', 'DOWN', 'LEFT', 'RIGHT'
      if (waypointState.going === 'IN') {
        this.$parent.updateNavigationLinks(this.$refs.Section)
        this.$parent.updateScrollPositionIndicator(this.$refs.Section)
      }
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "title": "Kair",
    "description": "W Egipcie, gdzie polskie wojsko dotarło pod koniec 1943 roku, mieściło się dowództwo sił brytyjskich śródziemnomorskiego teatru działań wojennych. W oczekiwaniu na ewakuację do Włoch Polacy zostali rozlokowani w obozach przejściowych z dala od większych miast. W Egipcie żołnierze 2. Korpusu Polskiego weszli w skład brytyjskiej 8. Armii.",
    "screen_reader_title": "Rozdział 10 Kair"
  },
  "en": {
    "title": "Cairo",
    "description": "In late 1943, the Polish army reached Egypt – home to the command of the British forces in the Mediterranean. Pending their evacuation to Italy, the Poles were placed in transit camps far away from the big cities. In Egypt, the soldiers of Anders’ Polish II Corps entered the ranks of the British 8th Army.",
    "screen_reader_title": "Chapter 10 Cairo"
  },
  "ru": {
    "title": "Каир",
    "description": "В Египте, куда польская армия прибыла в конце 1943 года, находилось командование британских войск, задействованных на территориях средиземноморского театра военных действий. В ожидании эвакуации в Италию поляки были расквартированы в перевалочных лагерях вдали от крупных городов. В Египте солдаты 2-го Польского корпуса вошли в состав 8-й Британской армии.",
    "screen_reader_title": "Глава 10 Каир"
  }
}
</i18n>
