<template>
  <section class="section quote beirut-quote" data-chapter="beirut" :data-number="sectionNumber">
    <a class="section__anchor" v-on:focus="$parent.handleClick(sectionNumber)" tabindex="0" aria-hidden="true"></a>
    <div class="quote__inner">
      <template v-if="$screen.width < 1024">
        <QuoteImageMobile fileSm="08-beirut-quote-sm.jpg" fileMd="08-beirut-quote-md.jpg" :description="$t('image_description')" :alt="$t('author_picture_alt')" />
        <QuoteAuthorPictureMobile file="08-beirut-quote-author-picture-sm.png" :alt="$t('author_picture_alt')" />
      </template>
      <template v-if="$screen.width >= 1024">
        <div class="quote__author-picture">
          <div class="quote__author-picture-inner">
            <picture class="lazy">
              <source media="(min-width:1921px)" data-srcset="@/assets/images/08-beirut-quote-author-picture.png">
              <img data-src="@/assets/images/08-beirut-quote-author-picture.png" :alt="$t('author_picture_alt')" class="beirut-quote__author-image" />
            </picture>
          </div>
        </div>
      </template>
      <figure>
        <blockquote>
          <p><span>{{ $t('quote') }}</span></p>
        </blockquote>
        <figcaption>
          <span class="quote__author-name"><span>{{ $t('author_name') }}</span></span>
          <span class="quote__author-title"><span>{{ $t('author_title') }}</span></span>
        </figcaption>
      </figure>
      <template v-if="$screen.width >= 1024">
        <div class="quote__background-image beirut-quote__background-image lazy-background"></div>
        <div class="quote__background-image-description">
          <p><span>{{ $t('image_description') }}</span></p>
        </div>
      </template>
    </div>
  </section>
</template>

<script>
import QuoteImageMobile from '@/components/partials/QuoteImageMobile.vue'
import QuoteAuthorPictureMobile from '@/components/partials/QuoteAuthorPictureMobile.vue'

export default {
  components: {
    QuoteImageMobile,
    QuoteAuthorPictureMobile
  },
  data() {
    return {
      sectionNumber: 30
    }
  }
}
</script>

<i18n>
{
  "pl": {
    "quote": "Tyle  pejzaży  przetańczyło  w ciągu  wojennych  lat  w mych  oczach,  od  Lwowa  po  Krąg Polarny,  od Kręgu  Polarnego przez centralną Rosję i  Ural  do Uzbekistanu, a potem przez Per­sję,  Irak,  Syrię do  Palestyny,  że oczy są naprawdę zmęczone.",
    "author_name": "Marian Czuchnowski,",
    "author_title": "poeta i pisarz",
    "author_picture_alt": "Czarno-biała fotografia obozu polskiego w Iraku. W prawej części obrazu – widoczna tęcza. W oddali kilka namiotów obozowych. W lewym dolnym rogu fragment białego koła, na nim portret foto Mariana Czuchnowskiego – poety i pisarza, w garniturze, pod krawatem. Z kapeluszem na głowie. Wzrok zwrócony w obiektyw. Pośrodku białe litery w kilku rzędach. Cytat wypowiedzi Mariana Czuchnowskiego.",
    "image_description": "Fot. www.audiovis.nac.gov.pl (NAC)"
  },
  "en": {
    "quote": "So many landscapes danced before my eyes during the war years – from Lviv to the Arctic Circle, from the Arctic Circle through central Russia, from the Urals to Uzbekistan, and then through Persia, Iraq and Syria to Palestine – that my eyes are truly exhausted.",
    "author_name": "Marian Czuchnowski,",
    "author_title": "poet & writer",
    "author_picture_alt": "A black-and-white photograph of the Polish camp in Iraq. On the right – a rainbow. A few camp tents in the distance. In the bottom left corner, a piece of a white circle with a portrait of Marian Czuchnowski – the poet and writer, dressed in suit and tie. With a hat on his head. He gazes into the camera lens. In the centre white letters in a few rows. Marian Czuchnowski’s statement is quoted.",
    "image_description": "Photo: www.audiovis.nac.gov.pl (NAC)"
  },
  "ru": {
    "quote": "Так много пейзажей плясало перед моими глазами в годы войны — от Львова до Полярного круга, от Полярного круга, Центральной России и Урала до Узбекистана, а потом от Персии, Ирака, Сирии до Палестины — что мои глаза очень устали.",
    "author_name": "Мариан Чухновский,",
    "author_title": "поэт и писатель",
    "author_picture_alt": "Черно-белая фотография польского лагеря в Ираке. В правой части снимка — радуга. Вдалеке несколько палаток лагеря. В левом нижнем углу — фрагмент белого круга, на нем — портрет  поэта и писателя Мариана Чухновского, он в шляпе, костюме и галстуке. Он смотрит в объектив. В центре белые буквы в несколько рядов. Цитата из обращения  Мариана Чухновского.",
    "image_description": "Фото: www.audiovis.nac.gov.pl (NAC)"
  }
}
</i18n>
